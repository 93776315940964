/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createOrder, getAllOrders } from '../../../redux/actions/orderAction.js';
import { getAllCustomers } from '../../../redux/actions/customerAction.js';

const CreateOrderModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, orders } = useSelector((state) => state.order);
  const { customers } = useSelector((state) => state.customer);

  const [orderId, setOrderId] = useState('');
  const [customer, setCustomer] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    dispatch(getAllCustomers());
  }, [dispatch]);

  const handleOrderIdChange = (e) => {
    const newOrderId = e.target.value;
    setOrderId(newOrderId);

    const orderExists = orders.some(order => order.orderId === newOrderId);
    if (orderExists) {
      toast.error('Order ID already exists!');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const orderData = {
      orderId,
      customer,
      totalAmount,
      status,
    };

    dispatch(createOrder(orderData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Order created successfully!');
      dispatch(getAllOrders()); // Fetch updated order list
      setOrderId('');
      setCustomer('');
      setTotalAmount('');
      setStatus('');
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Order ID</Form.Label>
                <Form.Control
                  type="text"
                  value={orderId}
                  onChange={handleOrderIdChange}
                  placeholder="Enter order ID"
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Customer</Form.Label>
                <Form.Control
                  as="select"
                  value={customer}
                  onChange={(e) => setCustomer(e.target.value)}
                  required
                >
                  <option value="">Select customer</option>
                  {customers.map((customer) => (
                    <option key={customer._id} value={customer._id}>
                      {customer.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                  placeholder="Enter total amount"
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                >
                  <option value="">Select status</option>
                  <option value="Pending">Pending</option>
                  <option value="Processing">Processing</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Cancelled">Cancelled</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" disabled={isLoading} className='float-end'>
            {isLoading ? 'Creating...' : 'Create Order'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateOrderModal;
