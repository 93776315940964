
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleStock, updatePrimaryVariants } from '../../../../redux/actions/stockAction';

const PrimaryVariantModal = ({ show, handleClose, primaryVariant, inventoryId, primaryVariations, setPrimaryVariations }) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector(state => state.stock);

  const [primaryVariation, setPrimaryVariation] = useState('');

  // handle add primary variant
  const handleAddPrimaryVariation = () => {
    // if unit is not selected
    if (!primaryVariation) {
      toast.error('Please select a variant');
      return;
    }
    if (primaryVariation && !primaryVariations.includes(primaryVariation)) {
      setPrimaryVariations([...primaryVariations, primaryVariation]);
      setPrimaryVariation('');
      return true;
    }
    toast.error('Variant already added');
  };

  // handle remove primary variant
  const handleRemovePrimaryVariation = (variant) => {
    const newPrimaryVariations = primaryVariations.filter((priceVariant) => priceVariant !== variant);
    setPrimaryVariations(newPrimaryVariations);
  };

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (primaryVariations.length === 0) {
      toast.error(`Please add at least one ${primaryVariant} variant`);
      return;
    }

    // inventory data
    const inventoryData = {
      primaryVariations
    };

    dispatch(updatePrimaryVariants(inventoryId, inventoryData));
  }

  useEffect(() => {
    if (success) {
      toast.success('Primary Variants updated successfully');
      dispatch({ type: "clearSuccess" });
      dispatch(getSingleStock(inventoryId));
      handleClose();
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }
  }, [success, handleClose, error, dispatch, inventoryId]);


  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{primaryVariant} Variants</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Variant Type</Form.Label>
                <div className="d-flex">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={primaryVariation}
                      onChange={(e) => setPrimaryVariation(capitalizeFirstLetter(e.target.value))}
                      placeholder="Enter variant type"
                    />
                    <Button
                      className="btn-warning"
                      onClick={handleAddPrimaryVariation}
                      type="button"
                    >
                      Add
                    </Button>
                  </InputGroup>
                </div>
              </Form.Group>
              <div className="mb-2">
                {primaryVariations && primaryVariations?.map((priceVariant, i) => (
                  <span key={i} className="badge bg-info d-inline-flex align-items-center me-2">
                    {priceVariant}
                    <AiOutlineClose
                      size={15}
                      className="ms-2 cursor-pointer"
                      onClick={() => handleRemovePrimaryVariation(priceVariant)}
                    />
                  </span>
                ))}
              </div>
            </Col>
          </Row>
          <Button className='float-end mt-3 ' type='submit' variant="primary">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PrimaryVariantModal;