/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { editCustomer } from '../../../redux/actions/customerAction';
import { capitalizeFirstLetter, toLowerCase } from '../../utils/utils';

const EditCustomerModal = ({ show, onHide, customer }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [company, setCompany] = useState('');

  useEffect(() => {
    if (customer) {
      setName(customer.name || '');
      setEmail(customer.email || '');
      setPhoneNumber(customer.phoneNumber || '');
      setAddress(customer.address || '');
      setCompany(customer.company || '');
    }
  }, [customer]);


  const handleSubmit = (e) => {
    e.preventDefault();

    const customerData = {
      name,
      email,
      phoneNumber,
      address,
      company,
    };
    dispatch(editCustomer(customer._id, customerData));
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Edit Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter customer name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(toLowerCase(e.target.value))}
              placeholder="Enter customer email"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter customer phone number"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              value={address}
              onChange={(e) => setAddress(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter customer address"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              value={company}
              onChange={(e) => setCompany(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter customer company"
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className='float-end'>
            Update Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCustomerModal;