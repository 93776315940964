/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';

import { editBranch, getAllBranches } from '../../../redux/actions/branchAction';

import { toast } from 'react-toastify';

const EditBranchModal = ({ show, onHide, branch }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.branch);

  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [contact, setContact] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (branch) {
      setName(branch.name);
      setLocation(branch.location);
      setContact(branch.contact);
      setActive(branch.active);
    }
  }, [branch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editBranch(branch._id, { name, location, contact, active }));
  };

  useEffect(() => {
    if (success) {
      toast.success('Branch updated successfully!');
      dispatch(getAllBranches()); // Fetch updated branch list
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type="text"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className='float-end' disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditBranchModal;
