import axios from "axios";
import { server } from "../../server/server";

// Create Invoice
export const createInvoice = (newInvoice) => async (dispatch) => {
  try {
    dispatch({ type: "createInvoiceRequest" });

    const { data } = await axios.post(`${server}/invoice/create-invoice`, newInvoice);

    dispatch({
      type: "createInvoiceSuccess",
      payload: data.invoice,
    });
  } catch (error) {
    dispatch({
      type: "createInvoiceFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Invoices
export const getAllInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllInvoicesRequest" });

    const { data } = await axios.get(`${server}/invoice/get-all-invoices`);

    dispatch({
      type: "getAllInvoicesSuccess",
      payload: data.invoices,
    });
  } catch (error) {
    dispatch({
      type: "getAllInvoicesFailed",
      payload: error.response.data.message,
    });
  }
};

// Fetch Single Invoice
export const getInvoice = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getInvoiceRequest" });

    const { data } = await axios.get(`${server}/invoice/get-invoice/${id}`);

    dispatch({
      type: "getInvoiceSuccess",
      payload: data.invoice,
    });
  } catch (error) {
    dispatch({
      type: "getInvoiceFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Invoice
export const editInvoice = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editInvoiceRequest" });

    const { data } = await axios.put(`${server}/invoice/update-invoice/${id}`, updatedData);

    dispatch({
      type: "editInvoiceSuccess",
      payload: data.invoice,
    });

    // Optionally, fetch all invoices again to update the list
    dispatch(getAllInvoices());
  } catch (error) {
    dispatch({
      type: "editInvoiceFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Invoice
export const deleteInvoice = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteInvoiceRequest" });

    const { data } = await axios.delete(`${server}/invoice/delete-invoice/${id}`);

    dispatch({
      type: "deleteInvoiceSuccess",
      payload: data.message,
    });

    // Optionally, fetch all invoices again to update the list
    dispatch(getAllInvoices());
  } catch (error) {
    dispatch({
      type: "deleteInvoiceFailed",
      payload: error.response.data.message,
    });
  }
};
