import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { getAllProductUnits } from "../../../../redux/actions/productUnitAction";
import { getAllStocks } from "../../../../redux/actions/stockAction";
import { editInventoryItem, getInventoryItemDetails, getInventoryItemsByStore } from "../../../../redux/actions/storeInventoryAction";
import { useSellingUnitSelect, useStoreInventoryProduct } from "../../../utils/selectFunctions";
import SellingUnits from "./SellingUnits";
import AddInventory from "./edit/AddInventory";

const StoreInventoryDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { inventoryItem, isLoading, success, error } = useSelector((state) => state.storeInventory);
  const { stocks } = useSelector((state) => state.stock);
  const { productUnits } = useSelector((state) => state.productUnit);

  const { stockId, setStockId, stockName, setStockName, stockOptions, handleStockChange } = useStoreInventoryProduct(stocks);
  // const { productUnitId, setProductUnitId, productUnitOptions, handleProductUnitChange } = useProductUnitSelect(productUnits);
  const { sellingUnitId, setSellingUnitId, sellingUnitOptions, handleSellingUnitChange } = useSellingUnitSelect(productUnits);

  useEffect(() => {
    if (id) {
      dispatch(getInventoryItemDetails(id));
      dispatch(getAllProductUnits());
      dispatch(getAllStocks());

    }
  }, [dispatch, id, setStockId]);

  // get selected stock
  const selectedStock = stocks?.find((stock) => stock?._id === stockId);

  const [unitQuantity, setUnitQuantity] = useState('');
  const [numberOfUnits, setNumberOfUnits] = useState('');
  const [totalQuantity, setTotalQuantity] = useState('');
  const [inventoryVariation, setInventoryVariation] = useState('');
  const [reorderLevel, setReorderLevel] = useState('');
  const [sellingUnits, setSellingUnits] = useState([]);
  const [openingStock, setOpeningStock] = useState(0);
  const [currentStock, setCurrentStock] = useState(0);


  /** add stock modals */
  const [showAddStockModal, setShowAddStockModal] = useState(false);
  const handleAddStockModalClose = () => setShowAddStockModal(false);


  // set inventory item details
  useEffect(() => {
    if (inventoryItem) {
      setStockId(inventoryItem?.stock?._id);
      setStockName(inventoryItem?.stock?.name);
      setInventoryVariation(inventoryItem?.inventoryVariation);
      // setProductUnitId(inventoryItem?.productUnit?._id);
      setUnitQuantity(inventoryItem?.unitQuantity);
      setNumberOfUnits(inventoryItem?.numberOfUnits);
      setTotalQuantity(inventoryItem?.totalQuantity);
      setReorderLevel(inventoryItem?.reorderLevel);
      setSellingUnits(inventoryItem?.sellingUnits);
      setOpeningStock(inventoryItem?.openingStock);
      setCurrentStock(inventoryItem?.currentStock);
    }
  }, [inventoryItem, setStockId, setStockName]);

  // calculate total quantity
  useEffect(() => {
    setTotalQuantity(numberOfUnits * unitQuantity);
  }, [numberOfUnits, unitQuantity]);

  // Handle selling unit add
  const handleAddSellingUnit = (itemId, setItem, items) => {
    // Find the selected unit from productUnits
    const selectedUnit = productUnits.find((unit) => unit._id === itemId);

    // Check if the unit is already in the sellingUnits array
    if (selectedUnit && !items.some((unit) => unit._id === selectedUnit._id)) {
      setItem([...items, selectedUnit]);
      setSellingUnitId(null);
      return true;
    } else {
      toast.error("This variant already exists!");
    }
    return false;
  };

  // Handle product unit remove
  const handleRemoveSellingUnit = (itemId, setItem, items) => {
    // Remove the unit by matching the `_id`
    setItem(items.filter((unit) => unit._id !== itemId));
  };


  // handle transfer stock
  const handleTransferStock = () => {
    // navigate('/store-inventory/transfer-stock');
  }

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the selling units are empty
    if (sellingUnits.length === 0) {
      return toast.error("Please add selling units!");
    }

    const newItem = {
      stock: stockId,
      name: stockName,
      inventoryVariation,
      product: selectedStock?.product,
      unitQuantity,
      numberOfUnits,
      totalQuantity,
      reorderLevel,
      sellingUnits,
      openingStock,
    };

    dispatch(editInventoryItem(inventoryItem._id, newItem));
  }

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
      toast.error(error);
    }

    if (success) {
      toast.success("Inventory item updated successfully!");
      dispatch(getInventoryItemsByStore());
      navigate('/store-inventory');
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, navigate, success]);


  return (
    <div className="container-fluid">
      <Row className="mt-2">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/store-inventory">Store Inventory</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {inventoryItem && inventoryItem?.stock?.inventoryName}
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <div className="d-flex align-items-center float-end">
            <Button
              variant="success"
              className="me-2"
              onClick={() => setShowAddStockModal(true)}
              disabled={isLoading}
            >
              <FaPlus className="me-1" /> Add Stock
            </Button>

            <Button variant="warning" className="me-2" onClick={handleTransferStock}>
              <FaExchangeAlt className="me-1" /> Transfer
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6} lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>Select Product :</Form.Label>
                  <div className="d-flex">
                    <InputGroup>
                      <Select
                        value={stockOptions.find(option => option.value === stockId) || null}
                        onChange={handleStockChange}
                        options={stockOptions}
                        placeholder="Select Inventory"
                        isClearable
                        className="flex-grow-1"
                        required
                      />
                    </InputGroup>
                  </div>
                </Form.Group>
              </Col>
              {selectedStock?.variantType === 'Multiple' && (
                <Col sm={6} md={6} lg={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Inventory Variation :</Form.Label>
                    <Form.Control
                      as="select"
                      value={inventoryVariation}
                      onChange={(e) => setInventoryVariation(e.target.value)}
                      required
                    >
                      <option value="">Select Inventory Variation</option>
                      {selectedStock?.primaryVariations?.map((variant, index) => (
                        <option key={index} value={variant}>
                          {variant}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}

              <Col sm={6} md={6} lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>Opening Stock :</Form.Label>
                  <Form.Control
                    type="number"
                    value={openingStock}
                    onChange={(e) => setOpeningStock(parseInt(e.target.value, 10))}
                    placeholder="Enter opening stock"
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={6} lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>Current Stock :</Form.Label>
                  <Form.Control
                    type="number"
                    value={currentStock}
                    disabled
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6} lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>Reorder Level :</Form.Label>
                  <Form.Control
                    type="number"
                    value={reorderLevel}
                    onChange={(e) => setReorderLevel(parseInt(e.target.value, 10))}
                    placeholder="Enter reorder level"
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={6} lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>Selling Unit :</Form.Label>
                  <div className="d-flex">
                    <Select
                      value={sellingUnitOptions.find(option => option.value === sellingUnitId) || null}
                      onChange={handleSellingUnitChange}
                      options={sellingUnitOptions}
                      placeholder="Select selling unit"
                      isClearable
                      className="flex-grow-1"
                    />

                    <Button
                      className="ms-2"
                      onClick={
                        () => handleAddSellingUnit(sellingUnitId, setSellingUnits, sellingUnits)
                      }
                      type="button"
                    >
                      Add
                    </Button>
                  </div>
                </Form.Group>
                <div className="d-flex flex-wrap mb-2">
                  {sellingUnits && sellingUnits?.map((unit, index) => (
                    <span key={index} className="badge bg-info d-inline-flex align-items-center me-2">
                      {unit.unitName}
                      <AiOutlineClose
                        size={15}
                        className="ms-2 cursor-pointer icon"
                        onClick={() => handleRemoveSellingUnit(unit?._id, setSellingUnits, sellingUnits)}
                      />
                    </span>
                  ))}
                </div>
              </Col>
              <Col md={12} lg={12}>
                {sellingUnits && sellingUnits.length > 0 && (
                  <SellingUnits
                    sellingUnits={sellingUnits}
                    setSellingUnits={setSellingUnits}
                    stockQuantity={selectedStock?.unitQuantity}
                  />
                )}
              </Col>
            </Row>
            <Button
              variant="primary"
              type="submit"
              className="float-end mt-3"
              disabled={isLoading}
            >
              Update
            </Button>
          </Form>
        </Col>
      </Row>

      {/* Add Stock Modal */}
      {showAddStockModal && (
        <AddInventory
          show={showAddStockModal}
          onClose={handleAddStockModalClose}
          inventoryItem={inventoryItem}
        />
      )}
    </div>
  )
}
export default StoreInventoryDetails