import axios from "axios";
import { server } from "../../server/server";

// Create Order
export const createOrder = (newOrder) => async (dispatch) => {
  try {
    dispatch({ type: "createOrderRequest" });

    const { data } = await axios.post(`${server}/order/create-order`, newOrder, {
      withCredentials: true,
    });

    dispatch({
      type: "createOrderSuccess",
      payload: data.order,
    });
  } catch (error) {
    dispatch({
      type: "createOrderFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Orders
export const getAllOrders = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllOrdersRequest" });

    const { data } = await axios.get(`${server}/order/get-all-orders`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllOrdersSuccess",
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: "getAllOrdersFail",
      payload: error.response.data.message,
    });
  }
};

// Edit Order
export const editOrder = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editOrderRequest" });

    const { data } = await axios.put(`${server}/order/update-order/${id}`, updatedData, {
      withCredentials: true,
    });

    dispatch({
      type: "editOrderSuccess",
      payload: data.order,
    });

    // Optionally, fetch all orders again to update the list
    dispatch(getAllOrders());
  } catch (error) {
    dispatch({
      type: "editOrderFail",
      payload: error.response.data.message,
    });
  }
};

// Delete Order
export const deleteOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteOrderRequest" });

    const { data } = await axios.delete(`${server}/order/delete-order/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteOrderSuccess",
      payload: data.message,
    });

    // Optionally, fetch all orders again to update the list
    dispatch(getAllOrders());
  } catch (error) {
    dispatch({
      type: "deleteOrderFail",
      payload: error.response.data.message,
    });
  }
};
