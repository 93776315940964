import axios from "axios";
import { server } from "../../server/server";

// Create Expense
export const createExpense = (newExpense) => async (dispatch) => {
  try {
    dispatch({ type: "createExpenseRequest" });

    const { data } = await axios.post(`${server}/expense/create-expense`, newExpense, {
      withCredentials: true,
    });

    dispatch({
      type: "createExpenseSuccess",
      payload: data.expense,
    });
  } catch (error) {
    dispatch({
      type: "createExpenseFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Expenses
export const getAllExpenses = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllExpensesRequest" });

    const { data } = await axios.get(`${server}/expense/get-all-expenses`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllExpensesSuccess",
      payload: data.expenses,
    });
  } catch (error) {
    dispatch({
      type: "getAllExpensesFail",
      payload: error.response.data.message,
    });
  }
};

// Edit Expense
export const editExpense = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editExpenseRequest" });

    const { data } = await axios.put(`${server}/expense/update-expense/${id}`, updatedData, {
      withCredentials: true,
    });

    dispatch({
      type: "editExpenseSuccess",
      payload: data.expense,
    });

    // Optionally, fetch all expenses again to update the list
    dispatch(getAllExpenses());
  } catch (error) {
    dispatch({
      type: "editExpenseFail",
      payload: error.response.data.message,
    });
  }
};

// Delete Expense
export const deleteExpense = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteExpenseRequest" });

    const { data } = await axios.delete(`${server}/expense/delete-expense/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteExpenseSuccess",
      payload: data.message,
    });

    // Optionally, fetch all expenses again to update the list
    dispatch(getAllExpenses());
  } catch (error) {
    dispatch({
      type: "deleteExpenseFail",
      payload: error.response.data.message,
    });
  }
};
