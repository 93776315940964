import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  variant: null,
  variants: [],
  success: false,
  error: null,
  message: null,
};

export const variantReducer = createReducer(initialState, (builder) => {
  builder
    // Create Variant
    .addCase('createVariantRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createVariantSuccess', (state, action) => {
      state.isLoading = false;
      state.variant = action.payload;
      state.success = true;
    })
    .addCase('createVariantFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Variants
    .addCase('getAllVariantsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllVariantsSuccess', (state, action) => {
      state.isLoading = false;
      state.variants = action.payload;
    })
    .addCase('getAllVariantsFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Single Variant
    .addCase('getVariantRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getVariantSuccess', (state, action) => {
      state.isLoading = false;
      state.variant = action.payload;
    })
    .addCase('getVariantFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Variant
    .addCase('updateVariantRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('updateVariantSuccess', (state, action) => {
      state.isLoading = false;
      state.variant = action.payload;
      state.success = true;
      state.message = 'Variant updated successfully!';
    })
    .addCase('updateVariantFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Variant
    .addCase('deleteVariantRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteVariantSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteVariantFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // add variations to a variant
    .addCase('addVariationRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('addVariationSuccess', (state, action) => {
      state.isLoading = false;
      state.variant = action.payload;
      state.success = true;
      state.message = 'Variations added successfully!';
    })
    .addCase('addVariationFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
