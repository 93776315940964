import axios from "axios";
import { server } from "../../server/server";

// Create Category
export const createCategory = (newCategory) => async (dispatch) => {
  try {
    dispatch({ type: "createCategoryRequest" });

    const { data } = await axios.post(`${server}/category/create-category`, newCategory);

    dispatch({
      type: "createCategorySuccess",
      payload: data.category,
    });
  } catch (error) {
    dispatch({
      type: "createCategoryFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Categories
export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllCategoriesRequest" });

    const { data } = await axios.get(`${server}/category/get-all-categories`);

    dispatch({
      type: "getAllCategoriesSuccess",
      payload: data.categories,
    });
  } catch (error) {
    dispatch({
      type: "getAllCategoriesFailed",
      payload: error.response.data.message,
    });
  }
};

// Fetch Single Category
export const getCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getCategoryRequest" });

    const { data } = await axios.get(`${server}/category/get-category/${id}`);

    dispatch({
      type: "getCategorySuccess",
      payload: data.category,
    });
  } catch (error) {
    dispatch({
      type: "getCategoryFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Category
export const editCategory = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editCategoryRequest" });

    const { data } = await axios.put(`${server}/category/update-category/${id}`, updatedData);

    dispatch({
      type: "editCategorySuccess",
      payload: data.category,
    });

    // Optionally, fetch all categories again to update the list
    dispatch(getAllCategories());
  } catch (error) {
    dispatch({
      type: "editCategoryFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Category
export const deleteCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteCategoryRequest" });

    const { data } = await axios.delete(`${server}/category/delete-category/${id}`);

    dispatch({
      type: "deleteCategorySuccess",
      payload: data.message,
    });

    // Optionally, fetch all categories again to update the list
    dispatch(getAllCategories());
  } catch (error) {
    dispatch({
      type: "deleteCategoryFailed",
      payload: error.response.data.message,
    });
  }
};
