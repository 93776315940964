/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getAllCategories } from '../../../redux/actions/categoryAction';
import { editSubcategory } from '../../../redux/actions/subcategoryAction.js';
import { useCategorySelect } from "../../utils/selectFunctions.jsx";
import { capitalizeFirstLetter } from '../../utils/utils.js';
import CreateCategoryModal from '../categories/CreateCategoryModal.jsx';

const EditSubcategoryModal = ({ show, onHide, subcategory }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);

  const [name, setName] = useState('');
  const [shortForm, setShortForm] = useState('');

  const { categoryId, setCategoryId, categoryOptions, handleCategoryChange } = useCategorySelect(categories);

  /** create modals */
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const handleAddCategory = () => {
    setShowCreateModal(true);
  };

  useEffect(() => {
    if (subcategory) {
      setName(subcategory?.name || '');
      setShortForm(subcategory?.shortForm || '');
      setCategoryId(subcategory?.category?._id || '');
    }
  }, [subcategory, setCategoryId]);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const subcategoryData = {
      name,
      shortForm,
      category: categoryId,
    };

    try {
      await dispatch(editSubcategory(subcategory?._id, subcategoryData));
      toast.success("Subcategory updated successfully!");
      onHide();
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} style={{ zIndex: 1050 }}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Sub Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Select Category</Form.Label>
            <div className="d-flex">
              <Select
                value={categoryOptions.find(option => option.value === categoryId)}
                onChange={handleCategoryChange}
                options={categoryOptions}
                placeholder="Select main category"
                isClearable
                className="flex-grow-1"
                required
              />
              <Button
                className="ms-2"
                onClick={handleAddCategory}
                type="button"
              >
                Add
              </Button>
            </div>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Sub Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Short Form</Form.Label>
            <Form.Control
              type="text"
              value={shortForm}
              onChange={(e) => setShortForm(capitalizeFirstLetter(e.target.value))}
              placeholder='Enter subcategory short form'
              required
            />
          </Form.Group>
          <Button variant="primary" className="float-end" type="submit">
            Update Subcategory
          </Button>

        </Form>
        {showCreateModal && (
          <CreateCategoryModal
            show={showCreateModal}
            onHide={handleCreateModalClose}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditSubcategoryModal;