import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  totalSales: 0,
  storeInventoryValue: 0,
  estimatedSales: 0,
  grossProfit: 0,
  netProfit: 0,
  isLoading: false,
  error: null,
  success: false,
  message: null,
};

export const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    // Get Total Sales
    .addCase('getTotalSalesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getTotalSalesSuccess', (state, action) => {
      state.isLoading = false;
      state.totalSales = action.payload;
      state.success = true;
    })
    .addCase('getTotalSalesFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Get Stock Value
    .addCase('getStockValueRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getStockValueSuccess', (state, action) => {
      state.isLoading = false;
      state.storeInventoryValue = action.payload;
      state.success = true;
    })
    .addCase('getStockValueFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Get Estimated Sales
    .addCase('getEstimatedSalesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getEstimatedSalesSuccess', (state, action) => {
      state.isLoading = false;
      state.estimatedSales = action.payload;
      state.success = true;
    })
    .addCase('getEstimatedSalesFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Get Gross Profit
    .addCase('getGrossProfitRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getGrossProfitSuccess', (state, action) => {
      state.isLoading = false;
      state.grossProfit = action.payload;
      state.success = true;
    })
    .addCase('getGrossProfitFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Get Net Profit
    .addCase('getNetProfitRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getNetProfitSuccess', (state, action) => {
      state.isLoading = false;
      state.netProfit = action.payload;
      state.success = true;
    })
    .addCase('getNetProfitFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
