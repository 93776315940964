import { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../../components/loader/Loader.jsx";
import { deleteStock } from "../../../redux/actions/stockAction.js";
import ConfirmDeleteModal from "../../components/confirm-delete/ConfirmDeleteModal.jsx";
import { handleInventoryFilter } from "../../components/utils/handleFilter";
import InventoryPricing from "./components/pricing/InventoryPricing.jsx";

const InventoryPage = () => {
  const { stocks, success, error } = useSelector((state) => state.stock);
  const dispatch = useDispatch();

  // console.log(stocks)

  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  /** pricing modals */
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [pricingStock, setPricingStock] = useState(null);

  /** confirm delete modal */
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [inventoryToDelete, setInventoryToDelete] = useState(null);

  useEffect(() => {
    setRecords(stocks || []);
  }, [stocks]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleInventoryFilter(stocks, term);
    setRecords(filteredData);
  };

  const handleClosePricingModal = () => {
    setShowPricingModal(false);
    setPricingStock(null);
  };

  const handleDelete = (inventoryId) => {
    if (inventoryId) {
      setInventoryToDelete(inventoryId);
      setShowConfirmDeleteModal(true);
    }
  };

  const handleConfirmDelete = () => {
    dispatch(deleteStock(inventoryToDelete));
    setShowConfirmDeleteModal(false);
    setInventoryToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setInventoryToDelete(null);
  };



  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }
    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: 'Name',
      selector: (row) => `${row?.inventoryName} ${row?.product?.subcategory?.shortForm || ""}`,
      sortable: true,
    },
    // {
    //   name: 'Product Name',
    //   selector: (row) => row?.product?.name || "N/A",
    //   sortable: true,
    // },
    {
      name: "Unit Price",
      selector: (row) => row?.primarySellingUnit?.unitName || "N/A",
      width: "100px",
    },
    {
      name: "Unit Qty",
      selector: (row) => row?.unitQuantity || "N/A",
      width: "90px",
    },
    {
      name: "Buying",
      selector: (row) => `Ksh.${row?.buyingPrice ? row?.buyingPrice : "0.00"}`,
      width: "140px",
    },
    {
      name: "WholeSale",
      selector: (row) => `Ksh.${row?.wholeSalePrice ? row?.wholeSalePrice : "0.00"}`,
      width: "140px",
    },
    {
      name: "Retail",
      selector: (row) => `Ksh.${row?.retailPrice ? row?.retailPrice : "0.00"}`,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => (
        <Badge bg={row?.isVerified ? "success" : "danger"}>
          {row?.isVerified ? "Verified" : "Not Verified"}
        </Badge>
      ),
      width: "130px",
    },
    {
      name: "Supplier",
      selector: (row) => row?.supplier?.name || "N/A",
    },
    {
      name: "Variant",
      selector: (row) => row?.variantType || "N/A",
      width: "130px",
    },
    // {
    //   name: "Variants",
    //   selector: (row) => (
    //     <>
    //       {row?.primaryVariant?.name && (
    //         <span className="badge bg-primary me-2">
    //           {row?.primaryVariant?.name}
    //         </span>
    //       )}
    //       {row?.variantType === "Single" ? "NONE" : (
    //         row?.variants?.map((variant, index) => (
    //           <span key={index} className="badge bg-primary me-2">
    //             {variant}
    //           </span>

    //         ))
    //       )}
    //       <Link to={`/inventory/variants/${row._id}`}>
    //         <span className="ms-2 text-success cursor-pointer"
    //           title="Pricing">
    //           <FaEye size={20} />
    //         </span>
    //       </Link>
    //     </>
    //   ),
    //   width: "200px",
    // },
    // {
    //   name: "Variants",
    //   selector: (row) => (
    //     <>
    //       {row?.primaryVariant?.name && (
    //         <span className="badge bg-primary me-2">
    //           {row?.primaryVariant?.name}
    //         </span>
    //       )}
    //       {row?.variantType === "Single" ? "NONE" : (
    //         row?.variants?.map((variant, index) => (
    //           <span key={index} className="badge bg-primary me-2">
    //             {variant}
    //           </span>

    //         ))
    //       )}
    //     </>
    //   ),
    //   width: "200px",
    // },

    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <Link to={`/inventory/edit/${row._id}`} className="text-primary cursor-pointer" title="Edit">
            <FaEdit size={20} />
          </Link>
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      width: "150px",
      ignoreRowClick: true,
    },
  ];

  return (
    <div className="container-fluid">
      <Row className="mt-2">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Inventory
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Inventory</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search inventory records..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={6} md={4} className="d-flex justify-content-end gap-2">
          {/* <ExportInventoryToPdf data={records} filename="inventory_report.pdf" /> */}
          <Link to="/inventory/create-inventory">
            <Button size="md" variant="success">
              <FaPlus className="align-items-center" /> Add
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              pagination
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              noHeader
              striped
              highlightOnHover
              pointerOnHover
              progressComponent={<Loader />}
            />
          </div>
        </Col>
      </Row>

      {showPricingModal && pricingStock && (
        <InventoryPricing
          show={showPricingModal}
          onHide={handleClosePricingModal}
          stock={pricingStock} />
      )}

      {showConfirmDeleteModal && (
        <ConfirmDeleteModal
          show={showConfirmDeleteModal}
          onClose={handleCloseConfirmDeleteModal}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default InventoryPage;