import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaArrowCircleRight, FaChartBar, FaChartLine } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getGrossProfit, getNetProfit, getStockValue, getTotalSales } from '../../../redux/actions/dashboardAction.js';
import { getAllExpenses } from '../../../redux/actions/expenseAction.js';
import { getAllPurchases } from '../../../redux/actions/purchaseAction.js';
import { getAllSales } from '../../../redux/actions/saleAction.js';
import { getAllUsers } from '../../../redux/actions/userAction';
import FormattedAmount from '../../components/utils/FormattedAmount.jsx';
import { getAllInventoryItems } from '../../../redux/actions/storeInventoryAction.js';
import SalesExpensesChart from './SalesExpensesChart.jsx';

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { user, users } = useSelector((state) => state.user);

  const { purchases } = useSelector((state) => state.purchase);
  const { expenses } = useSelector((state) => state.expense);
  const { sales } = useSelector((state) => state.sale);

  const { inventoryItems } = useSelector((state) => state.storeInventory);

  useEffect(() => {
    if (user) {
      dispatch(getAllSales());
      dispatch(getAllPurchases());
      dispatch(getAllUsers());
      dispatch(getTotalSales());
      dispatch(getStockValue());
      dispatch(getGrossProfit());
      dispatch(getNetProfit());
      dispatch(getAllExpenses());
      dispatch(getAllInventoryItems());
    }
  }, [dispatch, user]);


  const totalPurchases = purchases && purchases?.reduce((acc, item) => acc + item.totalAmount, 0);

  const totalSales = sales && sales?.reduce((acc, item) => acc + item.totalAmount, 0);

  // const grossProfit = totalSales - totalPurchases;

  const totalExpenses = expenses && expenses?.reduce((acc, item) => acc + item.amount, 0);

  // const netProfit = grossProfit - totalExpenses;

  const storeInventoryValue = inventoryItems && inventoryItems?.reduce(
    (acc, item) => acc + ((item?.currentStock || 0) * (item?.stock?.buyingPrice || 0)),
    0
  );

  const expectedSales = inventoryItems && inventoryItems?.reduce(
    (acc, item) => acc + ((item?.currentStock || 0) * (item?.stock?.wholeSalePrice || 0)),
    0
  );

  const expectedProfit = expectedSales - storeInventoryValue;
  const expectedProfitPercentage = (expectedProfit / storeInventoryValue) * 100;
  const expectedProfitPercentageFormatted = expectedProfitPercentage.toFixed(2);

  return (
    <div className="container-fluid mb-lg-0">
      <div className="row">
        <div className="col">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
      {/* Cards */}
      <Row className="gy-2">
        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className="bg-success text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>{users && users.length}</h2>
                  <p>Users</p>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/users" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className="bg-info text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={totalPurchases} /></h2>
                  <p>Total Purchases</p>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/purchases" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className="bg-success text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={storeInventoryValue} /></h2>
                  <p>Stock Value</p>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/store-inventory" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className="bg-warning text-black custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={expectedSales} /></h2>
                  <p>Expected Sales</p>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/store-inventory" className="text-black custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className={`custom-panel ${expectedProfit < 0 ? "bg-danger" : "bg-primary"} text-white`}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={expectedProfit} /></h2>
                  <p>{expectedProfit < 0 ? "Expected Loss" : "Expected Profit"}</p>
                </div>
                {expectedProfit < 0 ? (
                  <FaChartBar className="icon" />
                ) : (
                  <FaChartLine className="icon" />
                )}
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/financials" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>



        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className={`custom-panel ${expectedProfit < 0 ? "bg-danger" : "bg-success"} text-white`}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>{expectedProfitPercentageFormatted}%</h2>
                  <p>{expectedProfit < 0 ? "Expected Loss" : "Expected Profit"}</p>
                </div>
                {expectedProfit < 0 ? (
                  <FaChartBar className="icon" />
                ) : (
                  <FaChartLine className="icon" />
                )}
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/financials" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className="bg-info text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={totalSales} /></h2>
                  <p>Total Sales</p>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/sales" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        {/* <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className="bg-success text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={grossProfit} /></h2>
                  <p>Gross Profit</p>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/sales" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col> */}

        {/* <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className={`custom-panel ${netProfit < 0 ? "bg-danger" : "bg-primary"} text-white`}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={netProfit} /></h2>
                  <p>{netProfit < 0 ? "Net Loss" : "Net Profit"}</p>
                </div>
                {netProfit < 0 ? (
                  <FaChartBar className="icon" />
                ) : (
                  <FaChartLine className="icon" />
                )}
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/financials" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col> */}

        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className="bg-info text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={totalExpenses} /></h2>
                  <p>Total Expenses</p>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/expenses" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
          <Card className="bg-info text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>{inventoryItems && inventoryItems.length}</h2>
                  <p>Inventory Items</p>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/store-inventory" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <Row className="gy-2 mt-4">
        <Col lg={6}>
          <Card className="bg-light">
            <Card.Body>
              <SalesExpensesChart
                sales={totalSales}
                expenses={totalExpenses}
              // dailySales={dailySales}
              // weeklySales={weeklySales}
              // monthlySales={monthlySales}
              />
            </Card.Body>
          </Card>
        </Col>
        {/* <Col lg={6}>
          <Card className="bg-light">
            <Card.Body>
              <TopSellingProductsChart products={exampleTopSellingProducts} />
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
};

export default AdminDashboard;