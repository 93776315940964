import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSupplier, getAllSuppliers } from '../../../redux/actions/supplierAction';
import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';
import { handleSupplierFilter } from '../../components/utils/handleFilter.jsx';
import CreateSupplierModal from './CreateSupplierModal.jsx';
import EditSupplierModal from './EditSupplierModal.jsx';

const SuppliersPage = () => {
  const dispatch = useDispatch();
  const { suppliers, error, message } = useSelector((state) => state.supplier);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingSupplier, setEditingSupplier] = useState(null);
  const [supplierIdToDelete, setSupplierIdToDelete] = useState(null);


  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(getAllSuppliers());
  }, [dispatch]);


  useEffect(() => {
    setRecords(suppliers || []);
  }, [suppliers]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleSupplierFilter(suppliers, term);
    setRecords(filteredData);
  };

  const handleDelete = (supplierId) => {
    setSupplierIdToDelete(supplierId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSupplier(supplierIdToDelete));
    setShowConfirmDeleteModal(false);
    setSupplierIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setSupplierIdToDelete(null);
  };

  const handleEdit = (supplier) => {
    setEditingSupplier(supplier);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingSupplier(null);
  };

  useEffect(() => {
    if (error) {
      dispatch({ type: 'clearErrors' });
    }

    if (message) {
      dispatch({ type: 'clearSuccess' });
    }
  }, [dispatch, error, message])


  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Contact",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: "Address",
      selector: (row) => row?.address
    },
    {
      name: "Company",
      selector: (row) => row?.company,
    },
    {
      name: "KRA PIN",
      selector: (row) => row?.kraPin,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          <span className="me-3 text-primary cursor-pointer" title="Edit"
            onClick={() => handleEdit(row)}
          >
            <FaEdit size={20} />
          </span>
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];


  return (
    <div className='container-fluid'>
      <Row className="my-1">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active> Suppliers</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Suppliers</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search suppliers..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={6} md={4}>
          <Button className='float-end' variant="primary" onClick={() => setShowCreateModal(true)}>
            Add Supplier
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              noDataComponent="No supplier found"
              striped
              highlightOnHover
              responsive
            />
          </div>
        </Col>
      </Row>

      {showCreateModal && (
        <CreateSupplierModal
          show={showCreateModal}
          onHide={handleCreateModalClose}
        />
      )}
      {showModal && editingSupplier && (
        <EditSupplierModal
          show={showModal}
          onHide={handleModalClose}
          supplier={editingSupplier}
        />
      )}
      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default SuppliersPage;
