
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleStock } from "../../../../../redux/actions/stockAction.js";
import { getAllVariants } from "../../../../../redux/actions/variantAction.js";

import PrimaryVariantModal from "../PrimaryVariantModal.jsx";
import SecondaryVariantModal from "../SecondaryVariantModal.jsx";
import SingleInventoryVariant from "./SingleInventoryVariant.jsx";

const MultipleVariant = () => {

  const { id } = useParams();
  const dispatch = useDispatch();
  const { stock } = useSelector(state => state.stock);

  const [inventoryName, setInventoryName] = useState('');
  const [primaryVariant, setPrimaryVariant] = useState('');
  const [primaryVariations, setPrimaryVariations] = useState([]);

  const [secVariant, setSecVariant] = useState('');
  const [secVariations, setSecVariations] = useState([]);
  const [inventoryVariants, setInventoryVariants] = useState([]);

  // show primary variant modal
  const [showPrimaryVariantModal, setShowPrimaryVariantModal] = useState(false);
  const handleClosePrimaryVariantModal = () => setShowPrimaryVariantModal(false);

  // show secondary variant modal
  const [showSecondaryVariantModal, setShowSecondaryVariantModal] = useState(false);
  const handleCloseSecondaryVariantModal = () => setShowSecondaryVariantModal(false);

  // handle submit
  useEffect(() => {
    if (id) {
      dispatch(getSingleStock(id));
      dispatch(getAllVariants());
    }
  }, [dispatch, id]);
  // fetch 
  useEffect(() => {
    if (stock) {
      setInventoryName(stock?.inventoryName);
      setPrimaryVariations(stock?.primaryVariations);
      setPrimaryVariant(stock?.primaryVariant?.name);
      setSecVariations(stock?.secondaryVariations);
      setSecVariant(stock?.secondaryVariant?.name);
    }
  }, [stock]);

  // set inventory variants
  useEffect(() => {
    if (primaryVariations.length > 0) {
      const variations = primaryVariations.map((variation, index) => {
        const inventoryUpc = '';

        const inventoryVariants = {
          id: index,
          name: `${inventoryName} ${variation}`,
          upc: inventoryUpc
        }
        return inventoryVariants;
      });
      setInventoryVariants(variations);

    }
  }, [inventoryName, primaryVariations]);

  // const updateUPC = (index, variantUPC) => {
  //   console.log(index, variantUPC)
  //   const updatedVariant = inventoryVariants.find((variant, i) => i === index);

  //   if (updatedVariant) {
  //     const updatedData = {
  //       id: updatedVariant.id,
  //       name: updatedVariant.name,
  //       upc: variantUPC
  //     };

  //     const updatedVariants = inventoryVariants.map((variant, i) => {
  //       if (i === index) {
  //         return updatedData;
  //       }
  //       return variant;
  //     });
  //     setInventoryVariants(updatedVariants);
  //   }
  // }

  // console.log(inventoryVariants)

  return (
    <div className="container">
      <Row className="mt-2">
        <Col xs={12} className="mt-2">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Variant Type</th>
                <th>Value</th>
                <th>Variants</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fw-bold">Primary Variant</td>
                <td>{stock?.primaryVariant?.name || "N/A"}</td>
                <td>{stock?.primaryVariations.join(", ") || "N/A"}</td>
                <td>
                  <span className="btn btn-primary"
                    onClick={() => setShowPrimaryVariantModal(true)}
                  >
                    Edit
                  </span>
                </td>
              </tr>
              {secVariant && (
                <tr>
                  <td className="fw-bold">Secondary Variant</td>
                  <td>{stock?.secondaryVariant?.name || "N/A"}</td>
                  <td>{stock?.secondaryVariations?.join(", ") || "N/A"}</td>
                  <td>
                    <span className="btn btn-primary"
                      onClick={() => setShowSecondaryVariantModal(true)}
                    >Edit</span>
                  </td>
                </tr>
              )
              }
            </tbody>
          </Table>
        </Col>

        <Col xs={12} className="mt-2">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Inventory Variant</th>
                <th>UPC</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {inventoryVariants.length > 0 &&
                inventoryVariants.map((variant, index) => (
                  <SingleInventoryVariant
                    key={index}
                    data={variant}
                    // updateUPC={updateUPC}
                    stockId={id}
                  />
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {/* Primary Variant Modal */}
      {showPrimaryVariantModal && (
        <PrimaryVariantModal
          show={showPrimaryVariantModal}
          handleClose={handleClosePrimaryVariantModal}
          primaryVariant={primaryVariant}
          inventoryId={id}
          primaryVariations={primaryVariations}
          setPrimaryVariations={setPrimaryVariations}
        />
      )}
      {/* Secondary Variant Modal */}
      {showSecondaryVariantModal && (
        <SecondaryVariantModal
          show={showSecondaryVariantModal}
          handleClose={handleCloseSecondaryVariantModal}
          inventoryId={id}
          secondaryVariant={secVariant}
          secondaryVariations={secVariations}
          setSecondaryVariations={setSecVariations}
        />
      )}
    </div>
  )
}
export default MultipleVariant