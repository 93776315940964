/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editStore, getAllStores } from '../../../redux/actions/storeAction';
import { toast } from 'react-toastify';
import { getAllBranches } from '../../../redux/actions/branchAction';

const EditStoreModal = ({ show, onHide, store }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.store);
  const { branches } = useSelector((state) => state.branch);

  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [contact, setContact] = useState('');
  const [branch, setBranch] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (store) {
      setName(store.name);
      setLocation(store.location);
      setContact(store.contact);
      setBranch(store.branch?._id || '');
      setActive(store.active);
    }
  }, [store]);

  useEffect(() => {
    dispatch(getAllBranches());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editStore(store._id, { name, location, contact, branch, active }));
  };

  useEffect(() => {
    if (success) {
      toast.success('Store updated successfully!');
      dispatch(getAllStores()); // Fetch updated store list
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Store</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Contact</Form.Label>
                <Form.Control
                  type="text"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Branch</Form.Label>
                <Form.Control
                  as="select"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  required
                >
                  <option value="">Select Branch</option>
                  {branches.map((branch) => (
                    <option key={branch._id} value={branch._id}>
                      {branch.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className='float-end' disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditStoreModal;
