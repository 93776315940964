import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getProductById } from "../../../../redux/actions/productAction";
import EditProductVariant from "./variants/EditProductVariant";

const ProductDetailsPage = () => {
  const { product } = useSelector((state) => state.product);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [productVariants, setProductVariants] = useState([]);
  const [variant, setVariant] = useState(null);

  // variant modals
  // const [showCreateVariantModal, setShowCreateVariantModal] = useState(false);
  const [showEditVariantModal, setShowEditVariantModal] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getProductById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (product) {
      setProductVariants(product?.productVariants);
    }
  }, [product]);

  // handle edit
  const handleEdit = (variant) => {
    setVariant(variant);
    setShowEditVariantModal(true);
  };

  // handle create
  // const handleCreate = () => {
  //   setVariant(null);
  //   setShowCreateVariantModal(true);
  // };

  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {product?.name}
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <h4>{product?.name} Variants</h4>
          <hr />
          {productVariants && productVariants.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Variant</th>
                  <th>Variant Type</th>
                  <th>Variants</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {productVariants.map((variant, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{variant?.name || variant}</td>
                    <td>
                      <p>Single</p>
                    </td>
                    <td>{variant?.price || "None"}</td>
                    <td>
                      <span
                        className="me-3 text-primary cursor-pointer"
                        title="Edit"
                        onClick={() => handleEdit(variant)}
                      >
                        <FaEdit size={20} />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
        {showEditVariantModal && (
          <EditProductVariant
            show={showEditVariantModal}
            onHide={() => setShowEditVariantModal(false)}
            variant={variant}
          />
        )}
      </Row>
    </div>
  )
}
export default ProductDetailsPage