import axios from "axios";
import { server } from "../../server/server";

// Create Billing
export const createBilling = (newBilling) => async (dispatch) => {
  try {
    dispatch({ type: "createBillingRequest" });

    const { data } = await axios.post(`${server}/billing/create-billing`, newBilling, {
      withCredentials: true,
    });

    dispatch({
      type: "createBillingSuccess",
      payload: data.billing,
    });
  } catch (error) {
    dispatch({
      type: "createBillingFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Billings
export const getAllBillings = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllBillingsRequest" });

    const { data } = await axios.get(`${server}/billing/get-all-billings`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllBillingsSuccess",
      payload: data.billings,
    });
  } catch (error) {
    dispatch({
      type: "getAllBillingsFail",
      payload: error.response.data.message,
    });
  }
};

// Edit Billing
export const editBilling = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editBillingRequest" });

    const { data } = await axios.put(`${server}/billing/update-billing/${id}`, updatedData, {
      withCredentials: true,
    });

    dispatch({
      type: "editBillingSuccess",
      payload: data.billing,
    });

    // Optionally, fetch all billings again to update the list
    dispatch(getAllBillings());
  } catch (error) {
    dispatch({
      type: "editBillingFail",
      payload: error.response.data.message,
    });
  }
};

// Delete Billing
export const deleteBilling = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteBillingRequest" });

    const { data } = await axios.delete(`${server}/billing/delete-billing/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteBillingSuccess",
      payload: data.message,
    });

    // Optionally, fetch all billings again to update the list
    dispatch(getAllBillings());
  } catch (error) {
    dispatch({
      type: "deleteBillingFail",
      payload: error.response.data.message,
    });
  }
};
