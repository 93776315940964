import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteProductUnit, getAllProductUnits } from '../../../redux/actions/productUnitAction.js';
import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal';
import { handleProductUnitFilter } from '../../components/utils/handleFilter.jsx';
import CreateProductUnitModal from './CreateProductUnitModal.jsx';
import EditProductUnitModal from './EditProductUnitModal.jsx';

const ProductUnitPage = () => {
  const dispatch = useDispatch();
  const { productUnits, error, message } = useSelector((state) => state.productUnit);


  /**search states */
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [editingProductUnit, setEditingProductUnit] = useState(null);
  const [productUnitIdToDelete, setProductUnitIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllProductUnits());
  }, [dispatch]);

  useEffect(() => {
    setRecords(productUnits);
  }, [productUnits])


  /** search function */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleProductUnitFilter(productUnits, term);
    setRecords(filteredData);
  };

  const handleDelete = (productUnitId) => {
    setProductUnitIdToDelete(productUnitId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteProductUnit(productUnitIdToDelete));
    setShowConfirmDeleteModal(false);
    setProductUnitIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setProductUnitIdToDelete(null);
  };

  const handleEdit = (productUnit) => {
    setEditingProductUnit(productUnit);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingProductUnit(null);
  };

  /** table columns */
  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row?.unitName,
    },
    {
      name: "Abbreviation",
      selector: (row) => row?.abbreviation,
    },
    {
      name: "Description",
      selector: (row) => row?.description,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          <span
            className="me-3 text-primary cursor-pointer"
            onClick={() => handleEdit(row)}
            title="Edit"
          >
            <FaEdit size={20} />
          </span>
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, message]);

  return (
    <>
      <div className="container-fluid">
        <Row className="my-1">
          <Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Product Units
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
        <Row className="my-1 justify-content-between align-items-center">
          <Col xs={12} md={4}>
            <h2>Product Units</h2>
          </Col>
          <Col xs={6} md={4}>
            <Form.Control
              type="text"
              placeholder="Search product unit..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Col>
          <Col xs={6} md={4}>
            <Button
              className="float-end"
              variant="primary"
              onClick={() => setShowCreateModal(true)}
            >
              Create New
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div id="printable-area">
              <DataTable
                columns={columns}
                data={records}
                fixedHeader
                pagination
                noDataComponent="No product units found"
                striped
                highlightOnHover
                responsive
              />
            </div>

            {showCreateModal && (
              <CreateProductUnitModal
                show={showCreateModal}
                onHide={handleCreateModalClose}
              />
            )}
            {showModal && editingProductUnit && (
              <EditProductUnitModal
                show={showModal}
                onHide={handleModalClose}
                productUnit={editingProductUnit}
              />
            )}

            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />


          </Col>
        </Row>

      </div>
      {/* <div className='container-fluid'>
        <Row className='mb-2'>
          <Col className="d-flex align-items-center">
            <h2 className='mb-0'>Product Units</h2>
            <Button className='ms-auto' variant="primary" onClick={() => setShowCreateModal(true)}>
              Add Unit
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="table-container">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Unit Name</th>
                    <th>Abbreviation</th>
                    <th>Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {productUnits && productUnits.map((productUnit, index) => (
                    <tr key={productUnit._id}>
                      <td>{index + 1}</td>
                      <td>{productUnit.unitName}</td>
                      <td>{productUnit.abbreviation}</td>
                      <td>{productUnit.description}</td>
                      <td>
                        <Button
                          variant="info"
                          onClick={() => handleEdit(productUnit)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          className='ms-2'
                          onClick={() => handleDelete(productUnit._id)}
                          disabled={isLoading}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {showCreateModal && (
              <CreateProductUnitModal
                show={showCreateModal}
                onHide={handleCreateModalClose}
              />
            )}
            {showModal && editingProductUnit && (
              <EditProductUnitModal
                show={showModal}
                onHide={handleModalClose}
                productUnit={editingProductUnit}
              />
            )}
            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />
          </Col>
        </Row>
      </div> */}
    </>
  );
};

export default ProductUnitPage;
