import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
};

export const cartReducer = createReducer(initialState, (builder) => {
  builder
    .addCase('addToCart', (state, action) => {
      const item = action.payload;


      // Check if the item with the same inventoryId already exists
      const isItemExist = state.cart.find((i) => i._id === item._id);

      if (isItemExist) {
        // If it exists, update the existing item
        state.cart = state.cart.map((i) => (i._id === isItemExist._id ? item : i));
      } else {
        // If not, add the new item to the cart
        state.cart.push(item);
      }
    })
    .addCase('removeFromCart', (state, action) => {
      // Remove the item by inventoryId
      state.cart = state.cart.filter((i) => i._id !== action.payload);
    });
});
