import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useProductUnitSelect, useStoreInventoryProduct } from '../../../utils/selectFunctions';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { createInventoryItem } from '../../../../redux/actions/storeInventoryAction';

const AddStoreInventoryModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, error } = useSelector((state) => state.storeInventory);
  const { stocks } = useSelector((state) => state.stock);
  const { productUnits } = useSelector((state) => state.productUnit);

  const { stockId, stockOptions, handleStockChange } = useStoreInventoryProduct(stocks);
  const { productUnitId, productUnitOptions, handleProductUnitChange } = useProductUnitSelect(productUnits);

  // selected stock
  const selectedStock = stocks && stocks.find((stock) => stock._id === stockId);

  // form data
  const [quantity, setQuantity] = useState('');
  const [packageCount, setPackageCount] = useState('12');
  const [totalQuantity, setTotalQuantity] = useState('');

  // handle product unit change
  const handleProductUnit = (selectedOption) => {
    handleProductUnitChange(selectedOption);
    setQuantity(selectedOption?.quantity ?? '');
  };

  // calculate total quantity
  useEffect(() => {
    if (quantity && packageCount) {
      setTotalQuantity(quantity * packageCount);
    }
  }, [quantity, packageCount]);

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const inventoryData = {
      stockId,
      packaging: productUnitId,
      quantity,
      packageCount,
      totalQuantity,
    };
    dispatch(createInventoryItem(inventoryData));

  };

  useEffect(() => {
    if (success) {
      toast.success('Inventory added successfully');
      navigate('/store-inventory');
      dispatch({ type: "clearSuccess" });
    }

    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
  }, [dispatch, error, navigate, success]);


  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/store-inventory"> Store Inventory</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add Inventory
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="d-flex justify-content-between align-items-center me-5">
            <h4>Add Inventory</h4>
            <p>
              Variant Type: <span className="ms-3 badge bg-primary">{selectedStock?.variantType}</span>
            </p>
          </div>
          <hr className='mt-2' />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="inventoryId">
                  <Form.Label>Select Product :</Form.Label>
                  <div className="d-flex">
                    <InputGroup>
                      <Select
                        value={stockOptions.find(option => option.value === stockId) || null}
                        onChange={handleStockChange}
                        options={stockOptions}
                        placeholder="Select Inventory"
                        isClearable
                        className="flex-grow-1"
                      />
                    </InputGroup>
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="packaging">
                  <Form.Label>Received In :</Form.Label>
                  <div className="d-flex">
                    <Select
                      value={productUnitOptions.find(option => option.value === productUnitId) || null}
                      onChange={handleProductUnit}
                      options={productUnitOptions}
                      placeholder="Select selling unit"
                      isClearable
                      className="flex-grow-1"
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="quantity">
                  <Form.Label>Quantity :</Form.Label>
                  <Form.Control
                    type="number"
                    name="quantity"
                    placeholder="Enter quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(capitalizeFirstLetter(e.target.value))}
                    readOnly
                    disabled
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="packageCount">
                  <Form.Label>Package Count :</Form.Label>
                  <Form.Control
                    type="number"
                    name="packageCount"
                    placeholder="Enter package count"
                    value={packageCount}
                    onChange={(e) => setPackageCount(capitalizeFirstLetter(e.target.value))}
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="totalQuantity">
                  <Form.Label>Total Quantity :</Form.Label>
                  <Form.Control
                    type="number"
                    name="totalQuantity"
                    placeholder="Enter total quantity"
                    value={totalQuantity}
                    onChange={(e) => setTotalQuantity(e.target.value)}
                    disabled
                    readOnly
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <div>
              <Button
                type="submit"
                variant="primary"
                className='float-end mb-2'
              >
                Add Inventory
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AddStoreInventoryModal;
