import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { getAllInvoices } from "../../../redux/actions/invoiceAction";
import { getAllProductUnits } from "../../../redux/actions/productUnitAction";
import { editPurchase, getSinglePurchase } from "../../../redux/actions/purchaseAction";
import { getAllSuppliers } from "../../../redux/actions/supplierAction";
import { getFormattedDate } from "../../../utils/TimeFormats";
import { useSupplierSelect } from "../../utils/selectFunctions";
import { getTodayDate, handleNumericInput, toUpperCase } from "../../utils/utils";
import CreateInvoiceModal from "../invoices/CreateInvoiceModal";
import CreateProductUnitModal from "../product-unit/CreateProductUnitModal";
import CreateProductModal from "../products/CreateProductModal";
import CreateSupplierModal from "../suppliers/CreateSupplierModal";

const EditPurchasePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, success, error, purchase } = useSelector((state) => state.purchase);
  const { suppliers } = useSelector((state) => state.supplier);
  const { invoices } = useSelector((state) => state.invoice);

  const { supplierId, setSupplierId, supplierOptions, handleSupplierChange } = useSupplierSelect(suppliers);

  const [invoiceNumber, setInvoiceNumber] = useState('');

  const [numberOfItems, setNumberOfItems] = useState();
  const [subTotal, setSubTotal] = useState();
  const [vatAmount, setVatAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState();
  const [date, setDate] = useState('');

  // state error
  const [errorInvoiceNumber, setErrorInvoiceNumber] = useState('');


  /** create product modals */
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const handleCreateProductModalClose = () => setShowCreateProductModal(false);

  /** create supplier modals */
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const handleCreateSupplierModalClose = () => setShowCreateSupplierModal(false);

  /** create product unit modals */
  const [showCreateProductUnitModal, setShowCreateProductUnitModal] = useState(false);
  const handleCreateProductUnitModalClose = () => setShowCreateProductUnitModal(false);

  /** create invoice modals */
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const handleCreateInvoiceModalClose = () => setShowCreateInvoiceModal(false);

  useEffect(() => {
    dispatch(getAllSuppliers());
    dispatch(getAllProductUnits());
    dispatch(getAllInvoices());
    dispatch(getSinglePurchase(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (purchase) {
      setSupplierId(purchase?.supplier?._id || '');
      setNumberOfItems(purchase?.numberOfItems || '');
      setSubTotal(purchase?.subTotal || '');
      setVatAmount(purchase?.vatAmount || '0');
      setTotalAmount(purchase?.totalAmount || '');
      setInvoiceNumber(purchase?.invoiceNumber || '');
      // Ensure the date is in 'YYYY-MM-DD' format
      const formattedDate = purchase?.date ? getFormattedDate(purchase.date) : getTodayDate();
      setDate(formattedDate);
    }
  }, [purchase, setSupplierId]);

  const maxDate = getTodayDate();

  // handle purchase date change
  const handlePurchaseDateChange = (e) => {
    setDate(e.target.value);
    // purchase date should not be greater than today
    if (e.target.value > maxDate) {
      toast.error('Purchase date should not be greater than today');
      setDate(maxDate);
    }
  };

  // handle purchase date blur
  const handlePurchaseDateBlur = (e) => {
    if (e.target.value === '') {
      setDate(maxDate);
    } else if (e.target.value > maxDate) {
      toast.error('Purchase date should not be greater than today');
      setDate(maxDate);
    }
  };

  // selected supplier
  const selectedSupplier = suppliers.find((supplier) => supplier._id === supplierId);
  const clientName = selectedSupplier?.company;


  // generate invoice number
  const generateInvoiceNumber = () => {
    if (!clientName) return; // Prevent errors if clientName is undefined

    const firstWord = clientName.split(' ')[0].toUpperCase(); // Extract the first word and convert to uppercase
    const invoiceNumber = `INV-${Math.floor(1000 + Math.random() * 9000)}`;
    // const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');

    const newInvoiceNumber = `${firstWord}-${invoiceNumber}`;
    setInvoiceNumber(newInvoiceNumber);
  };


  // handle invoice number change
  const handleInvoiceNumberChange = (e) => {
    const newInvoiceNumber = toUpperCase(e.target.value).trim();
    setInvoiceNumber(newInvoiceNumber);

    const invoiceExists = invoices?.some(invoice => invoice.invoiceNumber === newInvoiceNumber);
    if (invoiceExists) {
      toast.error('Invoice number already exists!');
      setErrorInvoiceNumber('Invoice number already exists!');
    } else {
      setErrorInvoiceNumber('');
    }
  };


  // handle sub total change
  const handleSubTotalChange = (e) => {
    setSubTotal(e.target.value);

    // calculate total amount
    const total = Number(e.target.value) + Number(vatAmount);
    const newTotal = total.toFixed(2);
    setTotalAmount(newTotal);
  };

  // handle vat change
  const handleVatChange = (e) => {
    setVatAmount(e.target.value);

    // calculate total amount
    const total = Number(subTotal) + Number(e.target.value);
    const newTotal = total.toFixed(2);
    setTotalAmount(newTotal);
  };

  // handle total amount change
  const handleTotalAmountChange = () => {
    const total = Number(subTotal) + Number(vatAmount);
    const newTotal = total.toFixed(2);
    setTotalAmount(newTotal);
  }


  // handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // purchase data
    const purchaseData = {
      supplier: supplierId,
      invoiceNumber,
      numberOfItems,
      subTotal,
      vatAmount,
      totalAmount,
      date,
    };

    dispatch(editPurchase(id, purchaseData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Purchase updated successfully!');
      dispatch({ type: "clearSuccess" });
      navigate('/purchases');
    }
  }, [success, dispatch, navigate]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
  }, [dispatch, error]);

  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/purchases">Purchases</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Purchase
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Edit Purchase</h2>
        </Col>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} lg={4}>
              <Form.Group className="mb-2">
                <Form.Label>Supplier</Form.Label>
                <div className="d-flex">
                  <Select
                    value={supplierOptions.find(option => option.value === supplierId)}
                    onChange={handleSupplierChange}
                    options={supplierOptions}
                    placeholder="Select supplier"
                    isClearable
                    className="flex-grow-1"
                    required
                  />
                  <Button
                    className="ms-2"
                    onClick={() => setShowCreateSupplierModal(true)}
                    variant="outline-primary"
                    type="button"
                  >
                    <FaPlus />
                  </Button>
                </div>
              </Form.Group>
            </Col>

            <Col sm={6} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Invoice Number</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={invoiceNumber}
                    onChange={handleInvoiceNumberChange}
                    placeholder="Enter invoice number"
                    required
                  />
                  <Button
                    variant="primary"
                    className="ms-2"
                    onClick={generateInvoiceNumber}
                    disabled={invoiceNumber}
                  >
                    Generate
                  </Button>
                </div>
              </Form.Group>
              {errorInvoiceNumber && <p className="text-danger">{errorInvoiceNumber}</p>}
            </Col>

            <Col sm={6} md={4} lg={4}>
              <Form.Group className="mb-2">
                <Form.Label>Total Items</Form.Label>
                <Form.Control
                  type="number"
                  value={numberOfItems}
                  onChange={(e) => handleNumericInput(e, setNumberOfItems)}
                  disabled={!invoiceNumber}
                  placeholder='Enter total items'
                  required
                />
              </Form.Group>
            </Col>
            <Col sm={4} md={4} lg={4}>
              <Form.Group className="mb-2">
                <Form.Label>VAT Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={vatAmount}
                  onChange={handleVatChange}
                  disabled={!numberOfItems}
                  placeholder='Enter VAT amount'
                  required
                />
              </Form.Group>
            </Col>

            <Col sm={4} md={4} lg={4}>
              <Form.Group className="mb-2">
                <Form.Label>Sub Total</Form.Label>
                <Form.Control
                  type="text"
                  value={subTotal}
                  onChange={handleSubTotalChange}
                  disabled={!vatAmount}
                  placeholder='Enter sub total'
                  required
                />
              </Form.Group>
            </Col>

            <Col sm={4} md={4} lg={4}>
              <Form.Group className="mb-2">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={totalAmount}
                  onChange={handleTotalAmountChange}
                  disabled
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Purchase Date</Form.Label>
                <Form.Control
                  type="date"
                  value={date}
                  onChange={handlePurchaseDateChange}
                  max={maxDate}
                  onBlur={handlePurchaseDateBlur}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" disabled={isLoading} className="float-end">
            {isLoading ? 'Updating...' : 'Update Purchase'}
          </Button>
        </Form>
        {showCreateProductModal && (
          <CreateProductModal
            show={showCreateProductModal}
            onHide={handleCreateProductModalClose}
          />
        )}
        {showCreateSupplierModal && (
          <CreateSupplierModal
            show={showCreateSupplierModal}
            onHide={handleCreateSupplierModalClose}
          />
        )}
        {showCreateProductUnitModal && (
          <CreateProductUnitModal
            show={showCreateProductUnitModal}
            onHide={handleCreateProductUnitModalClose}
          />
        )}
        {showCreateInvoiceModal && (
          <CreateInvoiceModal
            show={showCreateInvoiceModal}
            onHide={handleCreateInvoiceModalClose}
            invoiceType='purchase'
            clientId={supplierId}
          />
        )}
      </Row >
    </div>
  )
}
export default EditPurchasePage
