import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaEdit, FaPlus, FaTrash, FaEye } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deletePurchase, getAllPurchases } from '../../../redux/actions/purchaseAction.js';
import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal.jsx';
import { handlePurchaseFilter } from '../../components/utils/handleFilter.jsx';
import CreatePurchaseModal from './CreatePurchaseModal.jsx';
import ViewPurchaseItemsModal from './ViewPurchaseItemsModal.jsx';

const PurchasesPage = () => {
  const dispatch = useDispatch();
  const { purchases, error, message } = useSelector((state) => state.purchase);

  /**search states */
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  /** create modals */
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  /**delete modals */
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [purchaseIdToDelete, setPurchaseIdToDelete] = useState(null);

  /** view items */
  const [showViewItemsModal, setShowViewItemsModal] = useState(false);
  const handleViewItemsModalClose = () => setShowViewItemsModal(false);

  useEffect(() => {
    dispatch(getAllPurchases());
  }, [dispatch]);

  useEffect(() => {
    setRecords(purchases);
  }, [purchases]);

  /** search function */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handlePurchaseFilter(purchases, term);
    setRecords(filteredData);
  };



  /** delete functions */
  const handleDelete = (purchaseId) => {
    setPurchaseIdToDelete(purchaseId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deletePurchase(purchaseIdToDelete));
    toast.success("Purchase deleted successfully");
    setShowConfirmDeleteModal(false);
    setPurchaseIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setPurchaseIdToDelete(null);
  };

  // handle view items
  const handleViewItems = (purchaseId) => {
    setPurchaseIdToDelete(purchaseId);
    setShowViewItemsModal(true);
  }

  /** table columns */
  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    // {
    //   name: "Invoice ID",
    //   selector: (row) => row?.invoiceId?.invoiceNumber,
    // },
    {
      name: "Invoice Number",
      selector: (row) => row?.invoiceNumber,
    },
    {
      name: "Supplier Name",
      selector: (row) => row?.supplier?.name,
    },
    {
      name: "Items",
      selector: (row) => (
        <div className="d-flex align-items-center justify-content-between">
          {row?.numberOfItems}
          <span
            className="text-primary ms-2 cursor-pointer"
            onClick={() => handleViewItems(row._id)}
            title="View Items"
          >
            <FaEye size={20} />
          </span>
        </div>
      ),
      width: "120px"
    },
    {
      name: "VAT",
      selector: (row) => `Ksh.${row?.vatAmount
        ? row?.vatAmount
        : 0}`,
    },
    {
      name: "Total Amount",
      selector: (row) => `Ksh.${row?.totalAmount}`,
    },
    {
      name: "Purchase Date",
      selector: (row) => moment(row?.date).format('ll'),  // Format as 'll, h:mm A'
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          <Link to={`/purchases/edit/${row._id}`} className="text-primary me-2" title="View">
            <FaEdit size={20} />
          </Link>
          {/* <span
            className="me-3 text-primary cursor-pointer"
            // onClick={() => handleEdit(row)}

            title="Edit"
          >
            <FaEdit size={20} />
          </span> */}
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (message) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, message]);

  return (
    <div className="container-fluid">
      <Row className="mt-2">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Purchases
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Purchases</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search purchases..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={6} md={4}>
          {/* <Button
            className="float-end"
            variant="primary"
            onClick={() => setShowCreateModal(true)}
          >
            <FaPlus /> Create
          </Button> */}
          <Link to="/purchases/create" className="btn btn-primary float-end">
            <FaPlus /> Create
          </Link>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              noDataComponent="No purchases found"
              striped
              highlightOnHover
              responsive
            />
          </div>

          {showCreateModal && (
            <CreatePurchaseModal
              show={showCreateModal}
              onHide={handleCreateModalClose}
            />
          )}

          <ConfirmDeleteModal
            show={showConfirmDeleteModal}
            onClose={handleCloseConfirmDeleteModal}
            onConfirm={handleConfirmDelete}
          />

          {showViewItemsModal && (
            <ViewPurchaseItemsModal
              show={showViewItemsModal}
              onHide={handleViewItemsModalClose}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PurchasesPage;
