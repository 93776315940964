/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllVariants, updateVariant } from '../../../redux/actions/variantAction';
import { capitalizeFirstLetter } from '../../utils/utils';

const EditVariantModal = ({ show, onHide, variant }) => {
  const { error, success } = useSelector((state) => state.variant);

  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    if (variant) {
      setName(variant.name || '');
      setType(variant.type || '');
    }
  }, [variant]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // variant data
    const variantData = {
      name,
      type
    };

    dispatch(updateVariant(variant._id, variantData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Variant updated successfully!');
      dispatch(getAllVariants()); // Fetch updated category list
      setName('');
      onHide(); // Close the modal
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [error, success, dispatch, onHide]);


  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Variant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="variantType" className='mb-3'>
            <Form.Label>Select Variant Type</Form.Label>
            <Form.Control
              as="select"
              value={type}
              onChange={(e) => setType(e.target.value)}
              placeholder='Select Variant Type'
              required
            >
              <option value="">Select Variant Type</option>
              <option value="Pricing">Pricing</option>
              <option value="Inventory">Inventory</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="variantName" className='mb-3'>
            <Form.Label>Variant Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter variant name"
              value={name}
              onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="float-end mt-3">
            Update
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditVariantModal;
