import axios from "axios";
import { server } from "../../server/server";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: "LoadUserRequest" });

    const { data } = await axios.get(`${server}/user/getuser`, {
      withCredentials: true,
    });

    dispatch({
      type: "LoadUserSuccess",
      payload: data.user,
    });

  } catch (error) {
    dispatch({
      type: "LoadUserFail",
      payload: error.response?.data?.message || 'Something went wrong',
    });
  }
};



// Fetch All Users
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllUsersRequest" });

    const { data } = await axios.get(`${server}/user/get-all-users`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllUsersSuccess",
      payload: data.users,
    });
  } catch (error) {
    dispatch({
      type: "getAllUsersFail",
      payload: error.response.data.message,
    });
  }
};

// Create User
export const createUser = (newUser) => async (dispatch) => {
  try {
    dispatch({ type: "userCreateRequest" });

    const { data } = await axios.post(`${server}/user/create-user`, newUser, {
      withCredentials: true,
    });

    dispatch({
      type: "userCreateSuccess",
      payload: data.user,
    });

    dispatch(getAllUsers());
  } catch (error) {
    dispatch({
      type: "userCreateFail",
      payload: error.response.data.message,
    });
  }
};

// Edit User
export const editUser = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editUserRequest" });

    const { data } = await axios.put(`${server}/user/update-user/${id}`, updatedData, {
      withCredentials: true,
    });

    dispatch({
      type: "editUserSuccess",
      payload: data.user,
    });

    // Optionally, fetch all users again to update the list
    dispatch(getAllUsers());
  } catch (error) {
    dispatch({
      type: "editUserFail",
      payload: error.response.data.message,
    });
  }
};

// Delete User
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteUserRequest" });

    const { data } = await axios.delete(`${server}/user/delete-user/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteUserSuccess",
      payload: data.message,
    });

    // Optionally, fetch all users again to update the list
    dispatch(getAllUsers());
  } catch (error) {
    dispatch({
      type: "deleteUserFail",
      payload: error.response.data.message,
    });
  }
};

// Logout User
export const logoutUser = () => async (dispatch) => {
  try {
    // Make API request to logout
    await axios.get(`${server}/user/logout`, {
      withCredentials: true, // Ensure credentials are sent with the request
    });

    // Dispatch success action
    dispatch({
      type: "LogoutSuccess",
    });

    // Clear token from local storage (if applicable)
    localStorage.removeItem("token");

  } catch (error) {
    // Dispatch failure action
    dispatch({
      type: "LogoutFail",
      payload: error.response?.data?.message || 'Something went wrong',
    });
  }
};