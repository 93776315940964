/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const SellingUnits = ({ sellingUnits, setSellingUnits }) => {
  const [unitQuantities, setUnitQuantities] = useState({});

  useEffect(() => {
    const initialQuantities = sellingUnits.reduce((acc, unit) => {
      acc[unit._id] = unit.quantity || "";
      return acc;
    }, {});
    setUnitQuantities(initialQuantities);
  }, [sellingUnits]);

  // const handleQuantityBlur = (id) => {
  //   const parsedValue = unitQuantities[id];

  //   if (parsedValue && stockQuantity % parsedValue !== 0) {
  //     toast.error(`Stock quantity must be divisible by ${stockQuantity}`);
  //     setUnitQuantities((prevQuantities) => ({
  //       ...prevQuantities,
  //       [id]: "",
  //     }));

  //     const updatedSellingUnits = sellingUnits.map((unit) => {
  //       if (unit._id === id) {
  //         return { ...unit, quantity: "" };
  //       }
  //       return unit;
  //     });

  //     setSellingUnits(updatedSellingUnits);
  //   }
  // };

  const handleQuantityChange = (e, id) => {
    const value = e.target.value;

    if (!/^\d*$/.test(value)) {
      toast.error("Only numbers (0-9) are allowed!");
      return;
    }

    const parsedValue = value ? parseInt(value, 10) : "";

    // Update the local state
    setUnitQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: parsedValue,
    }));

    // Update the selling units
    const updatedSellingUnits = sellingUnits.map((unit) => {
      if (unit._id === id) {
        return { ...unit, quantity: parsedValue };
      }
      return unit;
    });

    setSellingUnits(updatedSellingUnits);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h5 className="fw-bold">Selling Units: </h5>
          <table className="table table-bordered table-responsive">
            <thead>
              <tr>
                <th>Selling Unit</th>
                <th>Quantity</th>
                {/* <th>Price</th> */}
              </tr>
            </thead>
            <tbody>
              {sellingUnits && sellingUnits.map((sellingUnit) => (
                <tr key={sellingUnit._id}>
                  <td>{sellingUnit.unitName}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={unitQuantities[sellingUnit._id] || ""}
                      onChange={(e) => handleQuantityChange(e, sellingUnit._id)}
                      // onBlur={() => handleQuantityBlur(sellingUnit._id)}
                      required
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default SellingUnits