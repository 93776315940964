/* eslint-disable react/prop-types */

import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateInventoryVariant } from "../../../../../redux/actions/stockAction";

const SingleInventoryVariant = ({ stockId, data }) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector(state => state.stock);


  const [variantName, setVariantName] = useState('');
  const [inventoryUPC, setInventoryUPC] = useState('');

  useEffect(() => {
    if (data) {
      setVariantName(data?.name);
    }
  }, [data]);

  // generate upc
  const handleGenerateUPC = () => {
    const upc = Math.floor(100000000 + Math.random() * 900000000);
    setInventoryUPC(upc);
  };

  // handle submit
  const updateUPC = () => {
    if (inventoryUPC === '') {
      toast.error('Please enter UPC');
      return;
    }

    const inventoryVariantData = {
      name: variantName,
      upc: inventoryUPC,
    };
    dispatch(updateInventoryVariant(stockId, inventoryVariantData));
  }

  // clear success and error
  useEffect(() => {
    if (success) {
      toast.success('Inventory variant updated successfully');
      dispatch({ type: "clearSuccess" });
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }
  }, [success, error, dispatch]);



  return (
    <tr className="align-middle align-content-center">
      <td>{variantName}</td>
      <td>
        {inventoryUPC === '' ? (

          <Button onClick={handleGenerateUPC}>
            Generate UPC
          </Button>
        ) : (
          <input
            type="text"
            value={inventoryUPC}
            onChange={(e) => setInventoryUPC(e.target.value)}
            className="form-control"
            readOnly
          />
        )}
      </td>
      <td>
        <Button
          onClick={() => updateUPC(data.id, inventoryUPC)}
        >
          Save
        </Button>
      </td>
    </tr>
  )
}
export default SingleInventoryVariant