import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  invoice: null,
  invoices: [],
  success: false,
  error: null,
  message: null,
};

export const invoiceReducer = createReducer(initialState, (builder) => {
  builder
    // Create Invoice
    .addCase('createInvoiceRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createInvoiceSuccess', (state, action) => {
      state.isLoading = false;
      state.invoice = action.payload;
      state.success = true;
    })
    .addCase('createInvoiceFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Invoices
    .addCase('getAllInvoicesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllInvoicesSuccess', (state, action) => {
      state.isLoading = false;
      state.invoices = action.payload;
    })
    .addCase('getAllInvoicesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Single Invoice
    .addCase('getInvoiceRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getInvoiceSuccess', (state, action) => {
      state.isLoading = false;
      state.invoice = action.payload;
    })
    .addCase('getInvoiceFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Invoice
    .addCase('editInvoiceRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editInvoiceSuccess', (state, action) => {
      state.isLoading = false;
      state.invoice = action.payload;
      state.success = true;
      state.message = 'Invoice updated successfully!';
    })
    .addCase('editInvoiceFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Invoice
    .addCase('deleteInvoiceRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteInvoiceSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteInvoiceFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
