import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBasicSettings, updateBasicSettings } from '../../../../redux/actions/settingsAction';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from '../../../utils/utils';

const BasicSettings = () => {
  const { basicSettings, isLoading, success, error } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const [siteName, setSiteName] = useState('');
  const [siteURL, setSiteURL] = useState('');
  const [logoURL, setLogoURL] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [aboutUs, setAboutUs] = useState('');
  const [socialLinks, setSocialLinks] = useState({
    facebook: '',
    twitter: '',
    instagram: '',
  });

  useEffect(() => {
    dispatch(getBasicSettings());
  }, [dispatch]);

  useEffect(() => {
    if (basicSettings) {
      setSiteName(basicSettings.siteName || '');
      setSiteURL(basicSettings.siteURL || '');
      setLogoURL(basicSettings.logoURL || '');
      setContactEmail(basicSettings.contactEmail || '');
      setContactPhone(basicSettings.contactPhone || '');
      setSocialLinks(basicSettings.socialLinks || {
        facebook: '',
        twitter: '',
        instagram: '',
      });
      setAboutUs(basicSettings.aboutUs || '');
    }
  }, [basicSettings, isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      siteName,
      siteURL,
      logoURL,
      contactEmail,
      contactPhone,
      socialLinks,
      aboutUs,
    };

    dispatch(updateBasicSettings(data));
  };

  useEffect(() => {
    if (success) {
      // dispatch(getBasicSettings());
      dispatch({ type: "clearSuccess" });
    }

    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
  }, [success, dispatch, error]);

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h1>Site Settings</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="siteName">
                  <Form.Label>Site Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter site name"
                    name="siteName"
                    value={siteName}
                    onChange={(e) => setSiteName(capitalizeFirstLetter(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="siteURL">
                  <Form.Label>Site URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter site URL"
                    name="siteURL"
                    value={siteURL}
                    onChange={(e) => setSiteURL(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="logoURL">
                  <Form.Label>Logo URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter logo URL"
                    name="logoURL"
                    value={logoURL}
                    onChange={(e) => setLogoURL(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="contactEmail">
                  <Form.Label>Contact Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter contact email"
                    name="contactEmail"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="contactPhone">
                  <Form.Label>Contact Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter contact phone"
                    name="contactPhone"
                    value={contactPhone}
                    onChange={(e) => setContactPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="socialLinks.facebook">
                  <Form.Label>Facebook URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Facebook URL"
                    name="socialLinks.facebook"
                    value={socialLinks.facebook}
                    onChange={(e) => setSocialLinks((prevLinks) => ({ ...prevLinks, facebook: e.target.value }))}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="socialLinks.twitter">
                  <Form.Label>Twitter URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Twitter URL"
                    name="socialLinks.twitter"
                    value={socialLinks.twitter}
                    onChange={(e) => setSocialLinks((prevLinks) => ({ ...prevLinks, twitter: e.target.value }))}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="socialLinks.instagram">
                  <Form.Label>Instagram URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Instagram URL"
                    name="socialLinks.instagram"
                    value={socialLinks.instagram}
                    onChange={(e) => setSocialLinks((prevLinks) => ({ ...prevLinks, instagram: e.target.value }))}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="aboutUs">
                  <Form.Label>About Us</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter about us information"
                    name="aboutUs"
                    value={aboutUs}
                    onChange={(e) => setAboutUs(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button className='float-end' variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Update Settings'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default BasicSettings;
