import moment from 'moment';


// format like Mar 11th 25
export const formatTime = (time) => {
  return moment(time).format("MMM Do YY");
}

// Mar 11, 2025 6:36 PM
export const formatTimeWithTime = (time) => {
  return moment(time).format("MMM Do, YYYY h:mm A");
}

export const getFormattedDate = (date) => {
  return new Date(date).toISOString().split('T')[0];
}