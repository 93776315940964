import axios from "axios";
import { server } from "../../server/server";

// Create Subcategory
export const createSubcategory = (newSubcategory) => async (dispatch) => {
  try {
    dispatch({ type: "createSubcategoryRequest" });

    const { data } = await axios.post(
      `${server}/subcategory/create-subcategory`,
      newSubcategory
    );

    dispatch({
      type: "createSubcategorySuccess",
      payload: data.subcategory,
    });
  } catch (error) {
    dispatch({
      type: "createSubcategoryFail",
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Fetch All Subcategories
export const getAllSubcategories = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllSubcategoriesRequest" });

    const { data } = await axios.get(
      `${server}/subcategory/get-all-subcategories`
    );

    dispatch({
      type: "getAllSubcategoriesSuccess",
      payload: data.subcategories,
    });
  } catch (error) {
    dispatch({
      type: "getAllSubcategoriesFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Fetch Subcategory by ID
export const getSubcategoryById = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getSubcategoryByIdRequest" });

    const { data } = await axios.get(
      `${server}/subcategory/get-subcategory/${id}`
    );

    dispatch({
      type: "getSubcategoryByIdSuccess",
      payload: data.subcategory,
    });
  } catch (error) {
    dispatch({
      type: "getSubcategoryByIdFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Edit Subcategory
export const editSubcategory = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editSubcategoryRequest" });

    const { data } = await axios.put(
      `${server}/subcategory/update-subcategory/${id}`,
      updatedData
    );

    dispatch({
      type: "editSubcategorySuccess",
      payload: data.subcategory,
    });

    // Optionally, fetch all subcategories again to update the list
    dispatch(getAllSubcategories());
  } catch (error) {
    dispatch({
      type: "editSubcategoryFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Delete Subcategory
export const deleteSubcategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteSubcategoryRequest" });

    const { data } = await axios.delete(
      `${server}/subcategory/delete-subcategory/${id}`
    );

    dispatch({
      type: "deleteSubcategorySuccess",
      payload: data.message,
    });

    // Optionally, fetch all subcategories again to update the list
    dispatch(getAllSubcategories());
  } catch (error) {
    dispatch({
      type: "deleteSubcategoryFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};
