/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addInventoryQuantity, getInventoryItemDetails } from "../../../../../redux/actions/storeInventoryAction";

const AddInventory = ({ show, onClose, inventoryItem }) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state.storeInventory);

  const [currentStock, setCurrentStock] = useState(inventoryItem.currentStock);
  const [quantity, setQuantity] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault()
    const inventoryData = {
      id: inventoryItem._id,
      quantity: parseInt(quantity),
    }

    dispatch(addInventoryQuantity(inventoryData.id, inventoryData.quantity))
  }
  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
      toast.error(error);
    }

    if (success) {
      toast.success("Item quantity added successfully!");
      dispatch(getInventoryItemDetails(inventoryItem._id));
      onClose();
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, inventoryItem._id, onClose, success]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add {inventoryItem?.name} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Current Stock</Form.Label>
            <Form.Control
              type="number"
              value={currentStock}
              onChange={(e) => setCurrentStock(e.target.value)}
              disabled
              required
              readOnly
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              required
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            disabled={quantity <= 0}
            className="float-end"
          >
            Adjust Stock
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default AddInventory