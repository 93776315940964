import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal.jsx';
import { handleOrderFilter } from '../../components/utils/handleFilter.jsx';

import CreateOrderModal from './CreateOrderModal.jsx';
import EditOrderModal from './EditOrderModal.jsx';
import { deleteOrder, getAllOrders } from '../../../redux/actions/orderAction.js';

const OrdersPage = () => {
  const dispatch = useDispatch();
  const { orders, error, success } = useSelector((state) => state.order);

  /**search states */
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  /** create modals */
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  /** edit modals */
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingOrder, setEditingOrder] = useState(null);

  /**delete modals */
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [orderIdToDelete, setOrderIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  useEffect(() => {
    setRecords(orders);
  }, [orders]);

  /** search function */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleOrderFilter(orders, term);
    setRecords(filteredData);
  };

  /** edit functions */
  const handleEdit = (order) => {
    setEditingOrder(order);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setEditingOrder(null);
  };

  /** delete functions */
  const handleDelete = (orderId) => {
    setOrderIdToDelete(orderId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteOrder(orderIdToDelete));
    setShowConfirmDeleteModal(false);
    setOrderIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setOrderIdToDelete(null);
  };

  /** table columns */
  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Order ID",
      selector: (row) => row?.orderId,
    },
    {
      name: "Customer Name",
      selector: (row) => row?.customer?.name,
    },
    {
      name: "Total Amount",
      selector: (row) => row?.totalAmount,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          <span
            className="me-3 text-primary cursor-pointer"
            onClick={() => handleEdit(row)}
            title="Edit"
          >
            <FaEdit size={20} />
          </span>
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Orders
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Orders</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search orders..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={6} md={4}>
          <Button
            className="float-end"
            variant="primary"
            onClick={() => setShowCreateModal(true)}
          >
            Create Order
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              noDataComponent="No orders found"
              striped
              highlightOnHover
              responsive
            />
          </div>

          {showCreateModal && (
            <CreateOrderModal
              show={showCreateModal}
              onHide={handleCreateModalClose}
            />
          )}
          {showEditModal && editingOrder && (
            <EditOrderModal
              show={showEditModal}
              onHide={handleEditModalClose}
              order={editingOrder}
            />
          )}

          <ConfirmDeleteModal
            show={showConfirmDeleteModal}
            onClose={handleCloseConfirmDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OrdersPage;