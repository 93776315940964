/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllRoles } from '../../../redux/actions/roleAction.js';
import { createUser, getAllUsers } from '../../../redux/actions/userAction.js';
import { capitalizeFirstLetter } from '../../utils/utils.js';
import { getAllStores } from '../../../redux/actions/storeAction.js';

const CreateUserModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, users } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.role);
  const { stores } = useSelector((state) => state.store);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const [store, setStore] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllStores());
  }, [dispatch]);

  const handleFirstNameChange = (e) => {
    const newFirstName = capitalizeFirstLetter(e.target.value);
    setFirstName(newFirstName);

    const userExists = users.some(user => user.firstName === newFirstName);
    if (userExists) {
      toast.error('User first name already exists!');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      role,
      store,
      active,
    };

    dispatch(createUser(userData));
  };

  useEffect(() => {
    if (success) {
      toast.success('User created successfully!');
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setRole('');
      setActive(true);
      onHide(); // Close the modal
      dispatch(getAllUsers());
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  placeholder="Enter first name"
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter last name"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Store</Form.Label>
                <Form.Control
                  as="select"
                  value={store}
                  onChange={(e) => setStore(e.target.value)}
                  required
                >
                  <option value="">Select store</option>
                  {stores.map((store) => (
                    <option key={store._id} value={store._id}>
                      {store.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                >
                  <option value="">Select role</option>
                  {roles.map((role) => (
                    <option key={role._id} value={role._id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading} className='float-end'>
            {isLoading ? 'Creating...' : 'Create User'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserModal;
