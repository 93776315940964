import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const subcategoryReducer = createReducer(initialState, (builder) => {
  builder
    // Create Subcategory
    .addCase("createSubcategoryRequest", (state) => {
      state.isLoading = true;
    })
    .addCase("createSubcategorySuccess", (state, action) => {
      state.isLoading = false;
      state.subcategory = action.payload;
      state.success = true;
    })
    .addCase("createSubcategoryFail", (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Subcategories
    .addCase("getAllSubcategoriesRequest", (state) => {
      state.isLoading = true;
    })
    .addCase("getAllSubcategoriesSuccess", (state, action) => {
      state.isLoading = false;
      state.subcategories = action.payload;
    })
    .addCase("getAllSubcategoriesFailed", (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Subcategory by ID
    .addCase("getSubcategoryByIdRequest", (state) => {
      state.isLoading = true;
    })

    .addCase("getSubcategoryByIdSuccess", (state, action) => {
      state.isLoading = false;
      state.subcategory = action.payload;
    })
    .addCase("getSubcategoryByIdFailed", (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Subcategory
    .addCase("editSubcategoryRequest", (state) => {
      state.isLoading = true;
    })
    .addCase("editSubcategorySuccess", (state, action) => {
      state.isLoading = false;
      state.subcategory = action.payload;
      state.success = true;
    })
    .addCase("editSubcategoryFailed", (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Subcategory
    .addCase("deleteSubcategoryRequest", (state) => {
      state.isLoading = true;
    })
    .addCase("deleteSubcategorySuccess", (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase("deleteSubcategoryFailed", (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase("clearErrors", (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase("clearSuccess", (state) => {
      state.message = null;
      state.success = false;
    });
});
