/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { Nav, Offcanvas } from 'react-bootstrap';
import { FaBox, FaBoxes, FaCodeBranch, FaStore, FaTags, FaUsers, FaUserShield, FaWarehouse } from 'react-icons/fa';
import { MdPointOfSale } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const AdminSidebar = ({ show, handleClose, handleShow }) => {
  const { user } = useSelector((state) => state.user);
  const roleName = user && user?.role?.name;

  // Use effect to show the offcanvas by default on large screens
  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 992px)');
    if (mediaQuery.matches) {
      handleShow(); // Show the sidebar by default on large screens
    }

    const handleResize = () => {
      if (mediaQuery.matches) {
        handleShow(); // Open the sidebar on large screens
      } else {
        handleClose(); // Close the sidebar on smaller screens
      }
    };

    mediaQuery.addEventListener('change', handleResize);
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, [handleShow, handleClose]);

  return (
    <Offcanvas show={show} onHide={handleClose} className="sidebar-nav" scroll={true}>
      <Offcanvas.Header>
        <span className="brand-text font-weight-light fs-5 cursor-pointer">Matrix Ultimate | Admin</span>
      </Offcanvas.Header>
      <hr className='w-100 m-0' />
      <Offcanvas.Body>
        <Nav className='mt-0'>
          <ul className="nav nav-pills flex-column" data-widget="treeview" data-accordion="false">
            {/* Show POS link for all roles */}
            <li className="nav-header mb-1">POINT OF SALE</li>
            <div className="nav-item mb-0">
              <NavLink to="pos-dashboard" className="nav-link px-3" onClick={handleClose}>
                <span className="me-2">
                  <MdPointOfSale className="nav-icon" /></span>
                <span>POS</span>
              </NavLink>
            </div>
            <div className="nav-item mb-0">
              <NavLink to="store-inventory" className="nav-link px-3" onClick={handleClose}>
                <span className="me-2">
                  <FaBoxes className="nav-icon" size={20} />
                </span>
                <span>Store Inventory</span>
              </NavLink>
            </div>

            {/* Show additional links based on role */}
            {(roleName === 'Administrator' || roleName === "Admin") && (
              <>
                <li className="nav-header">DASHBOARD</li>
                <div className="nav-item mb-0">
                  <NavLink to="dashboard" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2">
                      <i className="nav-icon fas fa-tachometer-alt" /></span>
                    <span>Dashboard</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="branches" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2">
                      <FaCodeBranch className="nav-icon" size={20} />
                    </span>
                    <span>Branches</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="roles" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2">
                      <FaUserShield className="nav-icon" size={20} />
                    </span>
                    <span>Roles</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="stores" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2">
                      <FaStore className="nav-icon" size={20} />
                    </span>
                    <span>Stores</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="users" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2">
                      <FaUsers className="nav-icon" size={20} />
                    </span>
                    <span>Users</span>
                  </NavLink>
                </div>
                <li className="nav-header">INVENTORY MANAGEMENT</li>
                <div className="nav-item mb-0">
                  <NavLink to="product-categories" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><FaTags className="nav-icon" /></span>
                    <span>Product Categories</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="sub-categories" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><FaTags className="nav-icon" /></span>
                    <span>Sub Categories</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="product-units" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><FaBox className="nav-icon" /></span>
                    <span>Product Units</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="products" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><FaBoxes className="nav-icon" /></span>
                    <span>Products</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="inventory" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><FaWarehouse className="nav-icon" /></span>
                    <span>Inventory</span>
                  </NavLink>
                </div>
                <li className="nav-header">SALES MANAGEMENT</li>
                <div className="nav-item mb-0">
                  <NavLink to="suppliers" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-truck" /></span>
                    <span>Suppliers</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="purchases" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-shopping-cart" /></span>
                    <span>Purchases</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="customers" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-users" /></span>
                    <span>Customers</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="sales" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-shopping-cart" /></span>
                    <span>Sales</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="orders" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-receipt" /></span>
                    <span>Orders</span>
                  </NavLink>
                </div>

                <li className="nav-header">FINANCIAL MANAGEMENT</li>
                {/* <div className="nav-item mb-0">
                  <NavLink to="billing" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-file-invoice-dollar" /></span>
                    <span>Billing</span>
                  </NavLink>
                </div> */}
                <div className="nav-item mb-0">
                  <NavLink to="expenses" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-money-bill-wave" /></span>
                    <span>Expenses</span>
                  </NavLink>
                </div>
                {/* <li className="nav-header">REPORTS</li>
                <div className="nav-item mb-0">
                  <NavLink to="sales-reports" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-chart-line" /></span>
                    <span>Sales Reports</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="inventory-reports" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-warehouse" /></span>
                    <span>Inventory Reports</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="expenses-reports" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-money-bill-wave" /></span>
                    <span>Expense Reports</span>
                  </NavLink>
                </div>
                <div className="nav-item mb-0">
                  <NavLink to="profit-loss" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-balance-scale" /></span>
                    <span>Profit & Loss Reports</span>
                  </NavLink>
                </div> */}
                <li className="nav-header">SYSTEM SETTINGS</li>
                <div className="nav-item mb-0">
                  <NavLink to="settings" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2"><i className="nav-icon fas fa-cogs" /></span>
                    <span>Settings</span>
                  </NavLink>
                </div>
              </>
            )}

            {/* {roleName === 'Administrator' && (
              <>
                <div className="nav-item">
                  <NavLink to="stores" className="nav-link px-3" onClick={handleClose}>
                    <span className="me-2">
                      <FaStore className="nav-icon" size={20} />
                    </span>
                    <span>Stores</span>
                  </NavLink>
                </div>
              </>
            )} */}
          </ul>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AdminSidebar;
