import axios from "axios";
import { server } from "../../server/server";

// Get Total Sales
export const getTotalSales = () => async (dispatch) => {
  try {
    dispatch({ type: "getTotalSalesRequest" });

    const { data } = await axios.get(`${server}/dashboard/total-sales`, {
      withCredentials: true,
    });

    dispatch({
      type: "getTotalSalesSuccess",
      payload: data.totalSales,
    });
  } catch (error) {
    dispatch({
      type: "getTotalSalesFail",
      payload: error.response.data.message,
    });
  }
};

// Get store inventory value based on the total quantity and buying price
export const getStockValue = () => async (dispatch) => {
  try {
    dispatch({ type: "getStockValueRequest" });

    const { data } = await axios.get(`${server}/dashboard/store-inventory-value`, {
      withCredentials: true,
    });

    dispatch({
      type: "getStockValueSuccess",
      payload: data.storeInventoryValue,
    });
  } catch (error) {
    dispatch({
      type: "getStockValueFail",
      payload: error.response.data.message,
    });
  }
};

// Get Estimated Sales
export const getEstimatedSales = () => async (dispatch) => {
  try {
    dispatch({ type: "getEstimatedSalesRequest" });

    const { data } = await axios.get(`${server}/dashboard/estimated-sales`, {
      withCredentials: true,
    });

    dispatch({
      type: "getEstimatedSalesSuccess",
      payload: data.estimatedSales,
    });
  } catch (error) {
    dispatch({
      type: "getEstimatedSalesFail",
      payload: error.response.data.message,
    });
  }
};

// Get Gross Profit
export const getGrossProfit = () => async (dispatch) => {
  try {
    dispatch({ type: "getGrossProfitRequest" });

    const { data } = await axios.get(`${server}/dashboard/gross-profit`, {
      withCredentials: true,
    });

    dispatch({
      type: "getGrossProfitSuccess",
      payload: data.grossProfit,
    });
  } catch (error) {
    dispatch({
      type: "getGrossProfitFail",
      payload: error.response.data.message,
    });
  }
};

// Get Net Profit
export const getNetProfit = () => async (dispatch) => {
  try {
    dispatch({ type: "getNetProfitRequest" });

    const { data } = await axios.get(`${server}/dashboard/net-profit`, {
      withCredentials: true,
    });

    dispatch({
      type: "getNetProfitSuccess",
      payload: data.netProfit,
    });
  } catch (error) {
    dispatch({
      type: "getNetProfitFail",
      payload: error.response.data.message,
    });
  }
};
