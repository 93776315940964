import { toast } from "react-toastify";

// Capitalizes The First Letter Of A String
export const capitalizeFirstLetter = (str) => {
  if (!str) return str;  // If The String Is Empty, Return It As Is
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Converts A String To Uppercase
export const toUpperCase = (str) => {
  if (!str) return str;  // If The String Is Empty, Return It As Is
  return str.toUpperCase();
};

// Restricts Input To Numeric Characters Only
// export function handleNumericInput(e) {
//   e.target.value = e.target.value.replace(/[^0-9]/g, '');
// }

export function handleNumericInput(e, setterFunction) {
  const value = e.target.value;

  if (!/^\d*$/.test(value)) {
    toast.error("Only numbers (0-9) are allowed!");
    return;
  }

  // Convert to integer or set to empty string if the input is empty
  setterFunction(value ? parseInt(value, 10) : "");
}

export function handleNumericFloatInput(e, setterFunction) {
  let value = e.target.value;

  // Allow numbers with up to two decimal places
  if (!/^\d*\.?\d{0,2}$/.test(value)) {
    toast.error("Only numbers with up to 2 decimal places are allowed!");
    return;
  }

  // Prevent leading multiple zeros (except for decimals like 0.5)
  if (value.startsWith("0") && value.length > 1 && !value.startsWith("0.")) {
    value = value.replace(/^0+/, ""); // Remove leading zeros
  }

  setterFunction(value);
}


// To lowercase
export function toLowerCase(str) {
  if (!str) return str;  // If The String Is Empty, Return It As Is
  return str.toLowerCase();
}

// Get today's date in YYYY-MM-DD format
export const getTodayDate = () => {
  return new Date().toISOString().split('T')[0];
};