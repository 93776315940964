import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { deleteSubcategory, getAllSubcategories } from "../../../redux/actions/subcategoryAction.js";
import ConfirmDeleteModal from "../../components/confirm-delete/ConfirmDeleteModal.jsx";

import DataTable from "react-data-table-component";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { handleCategoryFilter } from "../../components/utils/handleFilter.jsx";

import CreateSubcategoryModal from "./CreateSubcategoryModal.jsx";
import EditSubcategoryModal from "./EditSubcategoryModal.jsx";
import { Link } from "react-router-dom";

const SubCategoryPage = () => {
  const { subcategories, error, success } = useSelector((state) => state.subcategory);
  const dispatch = useDispatch();

  /**search states */
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // State for modals
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingSubcategory, setEditingSubcategory] = useState(null);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [subcategoryIdToDelete, setSubcategoryIdToDelete] = useState(null);

  // Fetch subcategories when the component loads
  useEffect(() => {
    dispatch(getAllSubcategories());
  }, [dispatch]);

  useEffect(() => {
    setRecords(subcategories || []);
  }, [subcategories])

  /** search function */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleCategoryFilter(subcategories, term);
    setRecords(filteredData);
  };

  // Delete subcategory handler
  const handleDelete = (subcategoryId) => {
    setSubcategoryIdToDelete(subcategoryId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSubcategory(subcategoryIdToDelete));
    setShowConfirmDeleteModal(false);
    setSubcategoryIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setSubcategoryIdToDelete(null);
  };

  // Edit subcategory handler
  const handleEdit = (subcategory) => {
    setEditingSubcategory(subcategory);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  /** table columns */
  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Short Form",
      selector: (row) => row?.shortForm,
    },
    {
      name: "Category",
      sortable: true,
      selector: (row) => row?.category?.name,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          <span
            className="me-3 text-primary cursor-pointer"
            onClick={() => handleEdit(row)}
            title="Edit"
          >
            <FaEdit size={20} />
          </span>
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  return (
    <>
      <div className="container-fluid">
        <Row className="my-1">
          <Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/product-categories">Categories</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Sub Categories
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
        <Row className="my-2 justify-content-between align-items-center">
          <Col xs={12} md={4}>
            <h2>Sub Categories</h2>
          </Col>
          <Col xs={6} md={4}>
            <Form.Control
              type="text"
              placeholder="Search  sub category..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Col>
          <Col xs={6} md={4}>
            <Button
              className="float-end"
              variant="primary"
              onClick={() => setShowCreateModal(true)}
            >
              <FaPlus className='me-2' /> Add
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div id="printable-area">
              <DataTable
                columns={columns}
                data={records}
                fixedHeader
                pagination
                noDataComponent="No sub categories found"
                striped
                highlightOnHover
                responsive
              />
            </div>

            {showCreateModal && (
              <CreateSubcategoryModal
                show={showCreateModal}
                onHide={handleCreateModalClose}
              />
            )}
            {showEditModal && editingSubcategory && (
              <EditSubcategoryModal
                show={showEditModal}
                onHide={handleEditModalClose}
                subcategory={editingSubcategory}
              />
            )}

            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SubCategoryPage;
