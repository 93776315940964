/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createProductUnit, getAllProductUnits } from '../../../redux/actions/productUnitAction';
import { capitalizeFirstLetter, toUpperCase } from '../../utils/utils';

const CreateProductUnitModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.productUnit);

  const [unitName, setUnitName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [description, setDescription] = useState('Description');


  const handleSubmit = (e) => {
    e.preventDefault();

    const productUnitData = {
      unitName,
      abbreviation,
      description,
    };

    dispatch(createProductUnit(productUnitData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Product Unit created successfully!');
      dispatch(getAllProductUnits()); // Fetch updated product unit list
      setUnitName('');
      setAbbreviation('');
      setDescription('');
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Create Product Unit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Unit Name</Form.Label>
                <Form.Control
                  type="text"
                  value={unitName}
                  onChange={(e) => setUnitName(capitalizeFirstLetter(e.target.value))}
                  placeholder="Enter unit name"
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Abbreviation</Form.Label>
                <Form.Control
                  type="text"
                  value={abbreviation}
                  onChange={(e) => setAbbreviation(toUpperCase(e.target.value))}
                  placeholder="Enter abbreviation"
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(capitalizeFirstLetter(e.target.value))}
                  placeholder="Enter description"
                />
              </Form.Group>
            </Col>
          </Row>

          <Button className='float-end' variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProductUnitModal;
