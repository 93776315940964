import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  role: null,
  roles: [],
  success: false,
  error: null,
  message: null,
};

export const roleReducer = createReducer(initialState, (builder) => {
  builder
    // Create Role
    .addCase('createRoleRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createRoleSuccess', (state, action) => {
      state.isLoading = false;
      state.role = action.payload;
      state.success = true;
    })
    .addCase('createRoleFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Roles
    .addCase('getAllRolesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllRolesSuccess', (state, action) => {
      state.isLoading = false;
      state.roles = action.payload;
    })
    .addCase('getAllRolesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Role
    .addCase('editRoleRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editRoleSuccess', (state, action) => {
      state.isLoading = false;
      state.role = action.payload;
      state.success = true;
      state.message = 'Role updated successfully!';
    })
    .addCase('editRoleFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Role
    .addCase('deleteRoleRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteRoleSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteRoleFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
