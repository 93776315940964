import axios from "axios";

import { server } from "../../server/server";
// Create Variant
export const createVariant = (newVariant) => async (dispatch) => {
  try {
    dispatch({ type: "createVariantRequest" });

    const { data } = await axios.post(`${server}/variant/create-variant`, newVariant);

    dispatch({
      type: "createVariantSuccess",
      payload: data.variant,
    });
  } catch (error) {
    dispatch({
      type: "createVariantFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Variants
export const getAllVariants = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllVariantsRequest" });

    const { data } = await axios.get(`${server}/variant/get-all-variants`);

    dispatch({
      type: "getAllVariantsSuccess",
      payload: data.variants,
    });
  } catch (error) {
    dispatch({
      type: "getAllVariantsFailed",
      payload: error.response.data.message,
    });
  }
};

// Fetch Single Variant
export const getVariant = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getVariantRequest" });

    const { data } = await axios.get(`${server}/variant/get-variant/${id}`);

    dispatch({
      type: "getVariantSuccess",
      payload: data.variant,
    });
  } catch (error) {
    dispatch({
      type: "getVariantFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Variant
export const updateVariant = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "updateVariantRequest" });

    const { data } = await axios.put(`${server}/variant/update-variant/${id}`, updatedData);

    dispatch({
      type: "updateVariantSuccess",
      payload: data.variant,
    });

    // Optionally, fetch all variants again to update the list
    dispatch(getAllVariants());
  } catch (error) {
    dispatch({
      type: "updateVariantFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Variant
export const deleteVariant = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteVariantRequest" });

    const { data } = await axios.delete(`${server}/variant/delete-variant/${id}`);

    dispatch({
      type: "deleteVariantSuccess",
      payload: data.message,
    });

    // Optionally, fetch all variants again to update the list
    dispatch(getAllVariants());
  } catch (error) {
    dispatch({
      type: "deleteVariantFailed",
      payload: error.response.data.message,
    });
  }
};

// add variations to a variant
export const addVariation = (id, variantData) => async (dispatch) => {
  try {
    dispatch({ type: "addVariationRequest" });

    const { data } = await axios.put(`${server}/variant/add-variation/${id}`, variantData);

    dispatch({
      type: "addVariationSuccess",
      payload: data.variant,
    });

    // Optionally, fetch all variants again to update the list
    dispatch(getAllVariants());
  } catch (error) {
    dispatch({
      type: "addVariationFailed",
      payload: error.response.data.message,
    });
  }
};