import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  sale: null,
  sales: [],
  dailySales: [],
  weeklySales: [],
  monthlySales: [],
  success: false,
  error: null,
  message: null,
};

export const salesReducer = createReducer(initialState, (builder) => {
  builder
    // Create Sale
    .addCase('createSaleRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createSaleSuccess', (state, action) => {
      state.isLoading = false;
      state.sale = action.payload;
      state.success = true;
      state.message = "Sale created successfully!";
    })
    .addCase('createSaleFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Sales
    .addCase('getAllSalesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllSalesSuccess', (state, action) => {
      state.isLoading = false;
      state.sales = action.payload;
    })
    .addCase('getAllSalesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Sale by ID
    .addCase('getSaleByIdRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getSaleByIdSuccess', (state, action) => {
      state.isLoading = false;
      state.sale = action.payload;
    })
    .addCase('getSaleByIdFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Sales by Store
    .addCase('getSalesByStoreRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getSalesByStoreSuccess', (state, action) => {
      state.isLoading = false;
      state.sales = action.payload;
    })
    .addCase('getSalesByStoreFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Sale
    .addCase('editSaleRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editSaleSuccess', (state, action) => {
      state.isLoading = false;
      state.sale = action.payload;
      state.success = true;
      state.message = "Sale updated successfully!";
    })
    .addCase('editSaleFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Sale
    .addCase('deleteSaleRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteSaleSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteSaleFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Daily Sales
    .addCase('getDailySalesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getDailySalesSuccess', (state, action) => {
      state.isLoading = false;
      state.dailySales = action.payload;
    })
    .addCase('getDailySalesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Weekly Sales
    .addCase('getWeeklySalesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getWeeklySalesSuccess', (state, action) => {
      state.isLoading = false;
      state.weeklySales = action.payload;
    })
    .addCase('getWeeklySalesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Monthly Sales
    .addCase('getMonthlySalesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getMonthlySalesSuccess', (state, action) => {
      state.isLoading = false;
      state.monthlySales = action.payload;
    })
    .addCase('getMonthlySalesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
