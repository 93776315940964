import axios from "axios";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { server } from "../../../server/server";

const ChangePassword = () => {

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match");
    }

    const data = {
      currentPassword,
      newPassword
    };

    try {
      const response = await axios.put(`${server}/user/change-password`, data, { withCredentials: true });
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }

  }

  return (
    <div className="col-8">
      <h2 className="mb-4">Change Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="currentPassword" className="form-label">
            <strong>Current Password</strong>
          </label>
          <div className="input-group">
            <input
              type={currentPasswordVisible ? "text" : "password"}
              id="currentPassword"
              className="form-control"
              autoComplete="off"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Enter current password"
            />
            <span className="input-group-text">
              {currentPasswordVisible ? (
                <FaEye
                  onClick={toggleCurrentPasswordVisibility}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <FaEyeSlash
                  onClick={toggleCurrentPasswordVisibility}
                  style={{ cursor: "pointer" }}
                />
              )}
            </span>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="newPassword" className="form-label">
            <strong>New Password</strong>
          </label>
          <div className="input-group">
            <input
              type={newPasswordVisible ? "text" : "password"}
              id="newPassword"
              className="form-control"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
            />
            <span className="input-group-text">
              {newPasswordVisible ? (
                <FaEye
                  onClick={toggleNewPasswordVisibility}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <FaEyeSlash
                  onClick={toggleNewPasswordVisibility}
                  style={{ cursor: "pointer" }}
                />
              )}
            </span>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            <strong>Confirm New Password</strong>
          </label>
          <div className="input-group">
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              id="confirmPassword"
              className="form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
            />
            <span className="input-group-text">
              {confirmPasswordVisible ? (
                <FaEye
                  onClick={toggleConfirmPasswordVisibility}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <FaEyeSlash
                  onClick={toggleConfirmPasswordVisibility}
                  style={{ cursor: "pointer" }}
                />
              )}
            </span>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">Change Password</button>
      </form>
    </div>
  )
}
export default ChangePassword