import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  branch: null,
  branches: [],
  success: false,
  error: null,
  message: null,
};

export const branchReducer = createReducer(initialState, (builder) => {
  builder
    // Create Branch
    .addCase('createBranchRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createBranchSuccess', (state, action) => {
      state.isLoading = false;
      state.branch = action.payload;
      state.success = true;
    })
    .addCase('createBranchFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Branches
    .addCase('getAllBranchesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllBranchesSuccess', (state, action) => {
      state.isLoading = false;
      state.branches = action.payload;
    })
    .addCase('getAllBranchesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Branch
    .addCase('editBranchRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editBranchSuccess', (state, action) => {
      state.isLoading = false;
      state.branch = action.payload;
      state.success = true;
      state.message = 'Branch updated successfully!';
    })
    .addCase('editBranchFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Branch
    .addCase('deleteBranchRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteBranchSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteBranchFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
