import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSingleStock, updateStockInventoryVariations } from "../../../../redux/actions/stockAction";
import { getAllVariants } from "../../../../redux/actions/variantAction";

const InventoryVariations = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { stock } = useSelector(state => state.stock);

  // const { primaryInventoryId, primaryInventoryOptions, handlePrimaryInventoryChange } = usePrimaryInventory(variants);
  // const { secInventoryId, secInventoryOptions, handleSecInventoryChange } = useSecInventory(variants);

  const [primaryVariant, setPrimaryVariant] = useState('');
  const [primaryVariations, setPrimaryVariations] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleStock(id));
      dispatch(getAllVariants());
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (stock) {
      setPrimaryVariations(stock?.primaryVariations);
    }
  }, [stock]);

  // handle add primary variant
  const handleAddPrimaryVariant = () => {
    // if unit is not selected
    if (!primaryVariant) {
      toast.error('Please select a variant');
      return;
    }
    if (primaryVariant && !primaryVariations.includes(primaryVariant)) {
      setPrimaryVariations([...primaryVariations, primaryVariant]);
      setPrimaryVariant('');
      return true;
    } else {
      toast.error('Variant already added');
    }
  };

  // handle remove primary variant
  const handleRemoveSellingUnit = (variant) => {
    setPrimaryVariations(primaryVariations.filter((v) => v !== variant));
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (primaryVariations.length === 0) {
      toast.error('Please add at least one primary variant');
      return;
    }

    // inventory data
    const inventoryData = {
      primaryVariations
    };

    dispatch(updateStockInventoryVariations(id, inventoryData));
  };


  return (
    <div className="inventory-variations">
      <div className="container-fluid">
        <Row className="mt-2">
          <Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inventory">Inventory</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {stock?.inventoryName}
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
      </div>
      <div className="container-fluid">
        <Row className="mt-2">
          <Col lg={6}>
            <h3>Inventory Variations</h3>
            <hr />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Add {stock?.primaryVariant?.name || "Primary"} Variant :</Form.Label>
                    <div className="d-flex">
                      <InputGroup>
                        <Form.Control
                          as="select"
                          value={primaryVariant}
                          onChange={(e) => setPrimaryVariant(e.target.value)}
                        >
                          <option value="">Select {stock?.primaryVariant?.name || "Primary"} Inventory Variant</option>
                          <option value="Green">Green</option>
                          <option value="Red">Red</option>
                          <option value="Blue">Blue</option>
                        </Form.Control>
                        <Button
                          type="button"
                          title="Add Inventory Variant"
                          onClick={handleAddPrimaryVariant}
                        >
                          Add
                        </Button>
                      </InputGroup>
                    </div>
                  </Form.Group>
                  <div className="d-flex flex-wrap mb-2">
                    {primaryVariations && primaryVariations.map((variant, index) => (
                      <span key={index} className="badge bg-info d-inline-flex align-items-center me-2">
                        {variant}
                        <AiOutlineClose
                          size={15}
                          className="ms-2 cursor-pointer"
                          onClick={() => handleRemoveSellingUnit(variant)}
                        />
                      </span>
                    ))}
                  </div>
                </Col>

                {/* <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Secondary Variant :</Form.Label>
                  <div className="d-flex">
                    <InputGroup>
                      <Select
                        value={secInventoryOptions.find((v) => v.value === secInventoryId) || null}
                        options={secInventoryOptions}
                        onChange={handleSecInventoryChange}
                        isClearable
                        placeholder="Select Secondary Inventory Variant"
                        className="flex-grow-1"
                      />

                    </InputGroup>

                    <Button
                      className="ms-2"
                      type="button"
                      title="Add Inventory Variant"
                    // onClick={handleAddInventoryVariant}
                    >
                      <FaPlus />
                    </Button>
                  </div>
                </Form.Group>
              </Col> */}
              </Row>
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  className='float-end mb-2'
                >
                  Update Inventory
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default InventoryVariations