/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { editProduct, getAllProducts } from '../../../../redux/actions/productAction.js';
import { capitalizeFirstLetter } from '../../../utils/utils.js';
import { usePricingAttributeSelect } from '../../../utils/selectFunctions.jsx';
import Select from 'react-select';

const EditPricingVariantModal = ({ show, onHide, product }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.product);
  const { variants } = useSelector((state) => state.variant);

  const { pricingAttributeOptions, handlePricingAttributeChange } = usePricingAttributeSelect(variants);

  const [name, setName] = useState('');
  const [pricingAttribute, setPricingAttribute] = useState("");

  const [pricingVariant, setPricingVariant] = useState("");
  const [pricingVariations, setPricingVariations] = useState([]);


  useEffect(() => {
    if (product) {
      setName(product?.name || '');
      setPricingAttribute(product?.pricingAttribute?.name || '');
      setPricingVariations(product?.pricingVariations || []);
    }
  }, [product]);

  // variation functions
  const handleAddPricingVariant = () => {
    const trimmedPricingVariant = pricingVariant.trim();
    if (trimmedPricingVariant && !pricingVariations.includes(trimmedPricingVariant)) {
      setPricingVariations((prev) => [...prev, trimmedPricingVariant]);
      setPricingVariant("");
    } else if (pricingVariations.includes(trimmedPricingVariant)) {
      toast.error("This variant already exists");
    }
  };

  const handleRemovePricingVariation = (pricingVariant) => {
    setPricingVariations(pricingVariations.filter((pricingVariation) => pricingVariation !== pricingVariant));
  };


  // Handle pricing attribute change
  const handlePricingChangeAttribute = (selectedOption) => {
    handlePricingAttributeChange(selectedOption);
    setPricingAttribute(selectedOption ? selectedOption.label : "");
  };

  /** handle submit function */
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation for required fields
    if (!name || !pricingAttribute) {
      toast.error('Please fill out all required fields.');
      return;
    }

    if (pricingVariations.length === 0) {
      toast.error(`Please add at least one ${pricingAttribute?.name}.`);
      return;
    }

    // Create product data object
    const updatedProductData = {
      name,
      pricingAttribute: product?.pricingAttribute?._id,
      pricingVariations,
    };
    // Dispatch the action to edit the product
    dispatch(editProduct(product._id, updatedProductData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Product updated successfully!');
      dispatch(getAllProducts());
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Edit Pricing Variant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
                  placeholder='Enter product name'
                  disabled
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Pricing Variant :</Form.Label>
                <div className="d-flex">
                  <Select
                    value={pricingAttributeOptions.find(option => option.label === pricingAttribute) || null}
                    onChange={handlePricingChangeAttribute}
                    options={pricingAttributeOptions}
                    placeholder="Select pricing variant"
                    isClearable
                    className="flex-grow-1"
                    required
                  />
                </div>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-2">
                <Form.Label>Available {pricingAttribute?.name} Variations :</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={pricingVariant}
                    onChange={(e) => setPricingVariant(capitalizeFirstLetter(e.target.value))}
                  />
                  <Button
                    className="ms-2"
                    onClick={() => handleAddPricingVariant()}
                    type="button"
                    variant="primary"
                  >
                    Add
                  </Button>
                </div>
              </Form.Group>
              <div className="mt-2">
                {pricingVariations?.map((pricingVariant, i) => (
                  <span key={i} className="badge bg-info d-inline-flex align-items-center me-2">
                    {pricingVariant}
                    <AiOutlineClose
                      size={15}
                      className="ms-2 cursor-pointer"
                      onClick={() => handleRemovePricingVariation(pricingVariant)}
                    />
                  </span>
                ))}
              </div>
            </Col>
          </Row>
          <div className="float-end mt-1">
            <Button
              className="ms-3"
              type="submit"
              variant="primary"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Update Product'}
            </Button>
          </div>
        </Form>

      </Modal.Body>
    </Modal>
  );
};

export default EditPricingVariantModal;
