/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createVariant, getAllVariants } from '../../../redux/actions/variantAction.js';
import { capitalizeFirstLetter } from '../../utils/utils.js';
import { toast } from 'react-toastify';

const CreateVariantModal = ({ show, onHide, variantType }) => {
  const { error, success } = useSelector((state) => state.variant);

  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    setType(variantType)
  }, [variantType]);


  const handleSubmit = (e) => {
    e.preventDefault();
    // variant data
    const variantData = {
      name,
      type
    };
    dispatch(createVariant(variantData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Variant created successfully!');
      dispatch(getAllVariants());
      setName('');
      onHide();
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [error, success, dispatch, onHide]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Create Variant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {variantType ? (
            <Form.Group controlId="variantType" className='mb-3'>
              <Form.Label>Variant Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter variant type"
                value={type}
                onChange={(e) => setType(capitalizeFirstLetter(e.target.value))}
                disabled
                required
              />
            </Form.Group>
          ) : (
            <Form.Group controlId="variantType" className='mb-3'>
              <Form.Label>Select Variant Type</Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={(e) => setType(e.target.value)}
                placeholder='Select Variant Type'
                required
              >
                <option value="">Select Variant Type</option>
                <option value="Pricing">Pricing</option>
                <option value="Inventory">Inventory</option>
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group controlId="variantName" className='mb-3'>
            <Form.Label>Variant Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter variant name"
              value={name}
              onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="float-end mt-3">
            Create
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateVariantModal;
