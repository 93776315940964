import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  billing: null,
  billings: [],
  success: false,
  error: null,
  message: null,
};

export const billingReducer = createReducer(initialState, (builder) => {
  builder
    // Create Billing
    .addCase('createBillingRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createBillingSuccess', (state, action) => {
      state.isLoading = false;
      state.billing = action.payload;
      state.success = true;
    })
    .addCase('createBillingFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Billings
    .addCase('getAllBillingsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllBillingsSuccess', (state, action) => {
      state.isLoading = false;
      state.billings = action.payload;
    })
    .addCase('getAllBillingsFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Billing
    .addCase('editBillingRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editBillingSuccess', (state, action) => {
      state.isLoading = false;
      state.billing = action.payload;
      state.success = true;
      state.message = 'Billing updated successfully!';
    })
    .addCase('editBillingFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Billing
    .addCase('deleteBillingRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteBillingSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteBillingFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
