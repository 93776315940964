import axios from "axios";
import { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { server } from "../../../server/server";
import { Link } from "react-router-dom";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Clear the error while typing
  };

  const handleBlur = () => {
    const trimmedEmail = email.trim();
    if (!validateEmail(trimmedEmail)) {
      setError("Please enter a valid email address.");
    } else {
      setError(""); // Clear the error if the email is valid
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedEmail = email.trim();

    if (!validateEmail(trimmedEmail)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      setError(""); // Clear any previous error
      setSuccessMessage(""); // Clear any previous success message

      const response = await axios.post(`${server}/user/forgot-password`,
        { email: trimmedEmail });
      setSuccessMessage(response.data.message || "Password reset link sent successfully.");
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred. Please try again later."
      );
    }
  };

  return (
    <div className="vh-100 d-flex align-items-center justify-content-center">
      <div className="w-100 p-3" style={{ maxWidth: "400px" }}>
        <h3 className="text-center mb-4">Forgot Password</h3>
        <Form onSubmit={handleSubmit}>
          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
            // required
            />
          </Form.Group>
          <div className="justify-content-between d-flex">

            <Link to="/login" className="d-block text-center mt-3">
              <Button variant="link">Back to Login</Button>
            </Link>
            <Button variant="primary" type="submit" className="btn-sm" >
              Reset Password
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
