/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createInvoice, getAllInvoices } from '../../../redux/actions/invoiceAction.js';
import { getSingleSupplier } from '../../../redux/actions/supplierAction.js';
import { toUpperCase } from '../../utils/utils.js';

const CreateInvoiceModal = ({ show, onHide, invoiceType, clientId }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, invoices } = useSelector((state) => state.invoice);
  const { supplier } = useSelector((state) => state.supplier);

  useEffect(() => {
    if (invoiceType === 'purchase') {
      dispatch(getSingleSupplier(clientId));
    }
  }, [invoiceType, clientId, dispatch]);

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [clientName, setClientName] = useState('');
  const [totalAmount, setTotalAmount] = useState('');

  useEffect(() => {
    if (supplier) {
      setClientName(supplier.name);
    }
  }, [supplier]);

  // state error
  const [errorInvoiceNumber, setErrorInvoiceNumber] = useState('');

  // generate invoice number
  const generateInvoiceNumber = () => {
    if (!clientName) return; // Prevent errors if clientName is undefined

    const firstWord = clientName.split(' ')[0].toUpperCase(); // Extract the first word and convert to uppercase
    const invoiceNumber = `INV-${Math.floor(1000 + Math.random() * 9000)}`;
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');

    const newInvoiceNumber = `${firstWord}-${invoiceNumber}-${date}`;
    setInvoiceNumber(newInvoiceNumber);
  };


  // handle invoice number change
  const handleInvoiceNumberChange = (e) => {
    const newInvoiceNumber = toUpperCase(e.target.value).trim();
    setInvoiceNumber(newInvoiceNumber);

    const invoiceExists = invoices?.some(invoice => invoice.invoiceNumber === newInvoiceNumber);
    if (invoiceExists) {
      toast.error('Invoice number already exists!');
      setErrorInvoiceNumber('Invoice number already exists!');
    } else {
      setErrorInvoiceNumber('');
    }
  };

  // handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const invoiceData = {
      invoiceType,
      invoiceNumber,
      clientName,
      totalAmount,
      clientId,
    };

    // console.log(invoiceData);

    dispatch(createInvoice(invoiceData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Invoice created successfully!');
      dispatch(getAllInvoices());
      setInvoiceNumber('');
      setTotalAmount('');
      onHide();
    }
    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [error, success, dispatch, onHide]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} style={{ zIndex: 1050 }}>
      <Modal.Header closeButton>
        <Modal.Title>Create Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-2">
            <Form.Label>Invoice Type</Form.Label>
            {clientId && invoiceType === 'purchase' ? (
              <Form.Control
                type="text"
                value="Purchase"
                disabled
              />
            ) : (
              <Form.Control
                type="text"
                value="Sale"
                disabled
              />
            )}

          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Supplier Name</Form.Label>
            <Form.Control
              type="text"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              disabled
              placeholder="Enter client name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Invoice Number</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                value={invoiceNumber}
                onChange={handleInvoiceNumberChange}
                placeholder="Enter invoice number"
                required
              />
              <Button
                variant="primary"
                className="ms-2"
                onClick={generateInvoiceNumber}
              >
                Generate
              </Button>
            </div>
          </Form.Group>
          {errorInvoiceNumber && <p className="text-danger">{errorInvoiceNumber}</p>}
          <Form.Group className="mb-2">
            <Form.Label>Total Amount</Form.Label>
            <Form.Control
              type="number"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
              placeholder="Enter total amount"
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading} className='float-end my-2'>
            {isLoading ? 'Creating...' : 'Create Invoice'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateInvoiceModal;
