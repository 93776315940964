import axios from "axios";
import { server } from "../../server/server";

// create customer
export const createCustomer = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "customerCreateRequest",
    });

    // const config = { headers: { "Content-Type": "multipart/form-data" } }

    const { data } = await axios.post(`${server}/customer/create-customer`,
      newForm
    );
    dispatch({
      type: "customerCreateSuccess",
      payload: data.customer,
    });
  } catch (error) {
    dispatch({
      type: "customerCreateFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Customers
export const getAllCustomers = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllCustomersRequest" });

    const { data } = await axios.get(`${server}/customer/get-all-customers`, { withCredentials: true });

    dispatch({
      type: "getAllCustomersSuccess",
      payload: data.customers,
    });
  } catch (error) {
    dispatch({
      type: "getAllCustomersFailed",
      payload: error.response.data.message,
    });
  }
};


// Edit Customer
export const editCustomer = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editCustomerRequest" });

    const { data } = await axios.put(`${server}/customer/update-customer/${id}`, updatedData);

    dispatch({
      type: "editCustomerSuccess",
      payload: data.customer,
    });

    // Optionally, fetch all customers again to update the list
    dispatch(getAllCustomers());
  } catch (error) {
    dispatch({
      type: "editCustomerFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Customer
export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteCustomerRequest" });

    const { data } = await axios.delete(`${server}/customer/delete-customer/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteCustomerSuccess",
      payload: data.message,
    });

    // Optionally, fetch all customers again to update the list
    dispatch(getAllCustomers());
  } catch (error) {
    dispatch({
      type: "deleteCustomerFailed",
      payload: error.response.data.message,
    });
  }
};
