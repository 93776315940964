import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaEye, FaPlus, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteInventoryItem, getInventoryItemsByStore } from '../../../../redux/actions/storeInventoryAction.js';
import { formatTimeWithTime } from '../../../../utils/TimeFormats.jsx';
import ConfirmDeleteModal from '../../../components/confirm-delete/ConfirmDeleteModal.jsx';
import { handleStoreInventoryFilter } from '../../../components/utils/handleFilter.jsx';

import CreateStoreInventoryModal from './CreateStoreInventoryModal.jsx';
import EditInventoryModal from './EditInventoryModal.jsx';
// import AdjustStockModal from './AdjustStockModal.jsx';

const StoreInventoryPage = () => {
  const dispatch = useDispatch();
  const { inventoryItems, error, success } = useSelector((state) => state.storeInventory);
  const { user } = useSelector((state) => state.user);
  /**search states */
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  /** create modals */
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  /** edit modals */
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingInventoryItem, setEditingInventoryItem] = useState(null);

  /**delete modals */
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [inventoryItemIdToDelete, setInventoryItemIdToDelete] = useState(null);

  // load inventory items when the component mounts
  useEffect(() => {
    if (user) {
      dispatch(getInventoryItemsByStore());
    }
  }, [dispatch, user]);

  useEffect(() => {
    setRecords(inventoryItems);
  }, [inventoryItems]);

  /** search function */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleStoreInventoryFilter(inventoryItems, term);
    setRecords(filteredData);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setEditingInventoryItem(null);
  };

  /** delete functions */
  const handleDelete = (inventoryItemId) => {
    setInventoryItemIdToDelete(inventoryItemId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteInventoryItem(inventoryItemIdToDelete));
    toast.success("Inventory item deleted successfully!");
    setShowConfirmDeleteModal(false);
    setInventoryItemIdToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setInventoryItemIdToDelete(null);
  };

  /** table columns */
  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Name",
      // selector: (row) => `${row?.name} ${row.inventoryVariation}  ${row.stock?.product?.subcategory?.shortForm || ""}`,
      selector: (row) => `${row?.stock?.inventoryName} ${row.inventoryVariation} `,
      sortable: true,
    },
    {
      name: "Retail",
      selector: (row) => `Ksh.${row?.stock?.retailPrice}`,
      width: "150px",
    },
    {
      name: "Wholesale",
      selector: (row) => `Ksh.${row?.stock?.wholeSalePrice}`,
      width: "150px",
    },
    {
      name: "Opening",
      selector: (row) => row?.openingStock,
      width: "100px",
    },
    {
      name: "Current",
      selector: (row) => row?.currentStock,
      width: "100px",
    },
    {
      name: "Last Updated",
      selector: (row) => formatTimeWithTime(row.updatedAt),
    },
    // {
    //   name: "Created At",
    //   selector: (row) => formatTime(row.createdAt),
    // },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          {/* <span
            className="me-3 text-primary cursor-pointer"
            onClick={() => handleShow(row)}
            title="Edit"
          >
            Adjust Stock
          </span> */}
          <Link
            to={`/store-inventory/details/${row._id}`}
            className="me-3 text-primary cursor-pointer"
            title="View Details"
          >
            <FaEye size={20} />
          </Link>
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  return (
    <div className="container-fluid">
      <Row className="mt-2">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Store Inventory
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Store Inventory</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search inventory..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={6} md={4}>
          <Button
            className="float-end"
            variant="primary"
            onClick={() => setShowCreateModal(true)}
          >
            <FaPlus className='me-2' /> Add
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              pagination
              highlightOnHover
              pointerOnHover
              striped
              noHeader
              responsive
            />
          </div>

          {showCreateModal && (
            <CreateStoreInventoryModal
              show={showCreateModal}
              onHide={handleCreateModalClose}
            />
          )}
          {showEditModal && editingInventoryItem && (
            <EditInventoryModal
              show={showEditModal}
              onHide={handleEditModalClose}
              inventoryItem={editingInventoryItem}
            />
          )}

          <ConfirmDeleteModal
            show={showConfirmDeleteModal}
            onClose={handleCloseConfirmDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StoreInventoryPage;