import { useEffect, useState } from 'react';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { deleteVariant, getAllVariants } from '../../../redux/actions/variantAction.js';
import ConfirmDeleteModal from '../../components/confirm-delete/ConfirmDeleteModal.jsx';
import { handleVariantFilter } from '../../components/utils/handleFilter.jsx';

import CreateVariantModal from './CreateVariantModal.jsx';
import EditVariantModal from './EditVariantModal.jsx';
import AddVariantVariationModal from './variant-details/AddVariantVariation.jsx';

const VariantsPage = () => {
  const dispatch = useDispatch();
  const { variants, error, success } = useSelector((state) => state.variant);

  /**search states */
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  /** create modals */
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  /** edit modals */
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingVariant, setEditingVariant] = useState(null);

  /** view modals */
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewingVariant, setViewingVariant] = useState(null);

  /**delete modals */
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [variantIdToDelete, setVariantIdToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllVariants());
  }, [dispatch]);

  useEffect(() => {
    setRecords(variants);
  }, [variants]);

  /** search function */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleVariantFilter(variants, term);
    setRecords(filteredData);
  };

  /** handle view */
  const handleView = (variant) => {
    setViewingVariant(variant);
    setShowViewModal(true);
  }

  /** edit functions */
  const handleEdit = (variant) => {
    setEditingVariant(variant);
    setShowEditModal(true);
  };

  // handle edit modal close
  const handleEditModalClose = () => {
    setShowEditModal(false);
    setEditingVariant(null);
  };

  /** delete functions */
  const handleDelete = (variantId) => {
    setVariantIdToDelete(variantId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteVariant(variantIdToDelete));
    setShowConfirmDeleteModal(false);
    setVariantIdToDelete(null);
  };

  // handle confirm delete
  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setVariantIdToDelete(null);
  };

  /** table columns */
  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      width: "200px",
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      width: "200px",
    },
    {
      name: "Variations",
      selector: (row) => (
        <div className='d-flex'>
          {row?.variations?.length > 0 ? (
            <>
              {row.variations.slice(0, 5).map((variation, index) => (
                <Badge key={index} bg="info" className="me-1">
                  {variation}
                </Badge>
              ))}
              {row.variations.length > 5 && <Badge bg="dark">...</Badge>}
            </>
          ) : (
            <Badge bg="secondary">None</Badge>
          )}
          <Badge
            bg="primary"
            className="ms-2 cursor-pointer"
            onClick={() => handleView(row)}
            title='Add Variation'
            role="button"
          >
            <FaPlus />
          </Badge>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          <span
            className="me-3 text-primary cursor-pointer"
            onClick={() => handleEdit(row)}
            title="Edit"
          >
            <FaEdit size={20} />
          </span>
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
      allowoverflow: true,
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Product Variants
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Product Variants</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search variants..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={6} md={4}>
          <Button
            className="float-end"
            variant="primary"
            onClick={() => setShowCreateModal(true)}
          >
            <FaPlus className='me-2' /> Add
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              noDataComponent="No variants found"
              striped
              highlightOnHover
              responsive
            />
          </div>

          {showCreateModal && (
            <CreateVariantModal
              show={showCreateModal}
              onHide={handleCreateModalClose}
            />
          )}
          {showEditModal && editingVariant && (
            <EditVariantModal
              show={showEditModal}
              onHide={handleEditModalClose}
              variant={editingVariant}
            />
          )}
          {showViewModal && viewingVariant && (
            <AddVariantVariationModal
              show={showViewModal}
              onHide={() => setShowViewModal(false)}
              variant={viewingVariant}
            />
          )}
          {showConfirmDeleteModal && (
            <ConfirmDeleteModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onConfirm={handleConfirmDelete}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default VariantsPage;
