/* eslint-disable react/prop-types */
import { Col, Modal, Row } from "react-bootstrap"


const ViewPurchaseItemsModal = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Purchase Items</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Unit Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Item 1</td>
                                    <td>1</td>
                                    <td>100</td>
                                    <td>100</td>
                                </tr>
                                <tr>
                                    <td>Item 2</td>
                                    <td>2</td>
                                    <td>200</td>
                                    <td>400</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="primary">Understood</Button> */}
            </Modal.Footer>
        </Modal>
    )
}

export default ViewPurchaseItemsModal