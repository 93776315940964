/* eslint-disable react/prop-types */
import { Modal, Button, Table } from 'react-bootstrap';
import { FaPlay } from 'react-icons/fa';

const PendingOrdersModal = ({ show, handleClose, suspendedOrders, resumeOrder }) => {

  return (
    <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Suspended Orders</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {suspendedOrders.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Customer Name</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {suspendedOrders.map((order, index) => (
                <tr key={index}>
                  <td className="align-middle">{index + 1}</td>
                  <td className="align-middle">{order?.customer?.name}</td>
                  <td className="align-middle">Ksh. {order?.total}</td>
                  <td className="align-middle">
                    <Button
                      variant="primary"
                      className="btn-sm d-inline-flex align-items-center"
                      onClick={() => {
                        resumeOrder(order);
                        handleClose();
                      }}
                    >
                      <FaPlay className="me-1" /> Resume Order
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No suspended orders available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PendingOrdersModal;