import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { server } from "../../../server/server";

const UserProfile = () => {
  const { user } = useSelector((state) => state.user);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [branch, setBranch] = useState(null);
  const [store, setStore] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName || '');
      setLastName(user.lastName || '');
      setEmail(user.email || '');
      setPhoneNumber(user.phoneNumber || '');
      setBranch(user.branch || null);
      setStore(user.store || null);
      setRole(user.role || null);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      firstName,
      lastName,
    };

    try {
      const response = await axios.put(`${server}/user/update-user/${user._id}`, {
        data
      }, { withCredentials: true });
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }


  return (
    <div className="row justify-content-center">
      <div className="col-md-12 col-lg-8">
        <h1>User Profile</h1>
        <form onSubmit={handleSubmit} className="container mt-4">
          <div className="row mb-3">
            <label htmlFor="firstName" className="col-sm-2 col-form-label">
              <strong>First Name:</strong>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="lastName" className="col-sm-2 col-form-label">
              <strong>Last Name:</strong>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                id="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="email" className="col-sm-2 col-form-label">
              <strong>Email:</strong>
            </label>
            <div className="col-sm-10">
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="phoneNumber" className="col-sm-2 col-form-label">
              <strong>Phone No:</strong>
            </label>
            <div className="col-sm-10">
              <input
                type="tel"
                id="phoneNumber"
                className="form-control"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                disabled
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="branch" className="col-sm-2 col-form-label">
              <strong>Branch:</strong>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                id="branch"
                className="form-control"
                value={branch ? branch.name : ''}
                disabled
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="store" className="col-sm-2 col-form-label">
              <strong>Store:</strong>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                id="store"
                className="form-control"
                value={store ? store.name : ''}
                disabled
                readOnly
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="role" className="col-sm-2 col-form-label">
              <strong>Role:</strong>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                id="role"
                className="form-control"
                value={role ? role.name : ''}
                disabled
                readOnly
              />
            </div>
          </div>
          <div className="text-end">
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default UserProfile