import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  message: null,
  categories: [],
  category: null,
};


export const categoryReducer = createReducer(initialState, (builder) => {
  builder
    // Create Category
    .addCase('createCategoryRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createCategorySuccess', (state, action) => {
      state.isLoading = false;
      state.category = action.payload;
      state.success = true;
    })
    .addCase('createCategoryFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Categories
    .addCase('getAllCategoriesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllCategoriesSuccess', (state, action) => {
      state.isLoading = false;
      state.categories = action.payload;
    })
    .addCase('getAllCategoriesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Single Category
    .addCase('getCategoryRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getCategorySuccess', (state, action) => {
      state.isLoading = false;
      state.category = action.payload;
    })
    .addCase('getCategoryFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    // Edit Category
    .addCase('editCategoryRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editCategorySuccess', (state, action) => {
      state.isLoading = false;
      state.category = action.payload;
      state.success = true;
    })
    .addCase('editCategoryFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Category
    .addCase('deleteCategoryRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteCategorySuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteCategoryFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
