import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  purchase: null,
  purchases: [],
  success: false,
  error: null,
  message: null,
};

export const purchaseReducer = createReducer(initialState, (builder) => {
  builder
    // Create Purchase
    .addCase('createPurchaseRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createPurchaseSuccess', (state, action) => {
      state.isLoading = false;
      state.purchase = action.payload;
      state.success = true;
    })
    .addCase('createPurchaseFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Purchases
    .addCase('getAllPurchasesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllPurchasesSuccess', (state, action) => {
      state.isLoading = false;
      state.purchases = action.payload;
    })
    .addCase('getAllPurchasesFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Single Purchase
    .addCase('getSinglePurchaseRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getSinglePurchaseSuccess', (state, action) => {
      state.isLoading = false;
      state.purchase = action.payload;
    })
    .addCase('getSinglePurchaseFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Purchase
    .addCase('editPurchaseRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editPurchaseSuccess', (state, action) => {
      state.isLoading = false;
      state.purchase = action.payload;
      state.success = true;
      state.message = 'Purchase updated successfully!';
    })
    .addCase('editPurchaseFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Purchase
    .addCase('deletePurchaseRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deletePurchaseSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deletePurchaseFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
