import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updatePosSettings } from '../../../../redux/actions/settingsAction';
import { toast } from 'react-toastify';

const PosSettings = () => {
  const dispatch = useDispatch();
  const { posSettings, isLoading, success, error } = useSelector((state) => state.settings);

  const [receiptHeader, setReceiptHeader] = useState(posSettings?.receiptHeader || '');
  const [receiptFooter, setReceiptFooter] = useState(posSettings?.receiptFooter || '');
  const [taxRate, setTaxRate] = useState(posSettings?.taxRate || 0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedSettings = {
      receiptHeader,
      receiptFooter,
      taxRate,
    };
    dispatch(updatePosSettings(updatedSettings));
  };

  if (success) {
    toast.success('POS settings updated successfully!');
  }

  if (error) {
    toast.error(error);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Receipt Header</Form.Label>
        <Form.Control
          type="text"
          value={receiptHeader}
          onChange={(e) => setReceiptHeader(e.target.value)}
          placeholder="Enter receipt header"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Receipt Footer</Form.Label>
        <Form.Control
          type="text"
          value={receiptFooter}
          onChange={(e) => setReceiptFooter(e.target.value)}
          placeholder="Enter receipt footer"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Tax Rate (%)</Form.Label>
        <Form.Control
          type="number"
          value={taxRate}
          onChange={(e) => setTaxRate(e.target.value)}
          placeholder="Enter tax rate"
          required
        />
      </Form.Group>
      <Button className='float-end' variant="primary" type="submit" disabled={isLoading}>
        {isLoading ? 'Saving...' : 'Save Changes'}
      </Button>
    </Form>
  );
};

export default PosSettings;
