/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { editProductUnit } from '../../../redux/actions/productUnitAction.js';
import { capitalizeFirstLetter, toUpperCase } from '../../utils/utils.js';

const EditProductUnitModal = ({ show, onHide, productUnit }) => {
  const { success, error } = useSelector((state) => state.productUnit);

  const dispatch = useDispatch();

  const [unitName, setUnitName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [description, setDescription] = useState('');


  useEffect(() => {
    if (productUnit) {
      setUnitName(productUnit?.unitName || '');
      setAbbreviation(productUnit?.abbreviation || '');
      setDescription(productUnit?.description || 'Description');
    }
  }, [productUnit]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // productUnit data
    const productUnitData = {
      unitName,
      abbreviation,
      description,
    };

    dispatch(editProductUnit(productUnit?._id, productUnitData));
  };

  if (error) {
    toast.error(error);
    dispatch({ type: "clearErrors" });
  }
  if (success) {
    toast.success('Product unit updated successfully');
    dispatch({ type: "clearSuccess" });
    onHide();
  }

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Product Unit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Unit Name</Form.Label>
            <Form.Control
              type="text"
              value={unitName}
              onChange={(e) => setUnitName(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter unit name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Abbreviation</Form.Label>
            <Form.Control
              type="text"
              value={abbreviation}
              onChange={(e) => setAbbreviation(toUpperCase(e.target.value))}
              placeholder="Enter abbreviation"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="float-end mt-2">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductUnitModal;