/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateSecondaryVariants } from '../../../../redux/actions/stockAction';

const SecondaryVariantModal = ({ show, handleClose, secondaryVariant, inventoryId, secondaryVariations, setSecondaryVariations }) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector(state => state.stock);

  const [secondaryVariation, setSecondaryVariation] = useState('');

  const handleAddSecondaryVariation = () => {
    if (!secondaryVariation) {
      toast.error('Please select a variant');
      return;
    }
    if (secondaryVariation && !secondaryVariations.includes(secondaryVariation)) {
      setSecondaryVariations([...secondaryVariations, secondaryVariation]);
      setSecondaryVariation('');
      return true;
    }
    toast.error('Variant already added');
  };

  const handleRemoveSecondaryVariation = (variant) => {
    const newSecondaryVariations = secondaryVariations.filter((priceVariant) => priceVariant !== variant);
    setSecondaryVariations(newSecondaryVariations);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (secondaryVariations.length === 0) {
      toast.error(`Please add at least one ${secondaryVariant} variant`);
      return;
    }

    const inventoryData = {
      secondaryVariations
    };

    dispatch(updateSecondaryVariants(inventoryId, inventoryData));
  }

  useEffect(() => {
    if (success) {
      toast.success('Secondary Variants updated successfully');
      handleClose();
      dispatch({ type: "clearSuccess" });
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }
  }, [success, handleClose, error, dispatch]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{secondaryVariant} Variants</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-2">
                <Form.Label>{secondaryVariant} Type</Form.Label>
                <div className="d-flex">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={secondaryVariation}
                      onChange={(e) => setSecondaryVariation(capitalizeFirstLetter(e.target.value))}
                      placeholder="Enter variant type"
                    />
                    <Button
                      className="btn-warning"
                      onClick={handleAddSecondaryVariation}
                      type="button"
                    >
                      Add
                    </Button>
                  </InputGroup>
                </div>
              </Form.Group>
              <div className="mb-2">
                {secondaryVariations && secondaryVariations?.map((priceVariant, i) => (
                  <span key={i} className="badge bg-info d-inline-flex align-items-center me-2">
                    {priceVariant}
                    <AiOutlineClose
                      size={15}
                      className="ms-2 cursor-pointer"
                      onClick={() => handleRemoveSecondaryVariation(priceVariant)}
                    />
                  </span>
                ))}
              </div>
            </Col>
          </Row>
          <Button className='float-end mt-3 ' type='submit' variant="primary">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SecondaryVariantModal;