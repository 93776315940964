import axios from "axios";
import { server } from "../../server/server";

// Create Purchase
export const createPurchase = (newPurchase) => async (dispatch) => {
  try {
    dispatch({ type: "createPurchaseRequest" });

    const { data } = await axios.post(`${server}/purchase/create-purchase`, newPurchase);

    dispatch({
      type: "createPurchaseSuccess",
      payload: data.purchase,
    });
  } catch (error) {
    dispatch({
      type: "createPurchaseFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Purchases
export const getAllPurchases = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllPurchasesRequest" });

    const { data } = await axios.get(`${server}/purchase/get-all-purchases`);

    dispatch({
      type: "getAllPurchasesSuccess",
      payload: data.purchases,
    });
  } catch (error) {
    dispatch({
      type: "getAllPurchasesFailed",
      payload: error.response.data.message,
    });
  }
};

// Fetch Single Purchase
export const getSinglePurchase = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getSinglePurchaseRequest" });

    const { data } = await axios.get(`${server}/purchase/get-single-purchase/${id}`);

    dispatch({
      type: "getSinglePurchaseSuccess",
      payload: data.purchase,
    });
  } catch (error) {
    dispatch({
      type: "getSinglePurchaseFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Purchase
export const editPurchase = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editPurchaseRequest" });

    const { data } = await axios.put(`${server}/purchase/update-purchase/${id}`, updatedData);

    dispatch({
      type: "editPurchaseSuccess",
      payload: data.purchase,
    });

    // Optionally, fetch all purchases again to update the list
    dispatch(getAllPurchases());
  } catch (error) {
    dispatch({
      type: "editPurchaseFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Purchase
export const deletePurchase = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deletePurchaseRequest" });

    const { data } = await axios.delete(`${server}/purchase/delete-purchase/${id}`);

    dispatch({
      type: "deletePurchaseSuccess",
      payload: data.message,
    });

    // Optionally, fetch all purchases again to update the list
    dispatch(getAllPurchases());
  } catch (error) {
    dispatch({
      type: "deletePurchaseFailed",
      payload: error.response.data.message,
    });
  }
};
