import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  expense: null,
  expenses: [],
  success: false,
  error: null,
  message: null,
};

export const expenseReducer = createReducer(initialState, (builder) => {
  builder
    // Create Expense
    .addCase('createExpenseRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createExpenseSuccess', (state, action) => {
      state.isLoading = false;
      state.expense = action.payload;
      state.success = true;
    })
    .addCase('createExpenseFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Expenses
    .addCase('getAllExpensesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllExpensesSuccess', (state, action) => {
      state.isLoading = false;
      state.expenses = action.payload;
    })
    .addCase('getAllExpensesFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Expense
    .addCase('editExpenseRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editExpenseSuccess', (state, action) => {
      state.isLoading = false;
      state.expense = action.payload;
      state.success = true;
      state.message = 'Expense updated successfully!';
    })
    .addCase('editExpenseFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Expense
    .addCase('deleteExpenseRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteExpenseSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteExpenseFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
