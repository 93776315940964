import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { FaClock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addTocart, removeFromCart } from '../../../redux/actions/cartAction.js';
import "./Cart.css";

import CheckoutModal from '../checkout/CheckoutModal.jsx';
import CartSingle from './CartSingle.jsx';
import PendingOrdersModal from './PendingOrdersModal.jsx';

const Cart = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);

  // State to manage the visibility of the checkout modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /** checkout modals */
  // const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  // const handleCloseCheckoutModal = () => setShowCheckoutModal(true);

  // State to manage the visibility of the resume order modal
  const [showResumeModal, setShowPendingOrdersModal] = useState(false);
  const handleCloseResumeModal = () => setShowPendingOrdersModal(false);

  // Get suspended orders from localStorage, or initialize an empty array
  const suspendedOrders = JSON.parse(localStorage.getItem("suspendedOrders")) || [];

  // Load cart items from localStorage on component mount
  useEffect(() => {
    try {
      const savedCartItems = localStorage.getItem('cartItems');
      if (savedCartItems) {
        const cartItems = JSON.parse(savedCartItems);
        cartItems.forEach(item => dispatch(addTocart(item)));
      }
    } catch (error) {
      console.error('Error loading cart from localStorage', error);
      toast.error("Failed to load cart items.");
    }
  }, [dispatch]);

  // Persist cart changes to localStorage
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cart));
  }, [cart]);


  // Function to remove an item from the cart
  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  // Function to handle quantity changes
  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  // Function to update the price and/or quantity of a cart item
  const updateItem = (id, newQuantity, value, saleType, discountedPrice) => {
    // console.log("newQuantity", newQuantity)
    // console.log("value", value)
    // console.log("saleType", saleType)
    // console.log("discountedPrice", discountedPrice)
    // Find the item to update in the cart
    const updatedItem = cart?.find(item => item._id === id);
    if (updatedItem) {
      // Update the item with new price and quantity
      const updatedData = {
        ...updatedItem,
        sellingQuantity: newQuantity,
        qty: value,
        saleType,
        discountedPrice,
      };
      dispatch(addTocart(updatedData)); // Dispatch updated item
    }
  };

  // Calculate subtotal, total profit, tax, and total amount
  const subtotal = cart?.reduce((acc, item) => {

    // Determine the appropriate price based on sale type and discount availability
    const basePrice = item.saleType === "Retail" ? item.discountedPrice > 0 ? item.discountedPrice : item.stock?.retailPrice
      : item.discountedPrice > 0 ? item.discountedPrice : item.stock?.wholesalePrice;

    // Calculate the total quantity
    const totalQuantity = item.qty * item.sellingQuantity;

    // Accumulate the total price
    return acc + basePrice * totalQuantity;

  }, 0);

  // Calculate total profit
  const totalProfit = cart?.reduce((acc, item) => {

    // Determine the price based on saleType and discount availability
    const priceToUse = item.saleType === "Wholesale" ? item.discountedPrice > 0 ? item.discountedPrice : item.stock?.wholeSalePrice
      : item.discountedPrice > 0 ? item.discountedPrice : item.stock?.retailPrice;

    // Calculate the buying price
    const buyingPrice = item.stock?.buyingPrice * item.sellingQuantity;
    // Calculate profit per item
    const profitPerItem = priceToUse - buyingPrice;
    // Accumulate the total profit
    return acc + (profitPerItem * item.qty || 0);
  }, 0);

  // Calculate tax and total amount
  const tax = totalProfit * 0.16;
  const total = subtotal;

  // Function to handle order submission
  const handleSubmitOrder = () => {
    if (!selectedCustomer) {
      toast.error("Please select a customer before proceeding.");
      return;
    }

    // Prepare order data
    const orderData = {
      cart,
      total,
      customer: selectedCustomer,
      tax,
    };

    localStorage.setItem("latestOrder", JSON.stringify(orderData));
    handleShow();
  };


  // Function to handle pending orders
  const handlePendingOrders = () => {
    // Logic to show modal for resuming an order
    setShowPendingOrdersModal(true);
  };

  // Function to handle suspending an order
  const handleSuspend = () => {
    if (!selectedCustomer) {
      toast.error("Please select a customer before suspending the order.");
      return;
    }

    // Create a unique identifier (e.g., a timestamp) for each suspended order
    const orderData = {
      id: Date.now(), // Use timestamp as a unique identifier
      cart,
      total,
      customer: selectedCustomer,
      tax,
      suspendedAt: new Date().toISOString() // Store the date the order was suspended
    };

    // Get existing suspended orders from localStorage, or initialize an empty array
    const suspendedOrders = JSON.parse(localStorage.getItem("suspendedOrders")) || [];

    // Add the new suspended order to the array
    suspendedOrders.push(orderData);

    // Save the updated suspended orders array back to localStorage
    localStorage.setItem("suspendedOrders", JSON.stringify(suspendedOrders));

    // Clear the cart after suspending the order
    cart.forEach(item => dispatch(removeFromCart(item)));

    // Notify the user
    toast.info("Order has been suspended.");
  };

  // Function to resume a suspended order
  const resumeOrder = (order) => {

    // Initialize cart state from local storage
    const savedCartItems = localStorage.getItem('cartItems');

    // Check if there are items in the cart
    if (savedCartItems && JSON.parse(savedCartItems).length > 0) {
      toast.error("Please complete or hold the current transaction before proceeding.");
      return;
    }

    // Get all suspended orders from localStorage
    const suspendedOrders = JSON.parse(localStorage.getItem("suspendedOrders")) || [];

    // Add each item from the resumed order to the cart
    order.cart.forEach(item => {
      dispatch(addTocart(item));
    });
    handleCloseResumeModal();

    // Remove the resumed order from the suspended orders array
    const updatedSuspendedOrders = suspendedOrders.filter((suspendedOrder) => suspendedOrder.id !== order.id);

    // Save the updated suspended orders array back to localStorage
    localStorage.setItem("suspendedOrders", JSON.stringify(updatedSuspendedOrders));

    toast.success("Order has been resumed.");
  };

  return (
    <>
      <div className="container-fluid p-2">
        <div className="row">
          <div className="col-12" style={{ overflowX: "auto" }}>
            <Table striped bordered hover className='table-container table-small'>
              <thead>
                <tr>
                  <th className="text-center">Item Name</th>
                  <th className="text-center">Package</th>
                  <th className="text-center">Qty</th>
                  <th className="text-center">Sale Type</th>
                  <th className="text-center">Price</th>
                  <th className="text-center" style={{ width: '120px' }}>Disc Price.</th>
                  <th className="text-center">Total</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {cart && cart.length > 0 ? (
                  cart.map((item, index) => (
                    <CartSingle
                      key={index}
                      data={item}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                      updateItem={updateItem}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center align-middle text-danger" style={{ height: '30vh' }}>
                      No items in cart
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>

        <div className='container-fluid'>
          {cart.length > 0 && (
            <div className="row justify-content-end mt-4 border-top">
              <div className="col-md-6 col-lg-5">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between">
                    <span>SubTotal:</span>
                    <span>
                      Ksh.{subtotal.toFixed(2)}
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between" style={{ fontSize: "14px" }}>
                    <span className="fs-6">Tax (16%):</span>
                    <span>Ksh.{tax.toFixed(2)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between font-weight-bold">
                    <span>Total:</span>
                    <span>Ksh.{total.toFixed(2)}</span>
                  </li>
                </ul>
              </div>
            </div>
          )}

          <div className="row mt-3">
            <div className="col-6 d-flex justify-content-center">
              {
                suspendedOrders.length > 0 && (
                  <Button
                    variant="info"
                    onClick={handlePendingOrders}
                    aria-label="Resume an order"
                    className="d-inline-flex align-items-center"
                  >
                    <FaClock className="me-2" /> Pending Orders
                  </Button>
                )
              }
            </div>
            {cart.length > 0 && (
              <div className="col-6 d-flex justify-content-end">
                <Button variant="danger" onClick={handleSuspend} aria-label="Suspend the order">
                  Suspend
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmitOrder}
                  aria-label="Proceed to payment"
                  className="ms-2"
                >
                  Pay Now
                </Button>
              </div>

            )}
          </div>

          <CheckoutModal
            show={show}
            handleClose={handleClose}
            total={total}
          />


        </div>
      </div>
      {/* Use the new ResumeOrderModal component */}
      <PendingOrdersModal
        show={showResumeModal}
        handleClose={handleCloseResumeModal}
        suspendedOrders={suspendedOrders}
        resumeOrder={resumeOrder}
      />
    </>
  );
};

Cart.propTypes = {
  selectedCustomer: PropTypes.object,
};

export default memo(Cart);