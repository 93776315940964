import { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteProduct } from "../../../redux/actions/productAction.js";
import { getAllStocks } from "../../../redux/actions/stockAction.js";
import ConfirmDeleteModal from "../../components/confirm-delete/ConfirmDeleteModal.jsx";
import { handleProductFilter } from "../../components/utils/handleFilter.jsx";
import CreateProductModal from './CreateProductModal.jsx';
import EditProductModal from './EditProductModal.jsx';

const ProductsPage = () => {
  const { products, isLoading, error, success } = useSelector((state) => state.product);

  const dispatch = useDispatch();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);
  const [showModal, setShowModal] = useState(false);

  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [editingProduct, setEditingProduct] = useState(null);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAllStocks());
  }, [dispatch]);


  useEffect(() => {
    setRecords(products || []);
  }, [products]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleProductFilter(products, term);
    setRecords(filteredData);
  };

  const handleDelete = (productId) => {
    setProductToDelete(productId);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteProduct(productToDelete));
    setShowConfirmDeleteModal(false);
    setProductToDelete(null);

  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setProductToDelete(null);
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEditingProduct(null);
  };

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  const formatVariants = (variations) => {
    return variations?.length ? variations.join(", ") : "N/A";
  };

  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Product Name",
      selector: (row) => `${row?.name} ${row?.subcategory?.name || ""}`,
    },
    {
      name: "Available Variants",
      selector: (row) => formatVariants(row?.pricingVariations),
    },
    {
      name: "Category",
      selector: (row) => row?.category?.name || "N/A",
    },
    {
      name: "Sub Category",
      selector: (row) => row?.subcategory?.name || "N/A",
    },
    {
      name: "Price Attribute",
      selector: (row) => row?.pricingAttribute || "N/A",
    },
    {
      name: "created At",
      selector: (row) => new Date(row?.createdAt).toLocaleDateString(),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex">
          <span className="me-3 text-primary cursor-pointer" title="Edit" onClick={() => handleEdit(row)}>
            <FaEdit size={20} />
          </span>

          {/* <Link
            to={`/product/${row._id}`}
            title="View Details"
            className="text-success text-decoration-none"
          >
            <span className="me-3 cursor-pointer">
              <FaEye size={20} />
            </span>
          </Link> */}
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete">
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active> Products</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Products</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={6} md={4} className="d-flex justify-content-end gap-2">
          {/* <ExportProductsToPdf
            data={records}
            getProductQuantity={getProductQuantity}
            disabled={isLoading}
            filename="product_report.pdf"
          /> */}
          <Button
            size="md"
            variant="primary" onClick={() => setShowCreateModal(true)}
            disabled={isLoading}
          >

            <FaPlus className="me-1" title="Add New Product" /> Add
          </Button>
        </Col>
      </Row>

      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
      />

      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              paginationTotalRows={records.length}
              pagination
              highlightOnHover
              pointerOnHover
              striped
              noHeader
              responsive
            />
          </div>
        </Col>
      </Row>

      {showCreateModal && (
        <CreateProductModal
          show={showCreateModal}
          onHide={handleCreateModalClose}
        />
      )}
      {showModal && editingProduct && (
        <EditProductModal
          show={showModal}
          onHide={handleModalClose}
          product={editingProduct}
        />
      )}
    </div>
  );
};

export default ProductsPage;
