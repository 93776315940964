import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getSingleStock } from "../../../../redux/actions/stockAction.js";
import { getAllVariants } from "../../../../redux/actions/variantAction.js";

import SingleVariant from "./single-variant/SingleVariant.jsx";
import MultipleVariant from "./multiple-variant/MultipleVariant.jsx";

const InventoryVariantsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { stock, success, error } = useSelector(state => state.stock);

  const [inventoryName, setInventoryName] = useState('');
  const [variantType, setVariantType] = useState('');

  useEffect(() => {
    if (id) {
      dispatch(getSingleStock(id));
      dispatch(getAllVariants());
    }
  }, [dispatch, id]);
  // fetch 
  useEffect(() => {
    if (stock) {
      setInventoryName(stock?.inventoryName);
      setVariantType(stock?.variantType);
    }
  }, [stock]);

  useEffect(() => {
    if (success) {
      dispatch({ type: "clearSuccess" });
    }
    if (error) {
      dispatch({ type: "clearError" });
    }
  }, [success, error, dispatch]);

  return (
    <div className="inventory-variations">
      <div className="container-fluid">
        <Row className="mt-2">
          <Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inventory">Inventory</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {inventoryName}
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
      </div>
      <div className="container-fluid">
        <Row className="mt-2">
          <div className="d-flex">
            <h4 className="text-uppercase text-center">
              {variantType} Variant
            </h4>
          </div>
          <hr />
        </Row>
        <Row>
          {variantType === "Single" ? (
            <SingleVariant
              data={stock}
            />
          ) : (
            <MultipleVariant
              data={stock}
            />
          )}
        </Row>
      </div>
    </div>
  )
}
export default InventoryVariantsPage