import { Breadcrumb, Col, Nav, Row, Tab } from "react-bootstrap"
import BasicSettings from "./components/BasicSettings";
import PosSettings from "./components/PosSettings.jsx";
import EmailSettings from "./components/EmailSettings.jsx";

const SettingsPage = () => (
  <div className="container-fluid">
    <Row className="my-1">
      <Col>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active> Settings</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>

    <Row>
      <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
        <Nav variant="pills" className="nav-pills nav-fill mb-2">
          <Nav.Item>
            <Nav.Link eventKey="basic">Basic Settings</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="pos">Point of Sale Settings</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="email">Email Settings</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="basic">
            <BasicSettings />
          </Tab.Pane>
          <Tab.Pane eventKey="pos">
            <PosSettings />
          </Tab.Pane>
          <Tab.Pane eventKey="email">
            <EmailSettings />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Row>
  </div>
)

export default SettingsPage