import { useEffect, useState, useCallback } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFillBox2Fill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../../redux/actions/cartAction";
import { getInventoryItemsByStore } from "../../../redux/actions/storeInventoryAction";

const ProductSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { cart } = useSelector((state) => state.cart);
  const { inventoryItems } = useSelector((state) => state.storeInventory);

  const dispatch = useDispatch();

  // Get stock by store
  useEffect(() => {
    dispatch(getInventoryItemsByStore());
  }, [dispatch]);

  // Handle search input change
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setSelectedIndex(-1); // Reset selection when typing

    if (term.trim() === "") {
      setData([]);
    } else {
      const filteredData = inventoryItems?.filter(
        (i) =>
          (i?.stock?.inventoryName &&
            i?.stock?.inventoryName.toLowerCase().includes(term.toLowerCase())) ||
          (i?.inventoryVariation &&
            i?.inventoryVariation.toLowerCase().includes(term.toLowerCase())) ||
          (i?.inventoryName &&
            i?.inventoryName.toLowerCase().includes(term.toLowerCase()))
      );
      setData(filteredData);
    }
  };

  // Add to cart handler
  const addToCartHandler = useCallback((product) => {
    const isItemExists = cart?.find((i) => i._id === product._id);

    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (!product || product.currentStock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = {
          ...product,
          qty: 1,
          saleType: "Retail",
          discountedPrice: product?.stock?.retailPrice,
          sellingQuantity: product?.sellingUnits?.[0]?.quantity,
        };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }

    setSearchTerm("");
    setData([]);
    setSelectedIndex(-1);
  }, [cart, dispatch]);

  // Handle keydown events
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (data.length === 0) return;

      if (e.key === "ArrowDown") {
        setSelectedIndex((prev) => (prev < data.length - 1 ? prev + 1 : prev));
      } else if (e.key === "ArrowUp") {
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : 0));
      } else if (e.key === "Enter" && selectedIndex !== -1) {
        addToCartHandler(data[selectedIndex]);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [data, selectedIndex, addToCartHandler]);

  return (
    <div className="col-md-6" style={{ zIndex: 999 }}>
      <h5>Search Product</h5>
      <div className="position-relative">
        <input
          type="text"
          placeholder="Search Product..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control"
          style={{ paddingRight: "2.5rem" }}
        />
        <AiOutlineSearch
          size={25}
          className="position-absolute"
          style={{ right: "0.5rem", top: "0.35rem", cursor: "pointer" }}
        />
        {searchTerm && data?.length > 0 ? (
          <div
            className="position-absolute bg-light shadow-sm p-3 overflow-auto"
            style={{ maxHeight: "50vh", zIndex: 9, width: "100%" }}
          >
            {data.map((i, index) => (
              <div
                key={index}
                className={`d-flex align-items-center mb-2 ${selectedIndex === index ? "bg-primary text-white" : ""}`}
                style={{ cursor: "pointer", padding: "5px", borderRadius: "5px" }}
                onClick={() => addToCartHandler(i)}
              >
                {i?.image_Url?.[0]?.url ? (
                  <img
                    src={i?.image_Url[0].url}
                    alt={i?.name || "Default Image"}
                    className="me-2"
                    style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                  />
                ) : (
                  <BsFillBox2Fill
                    size={30}
                    color="#ccc"
                    className="me-2"
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "#f0f0f0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                )}
                <div>
                  <h1 className="h6 mb-0">
                    {i?.stock?.inventoryName} {i?.inventoryVariation}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        ) : searchTerm && data.length === 0 ? (
          <div
            className="position-absolute bg-light shadow-sm p-3"
            style={{ minHeight: "30vh", maxHeight: "50vh", zIndex: 9, width: "100%" }}
          >
            <p className="text-center text-danger mb-0 fs-3">Item not found</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductSearch;
