/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createCategory, getAllCategories } from '../../../redux/actions/categoryAction.js';
import { capitalizeFirstLetter, toUpperCase } from '../../utils/utils.js';

const CreateCategoryModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, categories } = useSelector((state) => state.category);

  const [name, setName] = useState('');
  const [shortForm, setShortForm] = useState('');
  const [description, setDescription] = useState('Description');

  // state error
  const [errorName, setErrorName] = useState('');

  const handleNameChange = (e) => {
    const newName = capitalizeFirstLetter(e.target.value);
    setName(newName);

    const categoryExists = categories?.some(category => category.name === newName);
    if (categoryExists) {
      toast.error('Category name already exists!');
      setErrorName('Category name already exists!');
    } else {
      setErrorName(''); // Clear error if name is unique
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const categoryData = {
      name,
      shortForm,
      description,
    };

    dispatch(createCategory(categoryData));
  };



  useEffect(() => {
    if (success) {
      toast.success('Category created successfully!');
      dispatch(getAllCategories()); // Fetch updated category list
      setName('');
      setDescription('');
      onHide(); // Close the modal
    }
    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [error, success, dispatch, onHide]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Create Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Enter category name"
              required
            />
          </Form.Group>
          {errorName && <p className="text-danger">{errorName}</p>}
          <Form.Group className="mb-3">
            <Form.Label>Short Form</Form.Label>
            <Form.Control
              type="text"
              value={shortForm}
              onChange={(e) => setShortForm(toUpperCase(e.target.value))}
              placeholder="Enter short form"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading} className='float-end'>
            {isLoading ? 'Creating...' : 'Create Category'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCategoryModal;