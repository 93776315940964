/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaClipboard } from 'react-icons/fa'; // Import the clipboard icon
import { toast } from 'react-toastify';

const PriceCalculatorModal = ({ show, onHide }) => {


  const [costPrice, setCostPrice] = useState('');
  const [quantity, setQuantity] = useState('');

  // Calculate cost price per unit without rounding
  const costPricePerUnit = parseFloat(costPrice) / parseFloat(quantity) || 0;

  // Function to copy cost price per unit to clipboard
  const copyToClipboard = () => {
    toast.success('Price copied to clipboard!');
    navigator.clipboard.writeText(costPricePerUnit.toFixed(2).toString());
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Price Calculator</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="costPrice" className="mb-2">
            <Form.Label>Cost Price</Form.Label>
            <Form.Control
              type="number"
              value={costPrice}
              onChange={(e) => setCostPrice(e.target.value)}
              placeholder="Enter cost price"
            />
          </Form.Group>
          <Form.Group controlId="quantity" className="mb-2">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              placeholder="Enter quantity"
            />
          </Form.Group>

          <Form.Group controlId="costPricePerUnit" className="mb-2">
            <Form.Label>Cost Price Per Unit</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                value={costPricePerUnit.toFixed(2)}
                readOnly
                placeholder="Calculated cost price per unit"
                disabled
              />
              <FaClipboard
                className="ms-2"
                size={20}
                style={{ cursor: 'pointer', color: '#007bff' }} // Make the icon greenish
                onClick={copyToClipboard}
                title="Copy Price"
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};

export default PriceCalculatorModal;