/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editRole, getAllRoles } from '../../../redux/actions/roleAction';
import { toast } from 'react-toastify';

const EditRoleModal = ({ show, onHide, role }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.role);

  const [roleData, setRoleData] = useState({
    name: '',
    description: '',
    permissions: [],
    level: 0,
    active: true,
    scope: '',
    restrictions: [],
  });

  useEffect(() => {
    if (role) {
      setRoleData({
        name: role.name,
        description: role.description,
        permissions: role.permissions,
        level: role.level,
        active: role.active,
        scope: role.scope,
        restrictions: role.restrictions,
      });
    }
  }, [role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editRole(role._id, roleData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Role updated successfully!');
      dispatch(getAllRoles()); // Fetch updated role list
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="roleName">
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={roleData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="roleDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={roleData.description}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="rolePermissions">
                <Form.Label>Permissions</Form.Label>
                <Form.Control
                  type="text"
                  name="permissions"
                  value={roleData.permissions}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="roleLevel">
                <Form.Label>Level</Form.Label>
                <Form.Control
                  type="number"
                  name="level"
                  value={roleData.level}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="roleScope">
                <Form.Label>Scope</Form.Label>
                <Form.Control
                  type="text"
                  name="scope"
                  value={roleData.scope}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="roleRestrictions">
                <Form.Label>Restrictions</Form.Label>
                <Form.Control
                  type="text"
                  name="restrictions"
                  value={roleData.restrictions}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="roleActive" className="mt-3">
            <Form.Check
              type="checkbox"
              name="active"
              label="Active"
              checked={roleData.active}
              onChange={(e) =>
                setRoleData({ ...roleData, active: e.target.checked })
              }
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3" disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditRoleModal;
