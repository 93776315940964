import axios from "axios";
import { server } from "../../server/server";

// Get Basic Settings
export const getBasicSettings = () => async (dispatch) => {
  try {
    dispatch({ type: "getBasicSettingsRequest" });

    const { data } = await axios.get(`${server}/settings/basic`, { withCredentials: true });

    dispatch({
      type: "getBasicSettingsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getBasicSettingsFail",
      payload: error.response.data.message,
    });
  }
};

// Update Basic Settings
export const updateBasicSettings = (updatedSettings) => async (dispatch) => {
  try {
    dispatch({ type: "updateBasicSettingsRequest" });

    const { data } = await axios.put(`${server}/settings/update-basic-settings`, updatedSettings, {
      withCredentials: true,
    });

    dispatch({
      type: "updateBasicSettingsSuccess",
      payload: data.settings,
    });
  } catch (error) {
    dispatch({
      type: "updateBasicSettingsFail",
      payload: error.response.data.message,
    });
  }
};

// Update POS Settings
export const updatePosSettings = (updatedSettings) => async (dispatch) => {
  try {
    dispatch({ type: "updatePosSettingsRequest" });

    const { data } = await axios.put(`${server}/settings/update-pos-settings`, updatedSettings, {
      withCredentials: true,
    });

    dispatch({
      type: "updatePosSettingsSuccess",
      payload: data.settings,
    });
  } catch (error) {
    dispatch({
      type: "updatePosSettingsFail",
      payload: error.response.data.message,
    });
  }
};

// Update Email Settings
export const updateEmailSettings = (updatedSettings) => async (dispatch) => {
  try {
    dispatch({ type: "updateEmailSettingsRequest" });

    const { data } = await axios.put(`${server}/settings/update-email-settings`, updatedSettings, {
      withCredentials: true,
    });

    dispatch({
      type: "updateEmailSettingsSuccess",
      payload: data.settings,
    });
  } catch (error) {
    dispatch({
      type: "updateEmailSettingsFail",
      payload: error.response.data.message,
    });
  }
};
