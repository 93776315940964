import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Import reducers
import { userReducer } from "./reducers/userReducer.js";
import { categoryReducer } from "./reducers/categoryReducer.js";
import { subcategoryReducer } from "./reducers/subcategoryReducer.js";
import { productUnitReducer } from "./reducers/productUnitReducer.js";
import { productReducer } from "./reducers/productReducer.js";
import { supplierReducer } from "./reducers/supplierReducer.js";
import { stockReducer } from "./reducers/stockReducer.js";
import { customerReducer } from "./reducers/customerReducer.js";
import { purchaseReducer } from "./reducers/purchaseReducer.js";
import { cartReducer } from "./reducers/cartReducer.js";
import { salesReducer } from "./reducers/saleReducer.js";
import { branchReducer } from "./reducers/branchReducer.js";
import { roleReducer } from "./reducers/roleReducer.js";
import { storeReducer } from "./reducers/storeReducer.js";
import { orderReducer } from "./reducers/orderReducer.js";
import { billingReducer } from "./reducers/billingReducer.js";
import { expenseReducer } from "./reducers/expenseReducer.js";
import { settingsReducer } from "./reducers/settingsReducer.js";
import { dashboardReducer } from "./reducers/dashboardReducer.js";
import { variantReducer } from "./reducers/variantReducer.js";
import { storeInventoryReducer } from "./reducers/storeInventoryReducer.js";
import { invoiceReducer } from "./reducers/invoiceReducer.js";

// Combine reducers
const rootReducer = combineReducers({
  user: userReducer,
  category: categoryReducer,
  subcategory: subcategoryReducer,
  productUnit: productUnitReducer,
  purchase: purchaseReducer,
  product: productReducer,
  supplier: supplierReducer,
  stock: stockReducer,
  customer: customerReducer,
  cart: cartReducer,
  sale: salesReducer,
  branch: branchReducer,
  role: roleReducer,
  store: storeReducer,
  order: orderReducer,
  billing: billingReducer,
  expense: expenseReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
  variant: variantReducer,
  storeInventory: storeInventoryReducer,
  invoice: invoiceReducer,
  // Add other reducers here if you have them
});

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user",], // Add other reducers if you want them persisted
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    immutableCheck: import.meta.env.MODE !== 'development',
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  });

// Create store
const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

// Create persistor
const persistor = persistStore(store);

export { persistor, store };
