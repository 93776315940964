import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { handleNumericInput } from '../../utils/utils.js';
import BankPayBillForm from './BankPayBillForm.jsx';
import MpesaPaymentForm from './MpesaPaymentForm.jsx';
import { createSale } from '../../../redux/actions/saleAction.js';

const CheckoutModal = ({ show, handleClose, total }) => {
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState(null);
  const { user } = useSelector((state) => state.user);

  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [cashReceived, setCashReceived] = useState('');
  const [mpesaDetails, setMpesaDetails] = useState('');
  const [paybillDetails, setPaybillDetails] = useState('')

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const totalAmount = orderData ? Math.round(orderData?.total) : Math.round(total);

  useEffect(() => {
    if (show) {
      const fetchedOrderData = JSON.parse(localStorage.getItem('latestOrder'));
      setOrderData(fetchedOrderData);
      setError("")
    }
  }, [show]);

  const handleCompleteTransaction = async (e) => {
    e.preventDefault();

    if (paymentMethod === 'cash') {
      if (!cashReceived) {
        setError("Please enter the amount of cash received.");
        return;
      }
      if (parseFloat(cashReceived) < totalAmount) {
        setError("The cash received is less than the total amount.");
        return;
      }
    }

    const saleItems = orderData ? orderData?.cart.map(item => ({
      inventory: item?._id,
      qty: item.qty * item?.sellingQuantity,
      sellingPrice: item.discountedPrice,
      saleType: item.saleType,
    })) : [];

    const sale = {
      items: saleItems,
      cashier: user,
      customer: orderData?.customer,
      totalAmount,
      cashReceived,
      paymentMethod: paymentMethod === 'cash' ? 'Cash' : paymentMethod === 'mpesa' ? 'MPesa' : 'Bank PayBill',
      // mpesaDetails: paymentMethod === 'mpesa' ? mpesaDetails : undefined,
      // paybillDetails: paymentMethod === 'paybill' ? paybillDetails : undefined,
    };

    console.log(sale)

    setLoading(true);

    try {

      // Dispatch the action to create the sale
      await dispatch(createSale(sale));
      toast.success("Order successful!");

      localStorage.setItem("cartItems", JSON.stringify([]));
      localStorage.setItem("latestOrder", JSON.stringify([]));

      setCashReceived('');
      setMpesaDetails('');
      setPaybillDetails('');
      setPaymentMethod('cash');
      setError('');

      // Close the modal after sale creation
      handleClose();
      window.location.reload(true);

    } catch (err) {
      console.log("Error creating sale:", err);
      toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleCashReceivedChange = (e) => {
    handleNumericInput(e, setCashReceived);
    const value = e.target.value;

    if (parseFloat(value) < totalAmount) {
      setError("The cash received is less than the total amount.");
    } else {
      setError("");
    }
  };

  const handleMpesaDetailsChange = (e) => {
    setMpesaDetails(e.target.value);
  };

  const handlePaybillDetailsChange = (e) => {
    setPaybillDetails(e.target.value);
  };

  const calculateChange = () => {
    const received = parseFloat(cashReceived) || 0;
    const change = received - totalAmount;
    return change > 0 ? change.toFixed(2) : '0.00';
  };



  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Complete Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col">
              <fieldset>
                <legend className='fw-bold'>Payment Method</legend>
                <div className="d-flex">
                  <div className="form-check me-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="paymentCash"
                      name="paymentMethod"
                      value="cash"
                      checked={paymentMethod === 'cash'}
                      onChange={handlePaymentMethodChange}
                    />
                    <label className="form-check-label" htmlFor="paymentCash">
                      Cash
                    </label>
                  </div>
                  <div className="form-check me-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="paymentMpesa"
                      name="paymentMethod"
                      value="mpesa"
                      checked={paymentMethod === 'mpesa'}
                      onChange={handlePaymentMethodChange}
                    />
                    <label className="form-check-label" htmlFor="paymentMpesa">
                      MPesa
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="paymentPaybill"
                      name="paymentMethod"
                      value="paybill"
                      checked={paymentMethod === 'paybill'}
                      onChange={handlePaymentMethodChange}
                    />
                    <label className="form-check-label" htmlFor="paymentPaybill">
                      Bank PayBill
                    </label>
                  </div>
                </div>
              </fieldset>

              {paymentMethod === 'cash' && (
                <Col>
                  <Form.Group className="mt-3" controlId="cashReceived">
                    <Form.Label>Cash Received</Form.Label>
                    <Form.Control
                      type="text"
                      value={cashReceived}
                      onChange={handleCashReceivedChange}
                      placeholder="Enter amount received"
                      pattern="[0-9]*"
                      required
                    />
                    {error && <div className="text-danger mt-1">{error}</div>}
                  </Form.Group>
                </Col>
              )}

              {paymentMethod === 'mpesa' && (
                <MpesaPaymentForm
                  mpesaDetails={mpesaDetails}
                  handleMpesaDetailsChange={handleMpesaDetailsChange}
                />
              )}

              {paymentMethod === 'paybill' && (
                <BankPayBillForm
                  paybillDetails={paybillDetails}
                  handlePaybillDetailsChange={handlePaybillDetailsChange}
                />
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="container">
          <ul className="list-group list-group-flush">
            <li className="list-group-item d-flex justify-content-between font-weight-bold">
              <span className='fw-bold'>Total Amount:</span>
              <span className='fw-bold'>Ksh. {totalAmount}</span>
            </li>
            {paymentMethod === 'cash' && cashReceived && (
              <li className="list-group-item d-flex justify-content-between">
                <span className='fw-bold'>Return Change:</span>
                <span className='fw-bold'>Ksh. {calculateChange()}</span>
              </li>
            )}
          </ul>
        </div>
        {/* <div style={{ display: 'none' }}>
          <Receipt
            ref={receiptRef}
            orderData={orderData}
            totalAmount={totalAmount}
            change={calculateChange()}
          />
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleCompleteTransaction}
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Complete Transaction'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CheckoutModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
};

export default CheckoutModal;