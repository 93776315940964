import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAllStocks } from '../../../../redux/actions/stockAction.js';
import { handleInventoryFilter } from '../../../components/utils/handleFilter.jsx';

const InventoryReportPage = () => {
  const dispatch = useDispatch();
  const { stocks, error, success } = useSelector((state) => state.stock);

  /**search states */
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(getAllStocks());
  }, [dispatch]);

  useEffect(() => {
    setRecords(stocks);
  }, [stocks]);

  /** search function */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleInventoryFilter(stocks, term);
    setRecords(filteredData);
  };

  /** table columns */
  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Product Name",
      selector: (row) => row?.product?.name,
    },
    {
      name: "Quantity",
      selector: (row) => row?.quantity,
    },
    {
      name: "Store",
      selector: (row) => row?.store?.name,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.date).toLocaleDateString(),
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Inventory Report
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Inventory Report</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search inventory..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              noDataComponent="No inventory found"
              striped
              highlightOnHover
              responsive
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InventoryReportPage;