/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createSupplier, getAllSuppliers } from '../../../redux/actions/supplierAction';
import { capitalizeFirstLetter, toUpperCase } from '../../utils/utils';

const CreateSupplierModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.supplier);

  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [kraPin, setKraPin] = useState('N/A');
  const [address, setAddress] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const supplierData = {
      name,
      email,
      phoneNumber,
      kraPin,
      address,
      company,
    };

    dispatch(createSupplier(supplierData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Supplier created successfully!');
      dispatch(getAllSuppliers());
      setName('');
      setEmail('');
      setPhoneNumber('');
      setKraPin('');
      setAddress('');
      setCompany('');
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  return (
    <Modal show={show} onHide={onHide} size='lg' scrollable backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Create Supplier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(capitalizeFirstLetter(e.target.value))}
                  placeholder="Enter company name"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
                  placeholder="Enter supplier name"
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value).toLowerCase()}
                  placeholder="Enter supplier email"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>KRA PIN</Form.Label>
                <Form.Control
                  type="text"
                  value={kraPin}
                  onChange={(e) => setKraPin(toUpperCase(e.target.value))}
                  placeholder="Enter KRA PIN"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(capitalizeFirstLetter(e.target.value))}
                  placeholder="Enter location"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Button className='float-end' variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Supplier'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateSupplierModal;
