import PropTypes from 'prop-types';
import { useState } from 'react';
import { HiOutlineMinus, HiPlus } from 'react-icons/hi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

const CartSingle = ({ data, updateItem, quantityChangeHandler, removeFromCartHandler }) => {
  // console.log(data)
  const [packaging, setPackaging] = useState(data?.sellingUnits?.[0]?._id || '');
  const [value, setValue] = useState(data?.qty);
  const [saleType, setSaleType] = useState(data.saleType);
  const [sellingPrice, setSellingPrice] = useState(data?.stock?.retailPrice * data.sellingUnits?.[0]?.quantity);
  const [discountedPrice, setDiscountedPrice] = useState(data?.stock?.retailPrice * data.sellingUnits?.[0]?.quantity);

  const sellingUnits = data?.sellingUnits?.length ?? 0;
  const selectedUnit = data?.sellingUnits?.find((unit) => unit._id === packaging);
  const packageQuantity = selectedUnit?.quantity || 1;
  const totalPrice = ((value ?? 0) * discountedPrice).toFixed(2);

  // handle packaging change
  const handlePackagingChange = (e) => {
    const selectedPackaging = e.target.value;
    const selectedUnit = data?.sellingUnits?.find((unit) => unit._id === selectedPackaging);
    const unitQuantity = selectedUnit?.quantity || 1;
    const newValue = 1;

    if (sellingUnits > 1 && unitQuantity > 1) {
      setPackaging(selectedPackaging);
      setValue(1);
      setSaleType("Wholesale");

      const newSellingPrice = Math.round(selectedUnit?.quantity * data?.stock?.wholeSalePrice);

      setSellingPrice(newSellingPrice);
      setDiscountedPrice(newSellingPrice);

      // Pass newSellingPrice directly instead of using stale discountedPrice
      updateItem(data._id, selectedUnit.quantity, newValue, "Wholesale", data?.stock?.wholeSalePrice);
    } else {
      setPackaging(selectedPackaging);
      setValue(1);
      setSaleType("Retail");

      const newSellingPrice = Math.round(selectedUnit?.quantity * data?.stock?.retailPrice);

      setSellingPrice(newSellingPrice);
      setDiscountedPrice(newSellingPrice);

      // Pass newSellingPrice directly instead of using stale discountedPrice
      updateItem(data._id, selectedUnit.quantity, newValue, "Retail", data?.stock?.retailPrice);
    }
  };

  // handle sale type change
  const handleSaleType = (e) => {
    const selectedType = e.target.value;
    setSaleType(selectedType);

    if (!selectedUnit) return; // Prevent errors if no unit is selected

    let newPrice;

    // console.log(sellingUnits)

    if (selectedType === "Wholesale") {
      if (sellingUnits > 1 && packageQuantity === 1) {
        newPrice = Math.round(selectedUnit.quantity * data?.stock?.retailPrice);
        updateItem(data._id, selectedUnit.quantity, value, selectedType, data?.stock?.retailPrice);
      } else if (sellingUnits === 1 && packageQuantity === 1) {
        newPrice = Math.round(selectedUnit.quantity * data?.stock?.wholeSalePrice);
        updateItem(data._id, selectedUnit.quantity, value, selectedType, data?.stock?.wholeSalePrice);
      } else {
        // Fallback if sellingUnits is 1 (should wholesale apply?)
        newPrice = Math.round(selectedUnit.quantity * data?.stock?.wholeSalePrice);
      }
    } else if (selectedType === "Retail") {
      if (sellingUnits > 1 && packageQuantity === 1) {
        newPrice = Math.round(selectedUnit.quantity * data?.stock?.retailPrice);
      } else {
        newPrice = Math.round(selectedUnit.quantity * data?.stock?.retailPrice);
      }
      updateItem(data._id, selectedUnit.quantity, value, selectedType, data?.stock?.retailPrice);
    }

    setSellingPrice(newPrice);
    setDiscountedPrice(newPrice);
  };


  // const handleSaleType = (e) => {
  //   const selectedType = e.target.value;
  //   setSaleType(selectedType);

  //   if (!selectedUnit) return; // Prevent errors if no unit is selected

  //   const { wholeSalePrice, retailPrice } = data?.stock || {};
  //   let pricePerUnit = selectedType === "Wholesale" ? wholeSalePrice : retailPrice;
  //   let newPrice = Math.round(selectedUnit.quantity * pricePerUnit);

  //   updateItem(data._id, selectedUnit.quantity, value, selectedType, pricePerUnit);

  //   setSellingPrice(newPrice);
  //   setDiscountedPrice(newPrice);
  // };

  // handle increment
  const increment = () => {
    const newValue = value + 1;
    const prospectiveQuantity = newValue * packageQuantity; // Calculate the prospective quantity

    if (data.currentStock < prospectiveQuantity) {
      toast.error(`Product stock limited! Available: ${data.currentStock}`);
      return; // Prevent increment if stock is insufficient
    }

    setValue(newValue);
    updateItem(data._id, selectedUnit.quantity, newValue, saleType, discountedPrice);
    quantityChangeHandler({ ...data, qty: newValue }); // Update cart via quantityChangeHandler
  };

  // handle decrement
  const decrement = () => {
    const newValue = value - 1;
    if (newValue < 1) { // Set the minimum value to 1
      toast.info("Quantity cannot be less than 1.");
      return;
    }
    setValue(newValue);
    updateItem(data._id, selectedUnit.quantity, newValue, saleType, discountedPrice);
  };

  const handlePriceChange = (e) => {
    const newPrice = e.target.value;

    if (newPrice === '' || parseFloat(newPrice) >= 0) {
      setDiscountedPrice(newPrice);

      const parsedPrice = newPrice === '' ? 0 : parseFloat(newPrice);

      updateItem(data._id, 1, value, saleType, parsedPrice);
    }
  };

  // handle price blur
  const handleBlur = () => {
    if (discountedPrice === '' || discountedPrice === '0') {
      toast.error(`Price has been reset to the initial ${saleType} price.`);
      if (saleType === "Retail") {
        setDiscountedPrice(data.stock.retailPrice);
      } else if (saleType === "Wholesale") {
        setDiscountedPrice(data.stock.wholeSalePrice);
      }
    } else if (parseFloat(discountedPrice) <= data.stock.buyingPrice) {
      toast.error(`New price cannot be lower than ${data.stock.buyingPrice}.`);
      if (saleType === "Retail") {
        setDiscountedPrice(data.stock.retailPrice);
        updateItem(data._id, selectedUnit.quantity, value, saleType, data.stock.retailPrice);
      } else if (saleType === "Wholesale") {
        setDiscountedPrice(data.stock.wholeSalePrice);
        updateItem(data._id, selectedUnit.quantity, value, saleType, data.stock.wholeSalePrice);

      }
    }
  };

  return (
    <tr>
      <td className="text-left align-middle">
        {data?.stock?.inventoryName?.slice(0, 18)} {" "}
        {data?.inventoryVariation?.slice(0, 10)} {" "}
      </td>
      <td className="text-center align-middle">
        <select
          value={packaging}
          onChange={handlePackagingChange}
          className="form-select mx-1"
        >
          {data?.sellingUnits?.map((unit, index) => (
            <option key={index} value={unit._id}>
              {unit.unitName}
            </option>
          ))}
        </select>
      </td>
      <td className="text-center align-middle">
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-sm btn-outline-secondary mx-1"
            onClick={decrement}
            // disabled={value <= unitQuantity}
            aria-label="Decrease quantity"
          >
            <HiOutlineMinus size={15} />
          </button>
          <span className="mx-2">{value}</span>
          <button className="btn btn-sm btn-outline-secondary mx-1" onClick={increment}>
            <HiPlus size={15} />
          </button>
        </div>
      </td>
      <td className="text-center align-middle">
        <select
          value={saleType}
          onChange={handleSaleType}
          className="form-select mx-1"
          aria-label="Select Sale Type"
        >
          <option value="" disabled>
            Select Sale Type
          </option>
          <option value="Retail">Retail</option>
          <option value="Wholesale">Wholesale</option>
        </select>
      </td>
      {/* <td className="text-center align-middle">
        {saleType === "retail" ? `Ksh.${data.retailPrice}` : `Ksh.${data.wholeSalePrice}`}
      </td> */}
      <td className="text-center align-middle">
        Ksh.{sellingPrice}
      </td>
      <td className="text-right align-middle">
        <input
          type="number"
          className="form-control me-2"
          value={discountedPrice}
          onChange={handlePriceChange}
          onBlur={handleBlur}
          min="0"
          style={{ width: '100px' }}
          aria-label="Price"
        />
      </td>
      {/* <td className="text-center align-middle">{unitQuantity}</td> */}
      <td className="text-center align-middle">
        Ksh.{totalPrice}
      </td>
      <td className="text-center align-middle">
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => removeFromCartHandler(data)}
          aria-label="Remove item from cart"
        >
          <RiDeleteBinLine size={20} />
        </button>
      </td>
    </tr>
  );
};

CartSingle.propTypes = {
  data: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
  quantityChangeHandler: PropTypes.func.isRequired,
  removeFromCartHandler: PropTypes.func.isRequired,
};

export default CartSingle;
