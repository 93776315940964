import { useState } from "react"
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"

const EditProductVariant = ({ show, onHide, variant }) => {

  const [primaryVariant, setPrimaryVariant] = useState('');
  const [secondaryVariant, setSecondaryVariant] = useState('');
  const [primaryVariants, setPrimaryVariants] = useState('');
  const [secondaryVariants, setSecondaryVariants] = useState('');

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
  }

  return (
    <Modal show={show} onHide={onHide} size='lg' backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Product Variants</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Primary Variant</Form.Label>
                <Form.Control
                  as="select"
                  value={primaryVariant}
                  onChange={(e) => setPrimaryVariant(e.target.value)}
                >
                  <option value="">Select Primary Variant</option>
                  <option value="Color">Color</option>
                  <option value="Size">Size</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Available Variants</Form.Label>
                <div className="d-flex">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={primaryVariants}
                      onChange={(e) => setPrimaryVariants(e.target.value)}
                      placeholder={`Enter ${primaryVariant} variant`}
                    />
                    <Button variant="primary">Add</Button>
                  </InputGroup>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className="float-end mt-1">
            <Button
              className="ms-3"
              type="submit"
              variant="primary"

            >
              Update
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default EditProductVariant