import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmailSettings } from '../../../../redux/actions/settingsAction';
import { toast } from 'react-toastify';

const EmailSettings = () => {
  const dispatch = useDispatch();
  const { emailSettings, isLoading, success, error } = useSelector((state) => state.settings);

  const [smtpMail, setSmtpMail] = useState(emailSettings?.smtpMail || '');
  const [smtpPassword, setSmtpPassword] = useState(emailSettings?.smtpPassword || '');

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedSettings = {
      smtpMail,
      smtpPassword,
    };
    dispatch(updateEmailSettings(updatedSettings));
  };

  if (success) {
    // toast.success('Email settings updated successfully!');
  }

  if (error) {
    toast.error(error);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>SMTP Email</Form.Label>
        <Form.Control
          type="email"
          value={smtpMail}
          onChange={(e) => setSmtpMail(e.target.value)}
          placeholder="Enter SMTP email"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>SMTP Password</Form.Label>
        <Form.Control
          type="password"
          value={smtpPassword}
          onChange={(e) => setSmtpPassword(e.target.value)}
          placeholder="Enter SMTP password"
          required
        />
      </Form.Group>

      <Button className='float-end' variant="primary" type="submit" disabled={isLoading}>
        {isLoading ? 'Saving...' : 'Save Changes'}
      </Button>
    </Form>
  );
};

export default EmailSettings;
