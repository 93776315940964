/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCategory } from "../../../redux/actions/categoryAction.js";
import { createSubcategory, getAllSubcategories } from "../../../redux/actions/subcategoryAction.js";
import { capitalizeFirstLetter } from "../../utils/utils.js";
import CreateCategoryModal from "../categories/CreateCategoryModal.jsx";

const CreateSubcategoryModal = ({ show, onHide, categoryId }) => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.category);
  const { isLoading, success, error } = useSelector((state) => state.subcategory);

  const [name, setName] = useState("");
  const [shortForm, setShortForm] = useState('');

  /** create modals */
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  useEffect(() => {
    if (categoryId) {
      dispatch(getCategory(categoryId));
    }

  }, [categoryId, dispatch])

  useEffect(() => {
    if (success) {
      toast.success("Subcategory created successfully!");
      dispatch(getAllSubcategories());

      const resetForm = () => {
        setName("");
      };

      resetForm();
      onHide();
    }
  }, [success, dispatch, onHide]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const subcategoryData = {
      name,
      shortForm,
      category: categoryId,
    };

    dispatch(createSubcategory(subcategoryData));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }

    if (success) {
      dispatch({ type: "clearSuccess" });
    }

  }, [dispatch, error, success]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} style={{ zIndex: 1050 }}>
      <Modal.Header closeButton>
        <Modal.Title>Create Sub Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-2">
            <Form.Label>Select Main Category</Form.Label>
            <Form.Control
              type="text"
              value={category?.name}
              placeholder="Enter main category"
              required
              readOnly
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>Sub Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter sub category name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Short Form</Form.Label>
            <Form.Control
              type="text"
              value={shortForm}
              onChange={(e) => setShortForm(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter sub category short form"
              required
            />
          </Form.Group>

          <Button className="float-end mt-3" variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Subcategory"}
          </Button>
        </Form>
        {showCreateModal && (
          <CreateCategoryModal
            show={showCreateModal}
            onHide={handleCreateModalClose}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CreateSubcategoryModal;