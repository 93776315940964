/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addVariation, getAllVariants, getVariant } from '../../../../redux/actions/variantAction.js';
import { capitalizeFirstLetter } from '../../../utils/utils.js';

const AddVariantVariationModal = ({ show, onHide, variant,variantId }) => {
  const { error, success } = useSelector((state) => state.variant);
  const dispatch = useDispatch();

  const [variation, setVariation] = useState('');
  const [variations, setVariations] = useState([]);

  useEffect(() => {
    if (variant) {
      setVariations(variant.variations);
    }
    if (variantId) {
      dispatch(getVariant(variantId));
    }
  }, [dispatch, variant, variantId]);

  useEffect(() => {
    if (variant && variant?.variations?.length > 0) {
      setVariations(variant?.variations);
    }
  }, [variant]);


  // handle add variation
  const handleAddVariation = () => {
    if (variation === '') {
      return toast.error('Please enter a variation');
    }

    if (variations.includes(capitalizeFirstLetter(variation))) {
      return toast.error(`Variation ${variation} already added`);
    }

    const trimmedVariation = variation.trim();

    setVariations((prev) => [...prev, trimmedVariation]);
    setVariation('');
  };

  // handle remove variation
  const handleRemoveVariation = (index) => {
    const newVariations = variations.filter((_, i) => i !== index);
    setVariations(newVariations);
  };


  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the variations are empty
    if (variations.length === 0) {
      return toast.error(`Please add variations to the ${variant} variant`);
    }

    // variant data
    const variantData = {
      name: variant.name,
      variations,
    };


    dispatch(addVariation(variant._id, variantData));
  };



  useEffect(() => {
    if (success) {
      toast.success('Variant created successfully!');
      dispatch(getAllVariants());
      dispatch({ type: "clearSuccess" });
      onHide();
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
  }, [error, success, dispatch, onHide]);


  return (
    <Modal show={show} onHide={onHide} size='md' scrollable backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{variant?.name} Variant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Col>
            <Form.Group controlId="variationName" className="mb-3">
              <Form.Label>Add {variant?.name} Name</Form.Label>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  placeholder="Enter variation name"
                  value={variation}
                  onChange={(e) => setVariation(capitalizeFirstLetter(e.target.value))}
                />
                <Button
                  variant="primary"
                  className="ms-2"
                  onClick={handleAddVariation}
                  disabled={!variation}
                >
                  Add
                </Button>
              </div>
            </Form.Group>
            {variations && variations.length > 0 && (
              <Col lg={12}>
                <Form.Group className="mb-0">
                  <Form.Label>{variant?.name} Variations :</Form.Label>
                </Form.Group>
                <div className="mb-3">
                  {variations?.map((variation, i) => (
                    <span key={i} className="badge bg-info d-inline-flex align-items-center me-2 badge-hover">
                      {variation}
                      <AiOutlineClose
                        className="icon ms-2 cursor-pointer "
                        size={15}
                        onClick={() => handleRemoveVariation(i)}
                      />
                    </span>
                  ))}
                </div>
              </Col>
            )}
          </Col>
          <Button variant="primary" type="submit" className="float-end mt-3">
            Update
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddVariantVariationModal;
