import axios from "axios";
import { server } from "../../server/server";

// Create Branch
export const createBranch = (newBranch) => async (dispatch) => {
  try {
    dispatch({ type: "createBranchRequest" });

    const { data } = await axios.post(`${server}/branch/create-branch`, newBranch, { withCredentials: true });

    dispatch({
      type: "createBranchSuccess",
      payload: data.branch,
    });
  } catch (error) {
    dispatch({
      type: "createBranchFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Branches
export const getAllBranches = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllBranchesRequest" });

    const { data } = await axios.get(`${server}/branch/get-all-branches`, { withCredentials: true });

    dispatch({
      type: "getAllBranchesSuccess",
      payload: data.branches,
    });
  } catch (error) {
    dispatch({
      type: "getAllBranchesFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Branch
export const editBranch = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editBranchRequest" });

    const { data } = await axios.put(`${server}/branch/update-branch/${id}`, updatedData, { withCredentials: true });

    dispatch({
      type: "editBranchSuccess",
      payload: data.branch,
    });

    // Optionally, fetch all branches again to update the list
    dispatch(getAllBranches());
  } catch (error) {
    dispatch({
      type: "editBranchFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Branch
export const deleteBranch = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteBranchRequest" });

    const { data } = await axios.delete(`${server}/branch/delete-branch/${id}`, { withCredentials: true });

    dispatch({
      type: "deleteBranchSuccess",
      payload: data.message,
    });

    // Optionally, fetch all branches again to update the list
    dispatch(getAllBranches());
  } catch (error) {
    dispatch({
      type: "deleteBranchFailed",
      payload: error.response.data.message,
    });
  }
};
