import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
  message: null,
};

export const stockReducer = createReducer(initialState, (builder) => {
  builder
    // Stock Creation
    .addCase('stockCreateRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('stockCreateSuccess', (state, action) => {
      state.isLoading = false;
      state.stock = action.payload;
      state.success = true;
    })
    .addCase('stockCreateFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Stocks
    .addCase('getAllStocksRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllStocksSuccess', (state, action) => {
      state.isLoading = false;
      state.stocks = action.payload;
    })
    .addCase('getAllStocksFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Single Stock
    .addCase('getSingleStockRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getSingleStockSuccess', (state, action) => {
      state.isLoading = false;
      state.stock = action.payload;
    })
    .addCase('getSingleStockFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Stock
    .addCase('editStockRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editStockSuccess', (state, action) => {
      state.isLoading = false;
      state.stock = action.payload;
      state.success = true;
    })
    .addCase('editStockFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // update inventory variants
    .addCase('updateInventoryVariantRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('updateInventoryVariantSuccess', (state, action) => {
      state.isLoading = false;
      state.stock = action.payload;
      state.success = true;
    })
    .addCase('updateInventoryVariantFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // update stock inventory variations
    .addCase('updateStockInventoryVariationsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('updateStockInventoryVariationsSuccess', (state, action) => {
      state.isLoading = false;
      state.stock = action.payload;
      state.success = true;
    })
    .addCase('updateStockInventoryVariationsFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // update primary variants
    .addCase('updatePrimaryVariantsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('updatePrimaryVariantsSuccess', (state, action) => {
      state.isLoading = false;
      state.stock = action.payload;
      state.success = true;
    })
    .addCase('updatePrimaryVariantsFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // update secondary variants
    .addCase('updateSecondaryVariantsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('updateSecondaryVariantsSuccess', (state, action) => {
      state.isLoading = false;
      state.stock = action.payload;
      state.success = true;
    })
    .addCase('updateSecondaryVariantsFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch Stock by Store
    .addCase('getStockByStoreRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getStockByStoreSuccess', (state, action) => {
      state.isLoading = false;
      state.stocks = action.payload;
    })
    .addCase('getStockByStoreFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Delete Stock
    .addCase('deleteStockRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteStockSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteStockFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Transfer Stock
    .addCase('transferStockRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('transferStockSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.success = true;
    })
    .addCase('transferStockFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Add Stock
    .addCase('addStockRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('addStockSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.success = true;
    })
    .addCase('addStockFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
