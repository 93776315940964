import { useState } from 'react';
import Cart from '../../components/cart-list/Cart.jsx';
import CustomerSearch from "../../components/customer-search/CustomerSearch.jsx";
import ProductSearch from "../../components/product-search/ProductSearch.jsx";

const PosDashboard = () => {
  // State to manage selected customer
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  return (
    <div className="container-fluid">
      <div className="fixed row mb-2 mt-2" style={{ zIndex: 1050 }}>
        {/* search product */}
        <ProductSearch />

        {/* search customer */}
        <CustomerSearch setSelectedCustomer={setSelectedCustomer} />
      </div>
      <div className="row">

        <div className="col-lg-12 order- order-lg-2">
          <h2 className="text-center fs-5">Order List</h2>
          <Cart selectedCustomer={selectedCustomer} />
        </div>
      </div>
    </div>
  );
};

export default PosDashboard;
