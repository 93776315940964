/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createRole, getAllRoles } from '../../../redux/actions/roleAction';
import { toast } from 'react-toastify';

const CreateRoleModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, roles } = useSelector((state) => state.role);

  const [roleData, setRoleData] = useState({
    name: 'role', // Default value
    description: 'Default description', // Default value
    permissions: ['read', 'write'], // Default value
    level: 1, // Default value
    active: true, // Default value
    scope: 'global', // Default value
    restrictions: ['none'], // Default value
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });

    if (name === 'name') {
      const roleExists = roles.some(role => role.name === value);
      if (roleExists) {
        toast.error('Role name already exists!');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createRole(roleData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Role created successfully!');
      dispatch(getAllRoles()); // Fetch updated role list
      setRoleData({
        name: 'role',
        description: 'Default description',
        permissions: ['read', 'write'],
        level: 1,
        active: true,
        scope: 'global',
        restrictions: ['none'],
      });
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Create Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="roleName">
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={roleData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="roleDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={roleData.description}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="rolePermissions">
                <Form.Label>Permissions</Form.Label>
                <Form.Control
                  type="text"
                  name="permissions"
                  value={roleData.permissions}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="roleLevel">
                <Form.Label>Level</Form.Label>
                <Form.Control
                  type="number"
                  name="level"
                  value={roleData.level}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="roleScope">
                <Form.Label>Scope</Form.Label>
                <Form.Control
                  type="text"
                  name="scope"
                  value={roleData.scope}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="roleRestrictions">
                <Form.Label>Restrictions</Form.Label>
                <Form.Control
                  type="text"
                  name="restrictions"
                  value={roleData.restrictions}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="roleActive" className="mt-3">
            <Form.Check
              type="checkbox"
              name="active"
              label="Active"
              checked={roleData.active}
              onChange={(e) =>
                setRoleData({ ...roleData, active: e.target.checked })
              }
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Role'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateRoleModal;
