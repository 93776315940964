import axios from "axios";
import { server } from "../../server/server";

// Create Sale
export const createSale = (newSale) => async (dispatch) => {
  try {
    dispatch({ type: "createSaleRequest" });

    const { data } = await axios.post(`${server}/sale/create-sale`, newSale, { withCredentials: true });

    dispatch({
      type: "createSaleSuccess",
      payload: data.sale,
    });
  } catch (error) {
    dispatch({
      type: "createSaleFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Sales
export const getAllSales = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllSalesRequest" });

    const { data } = await axios.get(`${server}/sale/get-all-sales`, { withCredentials: true });

    dispatch({
      type: "getAllSalesSuccess",
      payload: data.sales,
    });
  } catch (error) {
    const errorMessage = error.response && error.response.data.message
      ? error.response.data.message
      : error.message;

    dispatch({
      type: "getAllSalesFailed",
      payload: errorMessage,
    });
  }
};

// Fetch Sales for a Specific Store (Based on Store ID from req.user)
export const getSalesByStore = () => async (dispatch) => {
  try {
    dispatch({ type: "getSalesByStoreRequest" });

    const { data } = await axios.get(`${server}/sale/store`, {
      withCredentials: true,
    });

    dispatch({
      type: "getSalesByStoreSuccess",
      payload: data.sales,
    });
  } catch (error) {
    dispatch({
      type: "getSalesByStoreFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Fetch Single Sale by ID
export const getSaleById = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getSaleByIdRequest" });

    const { data } = await axios.get(`${server}/sale/sale/${id}`);

    dispatch({
      type: "getSaleByIdSuccess",
      payload: data.sale,
    });
  } catch (error) {
    dispatch({
      type: "getSaleByIdFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Sale
export const editSale = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editSaleRequest" });

    const { data } = await axios.put(`${server}/sale/sale/${id}`, updatedData);

    dispatch({
      type: "editSaleSuccess",
      payload: data.sale,
    });

    // Optionally, fetch all sales again to update the list
    dispatch(getAllSales());
  } catch (error) {
    dispatch({
      type: "editSaleFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Sale
export const deleteSale = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteSaleRequest" });

    const { data } = await axios.delete(`${server}/sale/delete/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteSaleSuccess",
      payload: data.message,
    });

    // Optionally, fetch all sales again to update the list
    dispatch(getAllSales());
  } catch (error) {
    dispatch({
      type: "deleteSaleFailed",
      payload: error.response.data.message,
    });
  }
};

// Fetch Daily Sales
export const getDailySales = () => async (dispatch) => {
  try {
    dispatch({ type: "getDailySalesRequest" });

    const { data } = await axios.get(`${server}/sale/daily`, { withCredentials: true });

    dispatch({
      type: "getDailySalesSuccess",
      payload: data.sales,
    });
  } catch (error) {
    dispatch({
      type: "getDailySalesFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Fetch Weekly Sales
export const getWeeklySales = () => async (dispatch) => {
  try {
    dispatch({ type: "getWeeklySalesRequest" });

    const { data } = await axios.get(`${server}/sale/weekly`, { withCredentials: true });

    dispatch({
      type: "getWeeklySalesSuccess",
      payload: data.sales,
    });
  } catch (error) {
    dispatch({
      type: "getWeeklySalesFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Fetch Monthly Sales
export const getMonthlySales = () => async (dispatch) => {
  try {
    dispatch({ type: "getMonthlySalesRequest" });

    const { data } = await axios.get(`${server}/sale/monthly`, { withCredentials: true });

    dispatch({
      type: "getMonthlySalesSuccess",
      payload: data.sales,
    });
  } catch (error) {
    dispatch({
      type: "getMonthlySalesFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};

// getStoreGrossProfit
export const getStoreGrossProfit = () => async (dispatch) => {
  try {
    dispatch({ type: "getStoreGrossProfitRequest" });

    const { data } = await axios.get(`${server}/sale/store-gross-profit`, { withCredentials: true });

    dispatch({
      type: "getStoreGrossProfitSuccess",
      payload: data.grossProfit,
    });
  } catch (error) {
    dispatch({
      type: "getStoreGrossProfitFailed",
      payload: error.response?.data?.message || error.message,
    });
  }
};