import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getAllProductUnits } from "../../../../redux/actions/productUnitAction";
import { editStock, getSingleStock } from "../../../../redux/actions/stockAction";
import { getAllSuppliers } from "../../../../redux/actions/supplierAction";
import { useInventoryProduct, usePrimaryInventory, usePrimarySellingUnit, useSupplierSelect } from "../../../utils/selectFunctions";
import CreateProductUnitModal from "../../product-unit/CreateProductUnitModal";

import { inventoryAttributes } from "../../../data/staticData";
import { capitalizeFirstLetter, handleNumericFloatInput, handleNumericInput } from "../../../utils/utils";
import CreateProductModal from "../../products/CreateProductModal";
import CreateSupplierModal from "../../suppliers/CreateSupplierModal";
import EditPricingVariantModal from "../components/EditPricingVariantModal";
import PriceCalculatorModal from "../components/PriceCalculatorModal";

const EditInventoryPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, success, error, stock } = useSelector((state) => state.stock);

  const { products } = useSelector((state) => state.product);
  const { productUnits } = useSelector((state) => state.productUnit);
  const { suppliers } = useSelector((state) => state.supplier);

  const { productId, setProductId, productOptions, handleProductChange } = useInventoryProduct(products);
  const { supplierId, setSupplierId, supplierOptions, handleSupplierChange } = useSupplierSelect(suppliers);
  const { primarySellingUnitId, setPrimarySellingUnitId, primarySellingUnitOptions } = usePrimarySellingUnit(productUnits);
  const { primaryInventoryId, setPrimaryInventoryId, primaryInventoryOptions, handlePrimaryInventoryChange } = usePrimaryInventory(inventoryAttributes);

  const [primarySellingUnitName, setPrimarySellingUnitName] = useState('');
  const [unitQuantity, setUnitQuantity] = useState("");
  const [inventoryName, setInventoryName] = useState("");
  const [pricingVariant, setPricingVariant] = useState('');
  const [buyingPrice, setBuyingPrice] = useState("");
  const [wholeSalePrice, setWholeSalePrice] = useState("");
  const [retailPrice, setRetailPrice] = useState("");
  const [variantType, setVariantType] = useState('Single');
  const [inventoryVariations, setInventoryVariations] = useState([]);
  const [isVerified, setIsVerified] = useState(false)

  // unit prices
  const [buyingUnitPrice, setBuyingUnitPrice] = useState(0);
  const [wholeSaleUnitPrice, setWholeSaleUnitPrice] = useState(0);
  const [retailUnitPrice, setRetailUnitPrice] = useState(0);

  // inventory variants
  const [primaryVariation, setPrimaryVariation] = useState('')
  const [primaryVariations, setPrimaryVariations] = useState([]);
  const [inventoryVariants, setInventoryVariants] = useState([]);

  /** create product modals */
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const handleCreateProductModalClose = () => setShowCreateProductModal(false);

  /* create price variation modals */
  const [showCreatePriceVariationModal, setShowCreatePriceVariationModal] = useState(false);
  const handleCreatePriceVariationModalClose = () => setShowCreatePriceVariationModal(false);

  /** create supplier modals */
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const handleCreateSupplierModalClose = () => setShowCreateSupplierModal(false);

  /** create product unit modals */
  const [showCreateProductUnitModal, setShowCreateProductUnitModal] = useState(false);
  const handleCreateProductUnitModalClose = () => setShowCreateProductUnitModal(false);

  /** price calculator modal */
  const [showPriceCalculatorModal, setShowPriceCalculatorModal] = useState(false);
  const handlePriceCalculatorModalClose = () => setShowPriceCalculatorModal(false);

  // Fetch all products, product units, and suppliers
  useEffect(() => {
    if (id) {
      dispatch(getAllProductUnits());
      dispatch(getAllSuppliers());
      dispatch(getSingleStock(id));
    }
  }, [dispatch, id]);


  // Set stock data to form fields
  useEffect(() => {
    if (stock) {
      setProductId(stock?.product?._id || '');
      setPricingVariant(stock?.pricingVariant || '');
      setPrimarySellingUnitId(stock?.primarySellingUnit?._id || '');
      setBuyingPrice(stock?.buyingPrice || '');
      setWholeSalePrice(stock?.wholeSalePrice || '');
      setRetailPrice(stock?.retailPrice || '');
      setVariantType(stock?.variantType || 'Single');
      setInventoryVariations(stock?.inventoryVariations || []);
      setSupplierId(stock?.supplier?._id || '');
      setPrimaryInventoryId(stock?.primaryVariant || '');
      setPrimaryVariations(stock?.primaryVariations || []);
      setInventoryName(stock?.inventoryName || '');
      setUnitQuantity(stock?.unitQuantity);
      setPrimarySellingUnitName(stock?.primarySellingUnit?.unitName || '');
      setIsVerified(stock?.isVerified || false);
    }

  }, [setPrimaryInventoryId, setPrimarySellingUnitId, setProductId, setSupplierId, stock]);

  // Get selected product
  const getSelectedProduct = products && products?.find((product) => product._id === productId);

  // Handle product unit add
  const handleAddProductUnit = () => {
    setShowCreateProductUnitModal(true);
  };

  // Handle product add
  const handleAddProduct = () => {
    setShowCreateProductModal(true);
  };

  // Handle product unit add
  const handleAddPricingVariant = () => {
    setShowCreatePriceVariationModal(true);
  };

  // Handle supplier add
  const handleAddSupplier = () => {
    setShowCreateSupplierModal(true);
  };

  // handle product name change
  const handleProductNameChange = (selectedOption) => {
    handleProductChange(selectedOption);
    const name = selectedOption?.label;
    handleInventoryName(name, '');
    setPricingVariant('');
    setInventoryVariants([]);
  };

  // Handle primary inventory change
  const handlePrimarySellingUnitChange = (selectedOption) => {
    setPrimarySellingUnitId(selectedOption?.value);
    setPrimarySellingUnitName(selectedOption?.label);
    setUnitQuantity('');
  }

  // handle primary selling unit change
  const handleUnitPriceChange = useCallback(() => {
    if (!unitQuantity || unitQuantity <= 0) {
      console.error("Invalid unit quantity:", unitQuantity);
      return;
    }

    setBuyingUnitPrice(parseFloat((buyingPrice / unitQuantity).toFixed(2)));
    setWholeSaleUnitPrice(parseFloat((wholeSalePrice / unitQuantity).toFixed(2)));
    setRetailUnitPrice(parseFloat((retailPrice / unitQuantity).toFixed(2)));
  }, [unitQuantity, buyingPrice, wholeSalePrice, retailPrice]);

  useEffect(() => {
    if (unitQuantity > 0 && buyingPrice > 0 && wholeSalePrice > 0 && retailPrice > 0) {
      handleUnitPriceChange();
    }
  }, [unitQuantity, buyingPrice, wholeSalePrice, retailPrice, handleUnitPriceChange]);

  // Handle input changes
  // const handlePrimaryUnitQuantity = (e) => {
  //   const value = parseInt(e.target.value, 10);
  //   setUnitQuantity(isNaN(value) ? 1 : value);
  // };

  // const handleBuyingPriceChange = (e) => {
  //   setBuyingPrice(parseFloat(e.target.value) || 0);
  // };

  // const handleWholeSalePriceChange = (e) => {
  //   setWholeSalePrice(parseFloat(e.target.value) || 0);
  // };

  // const handleRetailPriceChange = (e) => {
  //   setRetailPrice(parseFloat(e.target.value) || 0);
  // };



  // Handle pricing variant
  const handlePricingVariant = (e) => {
    setPricingVariant(e.target.value);
    const trimmedProduct = getSelectedProduct?.name;
    const trimmedVariant = e.target.value;
    handleInventoryName(trimmedProduct, trimmedVariant);
    setInventoryVariants([]);
    handleUnitPriceChange(wholeSalePrice, retailPrice);
  };


  // Handle inventory name
  const handleInventoryName = (productName, pricingVariant) => {
    const trimmedProductName = productName.trim();
    const trimmedVariantName = pricingVariant.trim();
    const name = `${trimmedProductName} ${trimmedVariantName}`;
    setInventoryName(name);
  };

  // handle add add primary variation
  const handleAddPrimaryVariation = (primaryVariation) => {
    const trimmedPrimaryVariant = primaryVariation.trim();
    if (trimmedPrimaryVariant && !primaryVariations.includes(trimmedPrimaryVariant)) {
      setPrimaryVariations((prev) => [...prev, trimmedPrimaryVariant]);
      setPrimaryVariation("");
    } else if (primaryVariations.includes(trimmedPrimaryVariant)) {
      toast.error(`${primaryVariation || ''} variant already exists!.`);
    }
  }

  // handle remove primary variation
  const handleRemovePrimaryVariation = (variation) => {
    setPrimaryVariations(primaryVariations.filter((v) => v !== variation));
  }

  // inventory variant change
  const handleInventoryVariantChange = useCallback((inventoryName) => {
    const name = inventoryName.trim();
    const upc = 0;

    const inventory = {
      name,
      upc
    };

    if (inventoryVariants.some((inventory) => inventory.name === name)) {
      return;
    }
    setInventoryVariants([...inventoryVariants, inventory]);
  }, [inventoryVariants]);

  useEffect(() => {
    if (inventoryVariants.length === 0 && inventoryName) {
      handleInventoryVariantChange(inventoryName);
    }
  });

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!getSelectedProduct) {
      toast.error('Please select a product.');
      return;
    }

    if (!supplierId) {
      toast.error('Please select a supplier.');
      return;
    }

    // wholeSalePrice should be greater than buyingPrice
    if (parseInt(wholeSalePrice) < parseInt(buyingPrice)) {
      toast.error('WholeSale price should be greater than buying price.');
      return;
    }

    // retailPrice should be greater than wholeSalePrice
    if (parseInt(retailPrice) < parseInt(wholeSalePrice)) {
      toast.error('Retail price should be greater than wholeSale price.');
      return;
    }

    const stockData = {
      product: getSelectedProduct._id,
      pricingVariant,
      inventoryName,
      primarySellingUnit: primarySellingUnitId,
      unitQuantity,
      buyingPrice,
      wholeSalePrice,
      retailPrice,
      supplier: supplierId,
      variantType,
      primaryVariant: primaryInventoryId || null,
      primaryVariations,
      inventoryVariations,
      inventoryVariants,
      buyingUnitPrice,
      retailUnitPrice,
      wholeSaleUnitPrice,
      isVerified
    };

    dispatch(editStock(id, stockData));
  }

  useEffect(() => {
    if (success) {
      toast.success("Inventory updated successfully");
      dispatch({ type: "clearSuccess" });
      navigate('/inventory');
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
  }, [dispatch, error, id, navigate, success]);

  return (
    <div className="container-fluid">
      <Row className="mt-2">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/inventory">Inventory</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Inventory
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <h2>Edit Inventory</h2>
        <Col md={12} lg={8}>
          <hr className="mt-0" />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-2">
                  <Form.Label>Product Name :</Form.Label>
                  <div className="d-flex">
                    <Select
                      value={productOptions.find(option => option.value === productId)}
                      onChange={handleProductNameChange}
                      options={productOptions}
                      placeholder="Select product"
                      isClearable
                      className="flex-grow-1"
                      required
                    />
                    <Button
                      className="ms-2"
                      onClick={handleAddProduct}
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </div>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-2">
                  <Form.Label>
                    {getSelectedProduct?.pricingAttribute?.name ?? 'Pricing'} Variation :
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      as="select"
                      value={pricingVariant}
                      onChange={handlePricingVariant}
                      required
                      placeholder={`Select ${getSelectedProduct?.pricingAttribute?.name ?? ''} Variant :`}
                      disabled={!getSelectedProduct}
                    >
                      <option value="">
                        Select {getSelectedProduct?.pricingAttribute?.name ?? ''} Variant
                      </option>
                      {getSelectedProduct?.pricingVariations?.map((variation, index) => (
                        <option key={index} value={variation}>
                          {variation}
                        </option>
                      ))}
                    </Form.Control>
                    <Button
                      className="ms-2"
                      onClick={handleAddPricingVariant}
                      type="button"
                      disabled={!getSelectedProduct}
                    >
                      <FaPlus />
                    </Button>
                  </div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-2">
                  <Form.Label>Inventory Name :</Form.Label>
                  <Form.Control
                    value={inventoryName}
                    onChange={handleInventoryName}
                    placeholder="Enter Inventory Name"
                    disabled
                  />
                </Form.Group>
              </Col>

              <Col sm={6} md={6} >
                <Form.Group className="mb-2">
                  <Form.Label>Pricing Unit :</Form.Label>
                  <div className="d-flex">
                    <Select
                      value={primarySellingUnitOptions.find(option => option.value === primarySellingUnitId)}
                      onChange={handlePrimarySellingUnitChange}
                      options={primarySellingUnitOptions}
                      placeholder="Select primary selling unit"
                      isClearable
                      className="flex-grow-1"
                      required
                    />

                    <Button
                      className="ms-2"
                      onClick={handleAddProductUnit}
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </div>
                </Form.Group>
              </Col>

              <Col sm={6} md={6}>
                <Form.Group className="mb-2">
                  <Form.Label>{`${primarySellingUnitName ?? ''} Quantity :`}</Form.Label>
                  <Form.Control
                    type="text"
                    value={unitQuantity}
                    onChange={(e) => handleNumericInput(e, setUnitQuantity)}
                    placeholder="Enter Unit Quantity"
                    required
                  />
                </Form.Group>
              </Col>


              <Col sm={4} md={6} lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>{`${primarySellingUnitName ?? ''} Cost Price :`}</Form.Label>
                  <Form.Control
                    type="text"
                    value={buyingPrice}
                    onChange={(e) => handleNumericFloatInput(e, setBuyingPrice)}
                    placeholder={`Enter ${pricingVariant ?? ''} ${primarySellingUnitName ?? ''} Buying Price`}
                    required
                  />
                </Form.Group>
              </Col>

              <Col sm={4} md={6}>
                <Form.Group className='mb-2'>
                  <Form.Label>{`${primarySellingUnitName ?? ''} WholeSale Price :`}</Form.Label>
                  <Form.Control
                    value={wholeSalePrice}
                    onChange={(e) => handleNumericFloatInput(e, setWholeSalePrice)}
                    placeholder={`Enter ${pricingVariant ?? ''} ${primarySellingUnitName ?? ''} Wholesale Price :`}
                    required
                  />
                </Form.Group>
              </Col>

              <Col sm={4} md={6}>
                <Form.Group className='mb-2'>
                  <Form.Label>{`${primarySellingUnitName ?? ''} Retail Price :`}</Form.Label>
                  <Form.Control
                    value={retailPrice}
                    onChange={(e) => handleNumericFloatInput(e, setRetailPrice)}
                    placeholder={`Enter ${pricingVariant ?? ''} ${primarySellingUnitName ?? ''} Retail Price`}
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className='mb-2'>
                  <Form.Label>Supplied By :</Form.Label>
                  <div className="d-flex">
                    <Select
                      value={supplierOptions.find(option => option.value === supplierId)}
                      onChange={handleSupplierChange}
                      options={supplierOptions}
                      placeholder="Select supplier"
                      isClearable
                      className="flex-grow-1"
                      required
                    />
                    <Button
                      className="ms-2"
                      onClick={handleAddSupplier}
                      type="button"
                    >
                      Add
                    </Button>
                  </div>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-2">
                  <Form.Label>Variant Type :</Form.Label>
                  <Form.Select
                    value={variantType}
                    onChange={(e) => setVariantType(e.target.value)}
                  >
                    <option value="Single">Single</option>
                    <option value="Multiple">Multiple</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              {variantType === 'Multiple' && (
                <>
                  <Col md={6}>
                    <Form.Group className="mb-2">
                      <Form.Label>Primary Variant :</Form.Label>
                      <div className="d-flex">
                        <InputGroup>
                          <Select
                            value={primaryInventoryOptions.find((v) => v.value === primaryInventoryId) || null}
                            options={primaryInventoryOptions}
                            onChange={handlePrimaryInventoryChange}
                            isClearable
                            placeholder="Select Primary Inventory Variant"
                            className="flex-grow-1"
                            required
                          />
                        </InputGroup>
                      </div>
                    </Form.Group>
                  </Col>
                  {primaryInventoryId && (
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Available Variation</Form.Label>
                        <div className="d-flex">
                          <Form.Control
                            value={primaryVariation}
                            onChange={(e) => setPrimaryVariation(capitalizeFirstLetter(e.target.value))}
                          />
                          <Button
                            className="ms-2 cursor-pointer"
                            onClick={() => handleAddPrimaryVariation(primaryVariation)}
                          >
                            Add
                          </Button>
                        </div>
                      </Form.Group>

                      <div className="d-flex flex-wrap mb-2">
                        {primaryVariations && primaryVariations?.map((variation, index) => (
                          <span key={index} className="badge bg-info d-inline-flex align-items-center me-2">
                            {variation}
                            <AiOutlineClose
                              size={15}
                              className="ms-2 cursor-pointer"
                              onClick={() => handleRemovePrimaryVariation(variation)}
                            />
                          </span>
                        ))}
                      </div>
                    </Col>
                  )}

                </>
              )}

              <Col md={12}>
                <Form.Label>Verify Price</Form.Label>
                <Form.Check
                  type="switch"
                  id="verify-price-switch"
                  label="Enable Verification"
                  checked={isVerified}
                  onChange={(e) => setIsVerified(e.target.checked)}
                  required
                />
              </Col>
            </Row>

            <div className="float-end justify-content-between mb-5">
              <Button
                type="submit"
                variant="success"
                disabled={isLoading}
              >
                Update
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      {showCreateProductModal && (
        <CreateProductModal
          show={showCreateProductModal}
          onHide={handleCreateProductModalClose}
        />
      )}

      {showCreateSupplierModal && (
        <CreateSupplierModal
          show={showCreateSupplierModal}
          onHide={handleCreateSupplierModalClose}
        />
      )}

      {showCreateProductUnitModal && (
        <CreateProductUnitModal
          show={showCreateProductUnitModal}
          onHide={handleCreateProductUnitModalClose}
        />
      )}

      {showCreatePriceVariationModal && (
        <EditPricingVariantModal
          show={showCreatePriceVariationModal}
          onHide={handleCreatePriceVariationModalClose}
          product={getSelectedProduct}
        />
      )}

      {showPriceCalculatorModal && (
        <PriceCalculatorModal
          show={showPriceCalculatorModal}
          onHide={handlePriceCalculatorModalClose}
        />
      )}

    </div>
  )
}
export default EditInventoryPage;
