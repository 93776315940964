import { useState } from 'react';

// Hook for selecting a category
export const useCategorySelect = (categories) => {
  const [categoryId, setCategoryId] = useState('');

  const categoryOptions = categories?.map((category) => ({
    value: category?._id,
    label: category?.name,
  })) ?? [];

  const handleCategoryChange = (selectedOption) => {
    setCategoryId(selectedOption?.value ?? '');
  };

  return {
    categoryId,
    setCategoryId,
    categoryOptions,
    handleCategoryChange,
  };
};

// Hook for selecting a product category
export const useProductCategorySelect = (categories) => {
  const categoryOptions = categories?.map((category) => ({
    value: category?._id,
    label: category?.name,
  })) ?? [];

  return {
    categoryOptions,
  };
};

// Hook for selecting a product
export const useProductSelect = (products) => {
  const [productId, setProductId] = useState('');

  const productOptions = products?.map((product) => ({
    value: product?._id,
    label: product?.name,
  })) ?? [];

  const handleProductChange = (selectedOption) => {
    setProductId(selectedOption?.value ?? '');
  };

  return {
    productId,
    setProductId,
    productOptions,
    handleProductChange,
  };
};

// Hook for selecting an inventory product
export const useInventoryProduct = (products) => {
  const [productId, setProductId] = useState('');

  const productOptions = products?.map((product) => ({
    value: product?._id,
    label: product?.name,
  })) ?? [];

  const handleProductChange = (selectedOption) => {
    setProductId(selectedOption?.value ?? '');
  };

  return {
    productId,
    setProductId,
    productOptions,
    handleProductChange,
  };
};

// Hook for selecting a supplier
export const useSupplierSelect = (suppliers) => {
  const [supplierId, setSupplierId] = useState('');

  const supplierOptions = suppliers?.map((supplier) => ({
    value: supplier?._id,
    label: supplier?.name,
  })) ?? [];

  const handleSupplierChange = (selectedOption) => {
    setSupplierId(selectedOption?.value ?? '');
  };

  return {
    supplierId,
    setSupplierId,
    supplierOptions,
    handleSupplierChange,
  };
};

// Hook for selecting a selling unit
export const useSellingUnitSelect = (productUnits) => {
  const [sellingUnitId, setSellingUnitId] = useState('');

  const sellingUnitOptions = productUnits?.map((productUnit) => ({
    value: productUnit?._id,
    label: productUnit?.unitName,
  })) ?? [];

  const handleSellingUnitChange = (selectedOption) => {
    setSellingUnitId(selectedOption?.value ?? '');
  };

  return {
    sellingUnitId,
    setSellingUnitId,
    sellingUnitOptions,
    handleSellingUnitChange,
  };
};

// Hook for selecting a product unit
export const useProductUnitSelect = (productUnits) => {
  const [productUnitId, setProductUnitId] = useState('');
  const [unitQuantity, setUnitQuantity] = useState(1);

  const productUnitOptions = productUnits?.reduce((acc, productUnit) => {
    acc.push({
      value: productUnit?._id,
      label: productUnit?.unitName,
      quantity: productUnit?.quantity,
    });
    return acc;
  }, []) ?? [];

  const handleProductUnitChange = (selectedOption) => {
    setProductUnitId(selectedOption?.value ?? '');
    setUnitQuantity(selectedOption?.quantity ?? 1);
  };

  return {
    unitQuantity,
    setUnitQuantity,
    productUnitId,
    setProductUnitId,
    productUnitOptions,
    handleProductUnitChange,
  };
};

// Hook for selecting a primary selling unit
export const usePrimarySellingUnit = (productUnits) => {
  const [primarySellingUnitId, setPrimarySellingUnitId] = useState('');
  const [primarySellingUnitName, setPrimarySellingUnitName] = useState('');

  const primarySellingUnitOptions = productUnits?.map((productUnit) => ({
    value: productUnit?._id,
    label: productUnit?.unitName,
  })) ?? [];

  const handlePrimarySellingUnitChange = (selectedOption) => {
    setPrimarySellingUnitId(selectedOption?.value ?? '');
    setPrimarySellingUnitName(selectedOption?.label ?? '');
  };

  return {
    primarySellingUnitName,
    primarySellingUnitId,
    setPrimarySellingUnitId,
    primarySellingUnitOptions,
    handlePrimarySellingUnitChange,
  };
};

// Hook for selecting a pricing variant
export const usePricingVariantSelect = (pricingVariants) => {
  const [pricingVariantId, setPricingVariantId] = useState('');

  const pricingVariantOptions = pricingVariants?.map((pricingVariant) => ({
    value: pricingVariant?._id,
    label: pricingVariant?.variantName,
  })) ?? [];

  const handlePricingVariantChange = (selectedOption) => {
    setPricingVariantId(selectedOption?.value ?? '');
  };

  return {
    pricingVariantId,
    setPricingVariantId,
    pricingVariantOptions,
    handlePricingVariantChange,
  };
};

// Hook for selecting an inventory variant
export const useInventoryVariantSelect = (inventoryVariants) => {
  const [inventoryVariantId, setInventoryVariantId] = useState('');

  const inventoryVariantOptions = inventoryVariants?.map((inventoryVariant) => ({
    value: inventoryVariant?._id,
    label: inventoryVariant?.name,
  })) ?? [];

  const handleInventoryVariantChange = (selectedOption) => {
    setInventoryVariantId(selectedOption?.value ?? '');
  };

  return {
    inventoryVariantId,
    setInventoryVariantId,
    inventoryVariantOptions,
    handleInventoryVariantChange,
  };
};

// Hook for selecting a primary inventory variant
export const usePrimaryInventory = (inventoryVariants) => {
  const [primaryInventoryId, setPrimaryInventoryId] = useState('');

  const primaryInventoryOptions = inventoryVariants?.map((inventoryVariant) => ({
    value: inventoryVariant,
    label: inventoryVariant,
  })) ?? [];

  const handlePrimaryInventoryChange = (selectedOption) => {
    setPrimaryInventoryId(selectedOption?.value ?? '');
  };

  return {
    primaryInventoryId,
    setPrimaryInventoryId,
    primaryInventoryOptions,
    handlePrimaryInventoryChange,
  };
};

// Hook for selecting a secondary inventory variant
export const useSecInventory = (inventoryVariants) => {
  const [secInventoryId, setSecInventoryId] = useState('');

  const filterInventoryVariants = inventoryVariants?.filter((variant) => variant?.type === 'Inventory');

  const secInventoryOptions = filterInventoryVariants?.map((inventoryVariant) => ({
    value: inventoryVariant?._id,
    label: inventoryVariant?.name,
  })) ?? [];

  const handleSecInventoryChange = (selectedOption) => {
    setSecInventoryId(selectedOption?.value ?? '');
  };

  return {
    secInventoryId,
    setSecInventoryId,
    secInventoryOptions,
    handleSecInventoryChange,
  };
};

// Hook for selecting an inventory variation
export const useInventoryVariationSelect = (inventoryVariants, primaryInventoryId) => {
  const [inventoryVariationId, setInventoryVariationId] = useState('');

  // Find the inventory variant that matches the primaryInventoryId
  const filterInventoryVariation = inventoryVariants?.find((variant) => variant?._id === primaryInventoryId);

  // Extract variations and map them into options
  const inventoryVariationOptions = filterInventoryVariation?.variations?.map((variation) => ({
    value: variation,
    label: variation,
  })) ?? [];

  const handleInventoryVariationChange = (selectedOption) => {
    setInventoryVariationId(selectedOption?.value ?? '');
  };

  return {
    inventoryVariationId,
    setInventoryVariationId,
    inventoryVariationOptions,
    handleInventoryVariationChange,
  };
};


// Hook for selecting an inventory
export const useInventorySelect = (inventories) => {
  const [inventoryId, setInventoryId] = useState('');

  const inventoryOptions = inventories?.map((inventory) => ({
    value: inventory?._id,
    label: inventory?.name,
  })) ?? [];

  const handleInventoryChange = (selectedOption) => {
    setInventoryId(selectedOption?.value ?? '');
  };

  return {
    inventoryId,
    setInventoryId,
    inventoryOptions,
    handleInventoryChange,
  };
};

// Hook for selecting a purchase invoice
export const usePurchaseInvoiceSelect = (invoices) => {
  const [invoiceId, setInvoiceId] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('')

  const invoiceOptions = invoices?.map((invoice) => ({
    value: invoice?._id,
    label: invoice?.invoiceNumber,
  })) ?? [];

  const handleInvoiceChange = (selectedOption) => {
    setInvoiceId(selectedOption?.value ?? '');
    setInvoiceNo(selectedOption?.label ?? '');
  };

  return {
    invoiceId,
    invoiceNo,
    setInvoiceNo,
    setInvoiceId,
    invoiceOptions,
    handleInvoiceChange,
  };
};

// Hook for selecting a customer
export const useCustomerSelect = (customers) => {
  const [customerId, setCustomerId] = useState('');

  const customerOptions = customers?.map((customer) => ({
    value: customer?._id,
    label: customer?.name,
  })) ?? [];

  const handleCustomerChange = (selectedOption) => {
    setCustomerId(selectedOption?.value ?? '');
  };

  return {
    customerId,
    setCustomerId,
    customerOptions,
    handleCustomerChange,
  };
};

// Hook for selecting a subcategory based on a category
export const useSubcategorySelect = (subcategories, categoryId) => {
  const filteredSubcategories = subcategories?.filter(
    (subcategory) => subcategory?.category?._id === categoryId
  ) ?? [];

  const subcategoryOptions = filteredSubcategories?.map((subcategory) => ({
    value: subcategory?._id,
    label: subcategory?.name,
  })) ?? [];

  return {
    subcategoryOptions,
  };
};

// Hook for selecting pricing attribute
export const usePricingAttribute = (pricingAttributes) => {
  const [pricingAttribute, setPricingAttribute] = useState('');

  const pricingAttributeOptions = pricingAttributes?.map((pricingAttribute) => ({
    value: pricingAttribute,
    label: pricingAttribute,
  })) ?? [];

  const handlePricingAttributeChange = (selectedOption) => {
    setPricingAttribute(selectedOption?.value ?? '');
  };

  return {
    pricingAttribute,
    setPricingAttribute,
    pricingAttributeOptions,
    handlePricingAttributeChange,
  };
};

// Hook for selecting a pricing attribute
export const usePricingAttributeSelect = (variants) => {

  const [pricingAttributeId, setPricingAttributeId] = useState('');
  const [pricingVariant, setPricingVariant] = useState('');

  const pricingAttributeOptions = variants?.reduce((acc, variant) => {
    if (variant?.type === 'Pricing') {
      acc.push({
        variation: variant,
        value: variant?._id,
        label: variant?.name,
      });
    }
    return acc;
  }, []) ?? [];

  const handlePricingAttributeChange = (selectedOption) => {
    setPricingAttributeId(selectedOption?.value ?? '');
    setPricingVariant(selectedOption?.variation ?? '');
  };

  return {
    pricingAttributeId,
    setPricingAttributeId,
    pricingVariant,
    pricingAttributeOptions,
    handlePricingAttributeChange,
  };
};

// Hook for selecting variations
export const useVariationSelect = (variants, pricingAttributeId) => {
  const filteredVariants = variants?.find((variant) => variant?._id === pricingAttributeId);

  const variationOptions = filteredVariants?.variations?.map((variation) => ({
    value: variation,
    label: variation,
  })) ?? [];

  return {
    variationOptions,
  };
};

// Hook for selecting store inventory product
export const useStoreInventoryProduct = (stocks) => {
  const [stockId, setStockId] = useState('');
  const [stockName, setStockName] = useState('');

  const stockOptions = stocks?.map((stock) => ({
    value: stock?._id,
    label: stock?.inventoryName || stock?.product?.name,
  })) ?? [];

  const handleStockChange = (selectedOption) => {
    setStockId(selectedOption?.value ?? '');
    setStockName(selectedOption?.label ?? '');
  };

  return {
    stockId,
    setStockName,
    stockName,
    setStockId,
    stockOptions,
    handleStockChange,
  };
};

// Hook for selecting purchase inventory
export const usePurchaseInventory = (stocks) => {
  const [stockId, setStockId] = useState('');

  const stockOptions = stocks?.map((stock) => ({
    value: stock?._id,
    label: stock?.inventoryName || stock?.product?.name,
  })) ?? [];

  const handleStockChange = (selectedOption) => {
    setStockId(selectedOption?.value ?? '');
  };

  return {
    stockId,
    setStockId,
    stockOptions,
    handleStockChange,
  };
};