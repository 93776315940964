import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  posSettings: {},
  basicSettings: {},
  success: false,
  error: null,
};

export const settingsReducer = createReducer(initialState, (builder) => {
  builder
    // Get Basic Settings
    .addCase('getBasicSettingsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getBasicSettingsSuccess', (state, action) => {
      state.isLoading = false;
      state.basicSettings = action.payload;
    })
    .addCase('getBasicSettingsFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Update Basic Settings
    .addCase('updateBasicSettingsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('updateBasicSettingsSuccess', (state, action) => {
      state.isLoading = false;
      state.basicSettings = action.payload;
      state.success = true;
    })
    .addCase('updateBasicSettingsFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Update POS Settings
    .addCase('updatePosSettingsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('updatePosSettingsSuccess', (state, action) => {
      state.isLoading = false;
      state.posSettings = action.payload;
      state.success = true;
    })
    .addCase('updatePosSettingsFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Update Email Settings
    .addCase('updateEmailSettingsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('updateEmailSettingsSuccess', (state, action) => {
      state.isLoading = false;
      state.emailSettings = action.payload;
      state.success = true;
    })
    .addCase('updateEmailSettingsFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.success = false;
    });
});
