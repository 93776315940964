import axios from "axios";
import { server } from "../../server/server";

// Fetch All Inventory Items
export const getAllInventoryItems = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllInventoryItemsRequest" });

    const { data } = await axios.get(`${server}/store-inventory/get-all-items`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllInventoryItemsSuccess",
      payload: data.items,
    });
  } catch (error) {
    dispatch({
      type: "getAllInventoryItemsFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch Inventory Items by store
export const getInventoryItemsByStore = () => async (dispatch) => {
  try {
    dispatch({ type: "getInventoryItemsByStoreRequest" });

    const { data } = await axios.get(`${server}/store-inventory/get-items-by-store`, {
      withCredentials: true,
    });

    dispatch({
      type: "getInventoryItemsByStoreSuccess",
      payload: data.items,
    });

  } catch (error) {
    dispatch({
      type: "getInventoryItemsByStoreFail",
      payload: error?.response?.data?.message,
    });
  }
}

// Create Inventory Item
export const createInventoryItem = (newItem) => async (dispatch) => {
  try {
    dispatch({ type: "createInventoryItemRequest" });

    const { data } = await axios.post(`${server}/store-inventory/create-item`, newItem, {
      withCredentials: true,
    });

    dispatch({
      type: "createInventoryItemSuccess",
      payload: data.item,
    });

    dispatch(getInventoryItemsByStore());
  } catch (error) {
    dispatch({
      type: "createInventoryItemFail",
      payload: error.response.data.message,
    });
  }
};

// Edit Inventory Item
export const editInventoryItem = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editInventoryItemRequest" });

    const { data } = await axios.put(`${server}/store-inventory/update-item/${id}`, updatedData, {
      withCredentials: true,
    });

    dispatch({
      type: "editInventoryItemSuccess",
      payload: data.item,
    });

    // Optionally, fetch all inventory items again to update the list
    dispatch(getInventoryItemsByStore());
  } catch (error) {
    dispatch({
      type: "editInventoryItemFail",
      payload: error.response.data.message,
    });
  }
};

// Add Inventory Quantity to an Item
export const addInventoryQuantity = (id, quantity) => async (dispatch) => {
  try {
    dispatch({ type: "addInventoryQuantityRequest" });

    const { data } = await axios.put(`${server}/store-inventory/add-quantity/${id}`, { quantity }, {
      withCredentials: true,
    });

    dispatch({
      type: "addInventoryQuantitySuccess",
      payload: data.item,
    });

    // Optionally, fetch all inventory items again to update the list
    dispatch(getInventoryItemsByStore());
  } catch (error) {
    dispatch({
      type: "addInventoryQuantityFail",
      payload: error.response.data.message,
    });
  }
};
// Remove Inventory Quantity from an Item
export const removeInventoryQuantity = (id, quantity) => async (dispatch) => {
  try {
    dispatch({ type: "removeInventoryQuantityRequest" });

    const { data } = await axios.put(`${server}/store-inventory/remove-quantity/${id}`, { quantity }, {
      withCredentials: true,
    });

    dispatch({
      type: "removeInventoryQuantitySuccess",
      payload: data.item,
    });

    // Optionally, fetch all inventory items again to update the list
    dispatch(getInventoryItemsByStore());
  } catch (error) {
    dispatch({
      type: "removeInventoryQuantityFail",
      payload: error.response.data.message,
    });
  }
};

// Get Inventory Item Details
export const getInventoryItemDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getInventoryItemDetailsRequest" });

    const { data } = await axios.get(`${server}/store-inventory/get-item-details/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "getInventoryItemDetailsSuccess",
      payload: data.item,
    });
  } catch (error) {
    dispatch({
      type: "getInventoryItemDetailsFail",
      payload: error.response.data.message,
    });
  }
};

// Delete Inventory Item
export const deleteInventoryItem = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteInventoryItemRequest" });

    const { data } = await axios.delete(`${server}/store-inventory/delete-item/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteInventoryItemSuccess",
      payload: data.message,
    });

    // Optionally, fetch all inventory items again to update the list
    dispatch(getInventoryItemsByStore());
  } catch (error) {
    dispatch({
      type: "deleteInventoryItemFail",
      payload: error.response.data.message,
    });
  }
};

// Get store inventory value based on the total quantity and buying price
export const getInventoryValue = () => async (dispatch) => {
  try {
    dispatch({ type: "getStockValueRequest" });

    const { data } = await axios.get(`${server}/dashboard/store-inventory-value`, {
      withCredentials: true,
    });

    dispatch({
      type: "getStockValueSuccess",
      payload: data.stockValue,
    });
  } catch (error) {
    dispatch({
      type: "getStockValueFail",
      payload: error.response.data.message,
    });
  }
};
