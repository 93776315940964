/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Container, Dropdown, Navbar } from 'react-bootstrap';
import { FaBell, FaCalculator, FaCreditCard, FaExclamationTriangle, FaShoppingCart, FaTimesCircle } from 'react-icons/fa';
import { RxAvatar } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PURGE } from 'redux-persist';
import { logoutUser } from '../../../redux/actions/userAction';
import PriceCalculatorModal from '../../pages/inventory/components/PriceCalculatorModal.jsx';
import CurrentTime from '../current-time/CurrentTime.jsx';

const AdminNavbar = ({ handleShowSidebar }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** price calculator modal */
  const [showPriceCalculatorModal, setShowPriceCalculatorModal] = useState(false);
  const handlePriceCalculatorModalClose = () => setShowPriceCalculatorModal(false);

  const handleCalculatorClick = () => {
    setShowPriceCalculatorModal(true);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()); // Dispatch the logoutUser action
      localStorage.removeItem("token");
      dispatch({ type: PURGE, key: "root", result: () => null });
      navigate("/login");
      window.location.reload();
      toast.success('Logged out successfully!'); // Show success message
    } catch (error) {
      console.error("Error logging out:", error.response?.data?.message || "An error occurred");
      toast.error(error.response?.data?.message || "An error occurred"); // Optionally show error message
    }
  };


  return (
    <Navbar expand="lg" className="navbar bg-dark top-nav" data-bs-theme="dark">
      <Container fluid className="d-flex justify-content-between align-items-center">
        {/* Left side (Brand and Toggle button) */}
        <div className="d-flex align-items-center">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleShowSidebar}
            className="custom-toggle me-2"
          />
          <Navbar.Brand
            as={Link}
            to="/"
            className="ms-2 ms-lg-0 bs-name px-1 px-sm-2 px-md-3 fs-7 fs-md-7 fs-lg-4 d-none d-md-block">
            <span>B&T </span>General Stores
          </Navbar.Brand>
        </div>

        {/* Center (Current Time) */}
        <div className="d-none d-sm-flex justify-content-center align-items-center">
          <span className='text-white fs-5 fs-md-4 fs-lg-3'>
            <CurrentTime />
          </span>
        </div>

        {/* center calculator icon */}
        <div className="d-flex justify-content-center align-items-center">
          <div onClick={handleCalculatorClick}
            className="navbar-link"
            style={{
              cursor: 'pointer',
              backgroundColor: '#007bff',
              padding: '5px',
              borderRadius: '10px',
            }}
          >
            <FaCalculator size={25} className="text-white " />
          </div>
        </div>

        {/* Right side (Avatar dropdown) */}
        <div className="d-flex align-items-center">
          <div className="navbar-link">
            <Dropdown>
              <Dropdown.Toggle as="div" className="notification-toggle no-arrow cursor-pointer">
                <span className="position-relative me-3">
                  <FaBell className="text-white" size={25} />
                  <span className="badge bg-danger position-absolute top-0 start-100 translate-middle rounded-pill">
                    3
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end custom-dark" style={{ minWidth: "320px" }}>
                <Dropdown.Header className='text-center text-white'>15 Notifications</Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.Item href="#">
                  <span className="position-relative me-3">
                    <FaExclamationTriangle className="text-warning" size={22} />
                    <span className="badge bg-danger position-absolute top-0 start-100 translate-middle rounded-pill" style={{ fontSize: "0.6rem", padding: "2px 5px" }}>
                      1
                    </span>
                  </span>
                  Low Stock Alert
                  <span className="float-end text-secondary fs-7">30 mins ago</span>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#">
                  <span className="position-relative me-3">
                    <FaTimesCircle className="text-danger" size={22} />
                    <span className="badge bg-danger position-absolute top-0 start-100 translate-middle rounded-pill" style={{ fontSize: "0.6rem", padding: "2px 5px" }}>
                      3
                    </span>
                  </span>
                  **Out of Stock:**
                  <span className="float-end text-secondary fs-7">1 hour ago</span>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#">
                  <span className="position-relative me-3">
                    <FaShoppingCart className="text-primary" size={22} />
                    <span className="badge bg-danger position-absolute top-0 start-100 translate-middle rounded-pill" style={{ fontSize: "0.6rem", padding: "2px 5px" }}>
                      5
                    </span>
                  </span>
                  New Orders
                  <span className="float-end text-secondary fs-7">Just now</span>
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item href="#">
                  <span className="position-relative me-3">
                    <FaCreditCard className="text-success" size={22} />
                    <span className="badge bg-success position-absolute top-0 start-100 translate-middle rounded-pill" style={{ fontSize: "0.6rem", padding: "2px 5px" }}>
                      2
                    </span>
                  </span>
                  Payments Completed
                  <span className="float-end text-secondary fs-7">5 mins ago</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="navbar-link">
            <Dropdown>
              <Dropdown.Toggle as="div" className="avatar-toggle no-arrow cursor-pointer">
                <RxAvatar className="avatar-icon" size={30} />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end" className="custom-dark profile">
                <Dropdown.Item as={Link} to="/user-profile">
                  <i className="fas fa-user me-2" /> Profile
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogout}>
                  <i className="fas fa-power-off me-2" /> Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {showPriceCalculatorModal && (
          <PriceCalculatorModal
            show={showPriceCalculatorModal}
            onHide={handlePriceCalculatorModalClose}
          />
        )}
      </Container>
    </Navbar>

  );
}

export default AdminNavbar;