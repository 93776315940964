/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createStore, getAllStores } from '../../../redux/actions/storeAction.js';
import { getAllBranches } from '../../../redux/actions/branchAction.js';
import { capitalizeFirstLetter } from '../../utils/utils.js';

const CreateStoreModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, stores } = useSelector((state) => state.store);
  const { branches } = useSelector((state) => state.branch);

  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [contact, setContact] = useState('');
  const [branch, setBranch] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(getAllBranches());
  }, [dispatch]);

  const handleNameChange = (e) => {
    const newName = capitalizeFirstLetter(e.target.value);
    setName(newName);

    const storeExists = stores.some(store => store.name === newName);
    if (storeExists) {
      toast.error('Store name already exists!');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const storeData = {
      name,
      location,
      contact,
      branch,
      active,
    };

    dispatch(createStore(storeData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Store created successfully!');
      dispatch(getAllStores()); // Fetch updated store list
      setName('');
      setLocation('');
      setContact('');
      setBranch('');
      setActive(true);
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Store</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Store Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Enter store name"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="Enter location"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Contact</Form.Label>
                <Form.Control
                  type="text"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  placeholder="Enter contact"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Branch</Form.Label>
                <Form.Control
                  as="select"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  required
                >
                  <option value="">Select Branch</option>
                  {branches.map((branch) => (
                    <option key={branch._id} value={branch._id}>
                      {branch.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading} className='float-end'>
            {isLoading ? 'Creating...' : 'Create Store'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateStoreModal;
