/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { editCategory, getAllCategories } from '../../../redux/actions/categoryAction.js';
import { capitalizeFirstLetter } from '../../utils/utils.js';

const EditCategoryModal = ({ show, onHide, category }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, categories } = useSelector((state) => state.category);

  const [name, setName] = useState('');
  const [shortForm, setShortForm] = useState('');
  const [description, setDescription] = useState('');

  // state error
  const [errorName, setErrorName] = useState('');

  useEffect(() => {
    if (category) {
      setName(category?.name || '');
      setShortForm(category?.shortForm || '');
      setDescription(category?.description || '');
    }
  }, [category]);

  const handleNameChange = (e) => {
    const newName = capitalizeFirstLetter(e.target.value);
    setName(newName);

    const categoryExists = categories?.some(cat => cat.name === newName);
    if (categoryExists) {
      toast.error('Category name already exists!');
      setErrorName('Category name already exists!');
    } else {
      setErrorName(''); // Clear error if name is unique
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const categoryData = {
      name,
      shortForm,
      description,
    };

    dispatch(editCategory(category?._id, categoryData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Category updated successfully!');
      dispatch(getAllCategories()); // Fetch updated category list
      onHide(); // Close the modal
    }
    if (error) {
      toast.error(error);
    }
  }, [dispatch, error, onHide, success]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Enter category name"
              required
            />
          </Form.Group>
          {errorName && <p className="text-danger">{errorName}</p>}
          <Form.Group className="mb-3">
            <Form.Label>Short Form</Form.Label>
            <Form.Control
              type="text"
              value={shortForm}
              onChange={(e) => setShortForm(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter short form"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter description"
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading} className='float-end'>
            {isLoading ? 'Updating...' : 'Update Category'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCategoryModal;