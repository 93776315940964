/* eslint-disable react/prop-types */
import { Button, Modal, Table } from "react-bootstrap";

const ViewSaleModal = ({ show, handleClose, sale }) => {

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Sale Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Date:</strong> {new Date(sale?.saleDate).toLocaleDateString()}
        </p>
        <p>
          <strong>Customer:</strong>{" "}
          {sale?.customer?.name || "Unknown Customer"}
        </p>
        <p>
          <strong>Total Amount:</strong> Ksh.{sale?.totalAmount}
        </p>
        <p>
          <strong>Payment Method:</strong> {sale?.paymentMethod}
        </p>
        <p>
          <strong>Cashier:</strong>{" "}
          {sale?.cashier?.firstName || "Unknown Cashier"}
        </p>

        <h5>Items : {sale?.items.length}</h5>
        <div className="table-container" style={{ overflowY: "auto" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {sale &&
                sale.items.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.product?.name || item?.stock?.inventoryName}</td>
                    <td>{item?.qty}</td>
                    <td>Ksh. {item?.sellingPrice.toFixed(2)}</td>
                    <td>Ksh. {(item?.qty * item?.sellingPrice).toFixed(2)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewSaleModal;
