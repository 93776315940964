/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createBranch, getAllBranches } from '../../../redux/actions/branchAction.js';
import { capitalizeFirstLetter } from '../../utils/utils.js';

const CreateBranchModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, branches } = useSelector((state) => state.branch);

  const [name, setName] = useState('Branch Name');
  const [description, setDescription] = useState('Description');
  const [location, setLocation] = useState('Nakuru');
  const [contact, setContact] = useState('0719694420');
  const [active, setActive] = useState(true);

  const handleNameChange = (e) => {
    const newName = capitalizeFirstLetter(e.target.value);
    setName(newName);

    const branchExists = branches.some(branch => branch.name === newName);
    if (branchExists) {
      toast.error('Branch name already exists!');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const branchData = {
      name,
      description,
      location,
      contact,
      active,
    };

    dispatch(createBranch(branchData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Branch created successfully!');
      dispatch(getAllBranches()); // Fetch updated branch list
      setName('');
      setDescription('');
      setLocation('');
      setContact('');
      setActive(true);
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Branch Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Enter branch name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Enter location"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type="text"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              placeholder="Enter contact"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading} className='float-end'>
            {isLoading ? 'Creating...' : 'Create Branch'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateBranchModal;
