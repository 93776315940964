/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getAllPurchases } from '../../../redux/actions/purchaseAction.js';
import { getAllSuppliers } from '../../../redux/actions/supplierAction.js';
import { useProductSelect, useSupplierSelect } from '../../utils/selectFunctions.jsx';
import { getTodayDate } from '../../utils/utils.js';

import CreateProductModal from '../products/CreateProductModal.jsx';
import CreateSupplierModal from '../suppliers/CreateSupplierModal.jsx';

const CreatePurchaseModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.purchase);
  const { suppliers } = useSelector((state) => state.supplier);
  const { products } = useSelector((state) => state.product);

  const { productOptions } = useProductSelect(products);
  const { supplierId, supplierOptions } = useSupplierSelect(suppliers);

  const [supplier, setSupplier] = useState('');
  const [date, setDate] = useState(getTodayDate());
  const [totalAmount, setTotalAmount] = useState('');

  /** create product modals */
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const handleCreateProductModalClose = () => setShowCreateProductModal(false);

  /** create supplier modals */
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const handleCreateSupplierModalClose = () => setShowCreateSupplierModal(false);

  useEffect(() => {
    dispatch(getAllSuppliers());
  }, [dispatch]);

  const maxDate = getTodayDate();

  // handle purchase date change
  const handlePurchaseDateChange = (e) => {
    setDate(e.target.value);
    // purchase date should not be greater than today
    if (e.target.value > maxDate) {
      toast.error('Purchase date should not be greater than today');
      setDate(maxDate);
    }
  };

  // handle purchase date blur
  const handlePurchaseDateBlur = (e) => {
    if (e.target.value === '') {
      setDate(maxDate);
    } else if (e.target.value > maxDate) {
      toast.error('Purchase date should not be greater than today');
      setDate(maxDate);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const purchaseData = {
      supplier: supplierId,
      date,
      totalAmount,
    };

    console.log(purchaseData);

    dispatch(createPurchase(purchaseData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Purchase created successfully!');
      dispatch(getAllPurchases());
      setSupplier('');
      setDate('');
      setTotalAmount('');
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} style={{ zIndex: '1050' }}>
      <Modal.Header closeButton>
        <Modal.Title>Create Purchase</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12}>
              <Form.Group className="mb-2">
                <Form.Label>Product Name :</Form.Label>
                <div className="d-flex">
                  <Select
                    // value={productOptions.find(option => option.value === productId)}
                    // onChange={handleProductNameChange}
                    options={productOptions}
                    placeholder="Select product"
                    isClearable
                    className="flex-grow-1"
                    required
                  />
                  <Button
                    className="ms-2"
                    onClick={() => setShowCreateProductModal(true)}
                    variant="outline-primary"
                    type="button"
                  >
                    <FaPlus />
                  </Button>
                </div>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Supplier</Form.Label>
                <div className="d-flex">
                  <Select
                    value={supplier}
                    onChange={(selectedOption) => setSupplier(selectedOption)}
                    options={supplierOptions}
                    placeholder="Select supplier"
                    isClearable
                    className="flex-grow-1"
                    required
                  />
                  <Button
                    className="ms-2"
                    onClick={() => setShowCreateSupplierModal(true)}
                    variant="outline-primary"
                    type="button"
                  >
                    <FaPlus />
                  </Button>
                </div>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Purchase Date</Form.Label>
                <Form.Control
                  type="date"
                  value={date}
                  onChange={handlePurchaseDateChange}
                  max={maxDate}
                  onBlur={handlePurchaseDateBlur}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                  placeholder='Enter total amount'
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" disabled={isLoading} className="float-end">
            {isLoading ? 'Creating...' : 'Create Purchase'}
          </Button>
        </Form>
      </Modal.Body>
      {showCreateProductModal && (
        <CreateProductModal
          show={showCreateProductModal}
          onHide={handleCreateProductModalClose}
        />
      )}
      {showCreateSupplierModal && (
        <CreateSupplierModal
          show={showCreateSupplierModal}
          onHide={handleCreateSupplierModalClose}
        />
      )}
    </Modal>
  );
};

export default CreatePurchaseModal;
