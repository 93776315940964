import { Breadcrumb, Col, Nav, Row, Tab } from 'react-bootstrap';
import UserProfile from './UserProfile';
import ChangePassword from './ChangePassword';

const UserProfilePage = () => {

  return (
    <div className='container'>
      <Row className="my-1">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active> User</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
          <Nav variant="pills" className="nav-pills nav-fill mb-2">
            <Nav.Item>
              <Nav.Link eventKey="basic">Basic Settings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="change-password">Change Password</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="basic">
              <UserProfile />
            </Tab.Pane>
            <Tab.Pane eventKey="change-password">
              <ChangePassword />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Row>
    </div>
  );
};

export default UserProfilePage;
