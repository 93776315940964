import axios from "axios";
import { server } from "../../server/server";

// Create Role
export const createRole = (newRole) => async (dispatch) => {
  try {
    dispatch({ type: "createRoleRequest" });

    const { data } = await axios.post(`${server}/role/create-role`, newRole, {
      withCredentials: true,
    });

    dispatch({
      type: "createRoleSuccess",
      payload: data.role,
    });
  } catch (error) {
    dispatch({
      type: "createRoleFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Roles
export const getAllRoles = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllRolesRequest" });

    const { data } = await axios.get(`${server}/role/get-all-roles`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllRolesSuccess",
      payload: data.roles,
    });
  } catch (error) {
    dispatch({
      type: "getAllRolesFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Role
export const editRole = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editRoleRequest" });

    const { data } = await axios.put(`${server}/role/update-role/${id}`, updatedData, {
      withCredentials: true,
    });

    dispatch({
      type: "editRoleSuccess",
      payload: data.role,
    });

    // Optionally, fetch all roles again to update the list
    dispatch(getAllRoles());
  } catch (error) {
    dispatch({
      type: "editRoleFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Role
export const deleteRole = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteRoleRequest" });

    const { data } = await axios.delete(`${server}/role/delete-role/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteRoleSuccess",
      payload: data.message,
    });

    // Optionally, fetch all roles again to update the list
    dispatch(getAllRoles());
  } catch (error) {
    dispatch({
      type: "deleteRoleFailed",
      payload: error.response.data.message,
    });
  }
};
