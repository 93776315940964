/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { toast } from "react-toastify";
import { getAllCategories } from "../../../redux/actions/categoryAction.js";
import { createSubcategory, getAllSubcategories } from "../../../redux/actions/subcategoryAction.js";
import { useCategorySelect } from "../../utils/selectFunctions.jsx";
import { capitalizeFirstLetter } from "../../utils/utils.js";
import CreateCategoryModal from "../categories/CreateCategoryModal.jsx";

const CreateSubcategoryModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);
  const { isLoading, success, error } = useSelector((state) => state.subcategory);

  const { categoryId, categoryOptions, handleCategoryChange } = useCategorySelect(categories);

  const [name, setName] = useState("");
  const [shortForm, setShortForm] = useState("");

  /** create modals */
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch])


  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const subcategoryData = {
      name,
      shortForm,
      category: categoryId,
    };

    console.log(subcategoryData)

    dispatch(createSubcategory(subcategoryData));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    if (success) {
      toast.success("Subcategory created successfully!");
      dispatch(getAllSubcategories());

      const resetForm = () => {
        setName("");
        setShortForm("");
      };

      resetForm();
      onHide();
    }

  }, [dispatch, error, onHide, success]);


  const handleAddCategory = () => {
    setShowCreateModal(true);
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} style={{ zIndex: 1050 }}>
      <Modal.Header closeButton>
        <Modal.Title>Create Sub Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Main Category</Form.Label>
            <div className="d-flex">
              <Select
                value={categoryOptions.find(option => option.value === categoryId)}
                onChange={handleCategoryChange}
                options={categoryOptions}
                placeholder="Select main category"
                isClearable
                className="flex-grow-1"
                required
              />
              <Button
                className="ms-2"
                onClick={handleAddCategory}
                type="button"
              >
                <FaPlus />
              </Button>
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Sub Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter sub category name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Short Form</Form.Label>
            <Form.Control
              type="text"
              value={shortForm}
              onChange={(e) => setShortForm(capitalizeFirstLetter(e.target.value))}
              placeholder="Enter short form"
              required
            />
          </Form.Group>

          <Button className="float-end mb-3" variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Sub Category"}
          </Button>
        </Form>
        {showCreateModal && (
          <CreateCategoryModal
            show={showCreateModal}
            onHide={handleCreateModalClose}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CreateSubcategoryModal;