import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  user: null,
  users: [],
  success: false,
  error: null,
  message: null,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    // Load User
    .addCase('LoadUserRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('LoadUserSuccess', (state, action) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.user = action.payload;
    })
    .addCase('LoadUserFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    })

    // User Creation
    .addCase('userCreateRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('userCreateSuccess', (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.success = true;
      state.message = "User created successfully!";
    })
    .addCase('userCreateFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })

    // Edit User
    .addCase('editUserRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editUserSuccess', (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.success = true;
      state.message = "User updated successfully!";
    })
    .addCase('editUserFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })

    // Get All Users
    .addCase('getAllUsersRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllUsersSuccess', (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    })
    .addCase('getAllUsersFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    // Delete User
    .addCase('deleteUserRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteUserSuccess', (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.message = "User deleted successfully!";
      state.users = state.users.filter(user => user._id !== action.payload._id);
    })
    .addCase('deleteUserFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })

    // Logout User
    .addCase('LogoutSuccess', (state) => {
      state.isAuthenticated = false;
      state.user = null;
    })
    .addCase('LogoutFail', (state, action) => {
      state.error = action.payload;
    })

    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })

    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
