// Pricing Attributes for Inventory Management
export const pricingAttributes = [
  "Size",
  "Weight",
  "Dimensions",
  "Volume",
  "Capacity",
  "Material Type",
  "Finish Type",
  "Style/Design",
  "Brand",
  "Compliance Standards",
  "Roll"
];

// Variant Types for Inventory Management
export const inventoryAttributes = [
  "Color",
  "Weight",
  "Material",
  "Dimensions",
  "Style",
  "Pattern",
  "Flavor",
  "Volume",
  "Length",
  "Width",
  "Height",
];
