import axios from "axios";
import { server } from "../../server/server";

// Create Store
export const createStore = (newStore) => async (dispatch) => {
  try {
    dispatch({ type: "createStoreRequest" });

    const { data } = await axios.post(`${server}/store/create-store`, newStore, {
      withCredentials: true,
    });

    dispatch({
      type: "createStoreSuccess",
      payload: data.store,
    });
  } catch (error) {
    dispatch({
      type: "createStoreFail",
      payload: error.response.data.message,
    });
  }
};

// Fetch All Stores
export const getAllStores = () => async (dispatch) => {
  try {
    dispatch({ type: "getAllStoresRequest" });

    const { data } = await axios.get(`${server}/store/get-all-stores`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllStoresSuccess",
      payload: data.stores,
    });
  } catch (error) {
    dispatch({
      type: "getAllStoresFailed",
      payload: error.response.data.message,
    });
  }
};

// Edit Store
export const editStore = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "editStoreRequest" });

    const { data } = await axios.put(`${server}/store/update-store/${id}`, updatedData, {
      withCredentials: true,
    });

    dispatch({
      type: "editStoreSuccess",
      payload: data.store,
    });

    // Optionally, fetch all stores again to update the list
    dispatch(getAllStores());
  } catch (error) {
    dispatch({
      type: "editStoreFailed",
      payload: error.response.data.message,
    });
  }
};

// Delete Store
export const deleteStore = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteStoreRequest" });

    const { data } = await axios.delete(`${server}/store/delete-store/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: "deleteStoreSuccess",
      payload: data.message,
    });

    // Optionally, fetch all stores again to update the list
    dispatch(getAllStores());
  } catch (error) {
    dispatch({
      type: "deleteStoreFailed",
      payload: error.response.data.message,
    });
  }
};
