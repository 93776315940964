/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { editSupplier } from '../../../redux/actions/supplierAction';
import { capitalizeFirstLetter, toUpperCase } from '../../utils/utils';

const EditSupplierModal = ({ show, onHide, supplier }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [kraPin, setKraPin] = useState('');
  const [address, setAddress] = useState('');
  const [company, setCompany] = useState('');

  useEffect(() => {
    if (supplier) {
      setName(supplier.name);
      setEmail(supplier.email);
      setPhoneNumber(supplier.phoneNumber);
      setKraPin(supplier.kraPin || 'N/A');
      setAddress(supplier.address);
      setCompany(supplier.company);
    }
  }, [supplier]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // supplier data
    const supplierData = {
      name,
      email,
      phoneNumber,
      kraPin,
      address,
      company,
    };


    dispatch(editSupplier(supplier._id, supplierData));
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size='lg' backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Supplier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Enter company name"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
                  placeholder="Enter supplier name"
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter supplier email"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>KRA PIN</Form.Label>
                <Form.Control
                  type="text"
                  value={kraPin}
                  onChange={(e) => setKraPin(toUpperCase(e.target.value))}
                  placeholder="Enter KRA PIN"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(capitalizeFirstLetter(e.target.value))}
                  placeholder="Enter location"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Button className='float-end' variant="primary" type="submit">
            Update
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditSupplierModal;