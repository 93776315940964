/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { editInventoryItem, getAllInventoryItems } from '../../../../redux/actions/storeInventoryAction';
import { getAllProductUnits } from '../../../../redux/actions/productUnitAction';
import { useProductUnitSelect, useStoreInventoryProduct } from '../../../utils/selectFunctions';
import Select from 'react-select';
import { handleNumericInput } from '../../../utils/utils';

const EditStoreInventoryModal = ({ show, onHide, inventoryItem }) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state.storeInventory);
  const { stocks } = useSelector((state) => state.stock);
  const { productUnits } = useSelector((state) => state.productUnit);

  const { stockId, setStockId, stockName, setStockName, stockOptions, handleStockChange } = useStoreInventoryProduct(stocks);
  const { productUnitId, setProductUnitId, productUnitOptions, handleProductUnitChange } = useProductUnitSelect(productUnits);

  // get selected stock
  const selectedStock = stocks?.find((stock) => stock?._id === stockId);

  const [unitQuantity, setUnitQuantity] = useState('');
  const [numberOfUnits, setNumberOfUnits] = useState('');
  const [totalQuantity, setTotalQuantity] = useState('');
  const [inventoryVariation, setInventoryVariation] = useState('');

  useEffect(() => {
    if (inventoryItem) {
      setStockId(inventoryItem.stock._id);
      setStockName(inventoryItem.stock.name);
      setInventoryVariation(inventoryItem.inventoryVariation);
      setProductUnitId(inventoryItem.productUnit._id);
      setUnitQuantity(inventoryItem.unitQuantity);
      setNumberOfUnits(inventoryItem.numberOfUnits);
      setTotalQuantity(inventoryItem.totalQuantity);
    }
  }, [inventoryItem, setProductUnitId, setStockId, setStockName]);

  useEffect(() => {
    if (show) {
      dispatch(getAllProductUnits())
    }
  }, [dispatch, show])


  // calculate total quantity
  useEffect(() => {
    setTotalQuantity(numberOfUnits * unitQuantity);
  }, [numberOfUnits, unitQuantity]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newItem = {
      stock: stockId,
      name: stockName,
      inventoryVariation,
      product: selectedStock?.product,
      productUnit: productUnitId,
      unitQuantity,
      numberOfUnits,
      totalQuantity,
    };
    dispatch(editInventoryItem(inventoryItem._id, newItem));
  };

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
      toast.error(error);
    }

    if (success) {
      toast.success("Inventory item created successfully!");
      dispatch(getAllInventoryItems())
      dispatch({ type: "clearSuccess" });
      onHide();
    }
  }, [dispatch, error, onHide, success]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Inventory Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-2" controlId="inventoryId">
                <Form.Label>Select Product :</Form.Label>
                <div className="d-flex">
                  <InputGroup>
                    <Select
                      value={stockOptions.find(option => option.value === stockId) || null}
                      onChange={handleStockChange}
                      options={stockOptions}
                      placeholder="Select Inventory"
                      isClearable
                      className="flex-grow-1"
                      required
                    />
                  </InputGroup>
                </div>
              </Form.Group>
            </Col>
            {selectedStock?.variantType === 'Multiple' && (
              <Col>
                <Form.Group className="mb-2" controlId="inventoryVariant">
                  <Form.Label>Inventory Variation :</Form.Label>
                  <Form.Control
                    as="select"
                    value={inventoryVariation}
                    onChange={(e) => setInventoryVariation(e.target.value)}
                    required
                  >
                    <option value="">Select Inventory Variation</option>
                    {selectedStock?.primaryVariations?.map((variant, index) => (
                      <option key={index} value={variant}>
                        {variant}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
            <Col lg={6}>
              <Form.Group className="mb-2" controlId="productUnit">
                <Form.Label>Unit Received :</Form.Label>
                <Select
                  value={productUnitOptions.find(option => option.value === productUnitId) || null}
                  onChange={handleProductUnitChange}
                  options={productUnitOptions}
                  placeholder="Select unit received"
                  isClearable
                  className="flex-grow-1"
                  required
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-2" controlId="unitQuantity">
                <Form.Label>Unit Quantity :</Form.Label>
                <Form.Control
                  type="number"
                  value={unitQuantity}
                  onChange={(e) => handleNumericInput(e, setUnitQuantity)}
                  placeholder="Enter unit quantity"
                  required
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-2" controlId="numberOfUnits">
                <Form.Label>Number of Units :</Form.Label>
                <Form.Control
                  type="number"
                  value={numberOfUnits}
                  onChange={(e) => handleNumericInput(e, setNumberOfUnits)}
                  placeholder="Enter number of units"
                  required
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-2" controlId="totalQuantity">
                <Form.Label>Total Quantity :</Form.Label>
                <Form.Control
                  type="number"
                  value={totalQuantity}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" className="float-end mt-3">
            Update
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditStoreInventoryModal;
