/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getAllCategories } from '../../../redux/actions/categoryAction';
import { editProduct } from '../../../redux/actions/productAction.js';
import { getAllProductUnits } from '../../../redux/actions/productUnitAction.js';
import { getAllSubcategories } from '../../../redux/actions/subcategoryAction';
import { getAllVariants } from '../../../redux/actions/variantAction.js';
import { usePricingAttribute, useProductCategorySelect, useSubcategorySelect } from '../../utils/selectFunctions.jsx';
import { capitalizeFirstLetter } from '../../utils/utils.js';
import CreateCategoryModal from '../categories/CreateCategoryModal.jsx';
import CreateVariantModal from '../variants/CreateVariantModal.jsx';
import AddVariantVariationModal from '../variants/variant-details/AddVariantVariation.jsx';
import CreateSubcategoryModal from './CreateSubcategoryModal.jsx';

import { pricingAttributes } from '../../data/staticData.js';

const EditProductModal = ({ show, onHide, product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, error } = useSelector((state) => state.product);
  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.subcategory);

  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState("");
  const [pricingVariation, setPricingVariation] = useState('');
  const [pricingVariations, setPricingVariations] = useState([]);

  const { categoryOptions } = useProductCategorySelect(categories);
  const { subcategoryOptions } = useSubcategorySelect(subcategories, category);
  const { pricingAttribute, setPricingAttribute, pricingAttributeOptions, handlePricingAttributeChange } = usePricingAttribute(pricingAttributes);

  // product variants
  const [productVariants, setProductVariants] = useState([]);

  /** create category modals */
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const handleCreateCategoryClose = () => setShowCreateCategory(false);

  // create subcategory modals
  const [showCreateSubcategoryModal, setShowCreateSubcategoryModal] = useState(false);
  const handleCreateSubcategoryClose = () => setShowCreateSubcategoryModal(false);

  // add variant variations modals
  const [showAddVariantVariation, setShowAddVariantVariation] = useState(false);
  const handleAddVariantVariationClose = () => setShowAddVariantVariation(false);

  // add variant modals
  const [showAddVariant, setShowAddVariant] = useState(false);
  const handleAddVariantClose = () => setShowAddVariant(false);

  // Get all categories, subcategories, product units and variants
  useEffect(() => {
    if (show) {
      dispatch(getAllCategories());
      dispatch(getAllSubcategories());
      dispatch(getAllProductUnits());
      dispatch(getAllVariants());
    }
  }, [show, dispatch]);

  useEffect(() => {
    if (product) {
      setName(product?.name || '');
      setCategory(product?.category?._id || '');
      setSubcategory(product?.subcategory?._id || '');
      setPricingAttribute(product?.pricingAttribute || '');
      setPricingVariations(product?.pricingVariations || []);
      setProductVariants(product?.productVariants || []);
    }
  }, [product, setPricingAttribute]);

  useEffect(() => {
    if (pricingVariations) {
      const productVariants = pricingVariations.map((variant) => `${name} ${variant}`);
      setProductVariants(productVariants);
    }
  }, [name, pricingVariations]);

  // Handle category change
  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption ? selectedOption.value : "");
    setSubcategory("");
    // setPricingAttribute("");
  };

  // Handle subcategory change
  const handleSubcategoryChange = (selectedOption) => {
    setSubcategory(selectedOption ? selectedOption.value : "");
  };

  // Handle pricing attribute change
  const handlePricingChangeAttribute = (selectedOption) => {
    handlePricingAttributeChange(selectedOption);
  };


  // Handle add price variation
  const handleAddPricingVariation = () => {
    if (!pricingVariation) {
      toast.error('Please enter a valid price variation.');
      return;
    }

    const trimmedPricingVariant = pricingVariation.trim();
    const trimmedName = name.trim();
    if (trimmedPricingVariant && !pricingVariations.includes(trimmedPricingVariant)) {
      setPricingVariations((prev) => [...prev, trimmedPricingVariant]);
      setPricingVariation("");
      handleAddProductVariant(`${trimmedName} ${trimmedPricingVariant}`);
    } else if (pricingVariations.includes(trimmedPricingVariant)) {
      toast.error(`${pricingVariation || ''} variant already exists!.`);
    }
  };

  // handle add product variant
  const handleAddProductVariant = (productVariant) => {
    setProductVariants((prev) => [...prev, productVariant]);
  };

  // handle remove product variant
  const handleRemoveProductVariant = (productVariant) => {
    setProductVariants((prev) => prev.filter((variant) => variant !== productVariant));
  };

  // Handle remove price variation
  const handleRemovePricingVariation = (priceVariant) => {
    setPricingVariations((prev) => prev.filter((variant) => variant !== priceVariant));
    handleRemoveProductVariant(`${name} ${priceVariant}`);
  };



  // Handle add category
  const handleAddCategory = () => {
    setShowCreateCategory(true);
  };

  // Handle add subcategory
  const handleAddSubcategory = () => {
    setShowCreateSubcategoryModal(true);
  };


  /** handle submit function */
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation for required fields
    if (!name || !category) {
      toast.error('Please fill out all required fields.');
      return;
    }

    if (pricingVariations.length === 0) {
      toast.error(`Please add at least one ${pricingAttribute || ''}.`);
      return;
    }

    // Create product data object
    const updatedProductData = {
      name,
      category,
      subcategory,
      pricingAttribute,
      pricingVariations,
      productVariants,
    };

    // Dispatch the action to edit the product
    dispatch(editProduct(product._id, updatedProductData));

  };


  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (success) {
      toast.success('Product updated successfully!');
      dispatch({ type: "clearSuccess" });
      onHide();
      // navigate('/product/' + product._id);
      navigate('/products');

    }
  }, [dispatch, error, navigate, onHide, product._id, success]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size='lg' style={{ zIndex: 1050 }} fixed="top">
      <Modal.Header closeButton>
        <Modal.Title>Edit Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Select Main Category :</Form.Label>
                <div className="d-flex flex-wrap">
                  <Select
                    value={categoryOptions.find(option => option.value === category)}
                    onChange={handleCategoryChange}
                    options={categoryOptions}
                    placeholder="Select main category"
                    isClearable
                    className="flex-grow-1"
                    required
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip">Add new category</Tooltip>}
                  >
                    <Button
                      className="ms-2 btn-sm"
                      onClick={handleAddCategory}
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Sub Category :</Form.Label>
                <div className="d-flex">
                  <Select
                    value={subcategoryOptions.find(option => option.value === subcategory) || null}
                    onChange={handleSubcategoryChange}
                    options={subcategoryOptions}
                    placeholder="Select subcategory"
                    isClearable
                    className="flex-grow-1"
                    required
                  />
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip">Add new subcategory</Tooltip>}
                  >
                    <Button
                      className="ms-2"
                      onClick={handleAddSubcategory}
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(capitalizeFirstLetter(e.target.value))}
                  placeholder='Enter product name'
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Pricing Variant :</Form.Label>
                <div className="d-flex">
                  <Select
                    value={pricingAttributeOptions.find(option => option.value === pricingAttribute) || null}
                    onChange={handlePricingChangeAttribute}
                    options={pricingAttributeOptions}
                    placeholder="Select pricing variant"
                    isClearable
                    className="flex-grow-1"
                    required
                  />
                  {/* <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip">Add new variant</Tooltip>}
                  >
                    <Button
                      className="ms-2"
                      onClick={() => setShowAddVariant(true)}
                      type="button"
                    >
                      <FaPlus />
                    </Button>
                  </OverlayTrigger> */}
                </div>
              </Form.Group>
            </Col>

            {pricingAttribute && (
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Available {pricingAttribute || ''} Variations :</Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      type="text"
                      value={pricingVariation}
                      onChange={(e) => setPricingVariation(capitalizeFirstLetter(e.target.value))}
                      placeholder='Enter price variation'
                    />
                    <Button
                      className="ms-2"
                      onClick={handleAddPricingVariation}
                      type="button"
                    >
                      Add
                    </Button>
                  </div>
                </Form.Group>
                <div className="mb-2">
                  {pricingVariations?.map((priceVariant, i) => (
                    <span key={i} className="badge bg-info d-inline-flex align-items-center me-2">
                      {priceVariant}
                      <AiOutlineClose
                        size={15}
                        className="icon ms-2 cursor-pointer"
                        onClick={() => handleRemovePricingVariation(priceVariant)}
                      />
                    </span>
                  ))}
                </div>
              </Col>
            )}
            {pricingAttribute && productVariants && productVariants.length > 0 && (
              <Col lg={12}>
                <Form.Group className="mb-0">
                  <Form.Label>Product Variants :</Form.Label>
                </Form.Group>
                <div className="mb-3">
                  {productVariants?.map((productVariant, i) => (
                    <span key={i} className="badge bg-info d-inline-flex align-items-center gap-2 me-2">
                      {productVariant}
                    </span>
                  ))}
                </div>
              </Col>
            )}
          </Row>
          <div className="float-end my-3">
            <Button
              className="ms-3"
              type="submit"
              variant="primary"
            // disabled={!getSelectedVariant}
            >
              Update Product
            </Button>
          </div>
        </Form>
        {
          showCreateCategory && (
            <CreateCategoryModal
              show={showCreateCategory}
              onHide={handleCreateCategoryClose}
            />
          )
        }
        {
          showCreateSubcategoryModal && (
            <CreateSubcategoryModal
              show={showCreateSubcategoryModal}
              onHide={handleCreateSubcategoryClose}
              categoryId={category}
            />
          )
        }
        {showAddVariant && (
          <CreateVariantModal
            show={showAddVariant}
            onHide={handleAddVariantClose}
            variantType="Pricing"
          />
        )}
        {
          showAddVariantVariation && (
            <AddVariantVariationModal
              show={showAddVariantVariation}
              onHide={handleAddVariantVariationClose}
            // variant={getSelectedVariant}
            />
          )
        }
      </Modal.Body >
    </Modal >
  );
};

export default EditProductModal;
