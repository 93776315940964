import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAllExpenses } from '../../../../redux/actions/expenseAction.js';
import { getAllSales } from '../../../../redux/actions/saleAction.js';
import { getAllPurchases } from '../../../../redux/actions/purchaseAction.js';
import { handleExpenseFilter, handleSalesFilter, handlePurchaseFilter } from '../../../components/utils/handleFilter.jsx';

const CashFlowPage = () => {
  const dispatch = useDispatch();
  const { expenses } = useSelector((state) => state.expense);
  const { sales } = useSelector((state) => state.sale);
  const { purchases } = useSelector((state) => state.purchase);

  /**search states */
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(getAllExpenses());
    dispatch(getAllSales());
    dispatch(getAllPurchases());
  }, [dispatch]);

  useEffect(() => {
    const combinedRecords = [
      ...expenses.map(expense => ({ ...expense, type: 'Expense' })),
      ...sales.map(sale => ({ ...sale, type: 'Sale' })),
      ...purchases.map(purchase => ({ ...purchase, type: 'Purchase' })),
    ];
    setRecords(combinedRecords);
  }, [expenses, sales, purchases]);

  /** search function */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredExpenses = handleExpenseFilter(expenses, term);
    const filteredSales = handleSalesFilter(sales, term);
    const filteredPurchases = handlePurchaseFilter(purchases, term);
    const combinedFilteredRecords = [
      ...filteredExpenses.map(expense => ({ ...expense, type: 'Expense' })),
      ...filteredSales.map(sale => ({ ...sale, type: 'Sale' })),
      ...filteredPurchases.map(purchase => ({ ...purchase, type: 'Purchase' })),
    ];
    setRecords(combinedFilteredRecords);
  };

  /** table columns */
  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Type",
      selector: (row) => row?.type,
    },
    {
      name: "ID",
      selector: (row) => row?.expenseId || row?.saleId || row?.purchaseId,
    },
    {
      name: "Description",
      selector: (row) => row?.description || row?.customer?.name || row?.supplier?.name,
    },
    {
      name: "Amount",
      selector: (row) => row?.amount || row?.totalAmount,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.date).toLocaleDateString(),
    },
  ];

  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Cash Flow Report
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Cash Flow Report</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search cash flow..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              noDataComponent="No records found"
              striped
              highlightOnHover
              responsive
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CashFlowPage;