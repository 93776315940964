import { useEffect, useState } from "react";
import { Breadcrumb, Col, Form, Row } from "react-bootstrap"; // Import Breadcrumb
import DataTable from "react-data-table-component";
import { FaEye, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteSale, getSalesByStore } from "../../../redux/actions/saleAction.js";
import { formatTimeWithTime } from "../../../utils/TimeFormats.jsx";
import ConfirmDeleteModal from "../../components/confirm-delete/ConfirmDeleteModal.jsx";
import { handleSalesFilter } from "../../components/utils/handleFilter.jsx";
import CreateProductModal from "../products/CreateProductModal.jsx";
import EditProductModal from "../products/EditProductModal.jsx";
import ViewSaleModal from './ViewSaleModal.jsx';

const SalesPage = () => {

  const [selectedSale, setSelectedSale] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);

  const { sales, error, success } = useSelector((state) => state.sale);
  const { user } = useSelector((state) => state.user);
  console.log(sales)
  const storeId = user && user?.storeId;

  const dispatch = useDispatch();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateModalClose = () => setShowCreateModal(false);

  const [showModal, setShowModal] = useState(false);

  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [editingProduct, setEditingProduct] = useState(null);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [saleToDelete, setSaleToDelete] = useState(null);

  useEffect(() => {
    dispatch(getSalesByStore(storeId));
  }, [dispatch, storeId]);

  useEffect(() => {
    setRecords(sales || []);
  }, [sales]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleSalesFilter(sales, term);
    setRecords(filteredData);
  };

  const handleDelete = (saleId) => {
    if (saleId) {
      setSaleToDelete(saleId);
      setShowConfirmDeleteModal(true);
    }
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSale(saleToDelete));
    setShowConfirmDeleteModal(false);
    toast.success("Sale deleted successfully");
    setSaleToDelete(null);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setSaleToDelete(null);
  };


  const handleModalClose = () => {
    setShowModal(false);
    setEditingProduct(null);
  };

  const handleView = (sale) => {
    setSelectedSale(sale);
    setShowViewModal(true);
  };

  const handleCloseViewModal = () => {
    setShowViewModal(false);
    setSelectedSale(null);
  };

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);


  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Receipt Number",
      selector: (row) => row?.receiptNo,
    },
    {
      name: "Customer Name",
      selector: (row) => row?.customer?.name,
    },
    {
      name: "Total Amount",
      selector: (row) => `Ksh. ${row?.totalAmount}`,
    },
    {
      name: "Payment Method",
      selector: (row) => row?.paymentMethod,
    },
    {
      name: "Date",
      selector: (row) => formatTimeWithTime(row?.saleDate),
    },
    {
      name: "Served By:",
      selector: (row) => row?.cashier?.firstName,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          <span className="me-3 text-primary cursor-pointer" title="View Details"
            onClick={() => handleView(row)}
          >
            <FaEye size={20} />
          </span>
          <span
            className="text-danger cursor-pointer"
            onClick={() => handleDelete(row._id)}
            title="Delete"
          >
            <FaTrash size={20} />
          </span>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Dashboard
            </Breadcrumb.Item>
            {/* <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/purchases" }}>
              Sales
            </Breadcrumb.Item> */}
            <Breadcrumb.Item active>Sales</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Sales</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search records..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>

      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
      />

      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              noDataComponent="No sales found"
              striped
              highlightOnHover
              responsive
            />
          </div>
        </Col>
      </Row>

      {showCreateModal && (
        <CreateProductModal
          show={showCreateModal}
          onHide={handleCreateModalClose}
        />
      )}
      {showModal && editingProduct && (
        <EditProductModal
          show={showModal}
          onHide={handleModalClose}
          product={editingProduct}
        />
      )}

      <ViewSaleModal
        show={showViewModal}
        handleClose={handleCloseViewModal}
        sale={selectedSale}
      />
    </div>
  );
};

export default SalesPage;
