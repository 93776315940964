/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createBilling, getAllBillings } from '../../../redux/actions/billingAction.js';
import { getAllCustomers } from '../../../redux/actions/customerAction.js';

const CreateBillingModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, billings } = useSelector((state) => state.billing);
  const { customers } = useSelector((state) => state.customer);

  const [billingId, setBillingId] = useState('');
  const [customer, setCustomer] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    dispatch(getAllCustomers());
  }, [dispatch]);

  const handleBillingIdChange = (e) => {
    const newBillingId = e.target.value;
    setBillingId(newBillingId);

    const billingExists = billings.some(billing => billing.billingId === newBillingId);
    if (billingExists) {
      toast.error('Billing ID already exists!');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const billingData = {
      billingId,
      customer,
      totalAmount,
      status,
    };

    dispatch(createBilling(billingData));
  };

  useEffect(() => {
    if (success) {
      toast.success('Billing created successfully!');
      dispatch(getAllBillings()); // Fetch updated billing list
      setBillingId('');
      setCustomer('');
      setTotalAmount('');
      setStatus('');
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Billing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Billing ID</Form.Label>
                <Form.Control
                  type="text"
                  value={billingId}
                  onChange={handleBillingIdChange}
                  placeholder="Enter billing ID"
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Customer</Form.Label>
                <Form.Control
                  as="select"
                  value={customer}
                  onChange={(e) => setCustomer(e.target.value)}
                  required
                >
                  <option value="">Select customer</option>
                  {customers.map((customer) => (
                    <option key={customer._id} value={customer._id}>
                      {customer.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                  placeholder="Enter total amount"
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                >
                  <option value="">Select status</option>
                  <option value="Pending">Pending</option>
                  <option value="Paid">Paid</option>
                  <option value="Cancelled">Cancelled</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" disabled={isLoading} className='float-end'>
            {isLoading ? 'Creating...' : 'Create Billing'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateBillingModal;
