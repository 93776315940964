/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getAllProductUnits } from '../../../../redux/actions/productUnitAction';
import { getAllStocks } from '../../../../redux/actions/stockAction';
import { createInventoryItem, getInventoryItemsByStore } from '../../../../redux/actions/storeInventoryAction';
import { useProductUnitSelect, useSellingUnitSelect, useStoreInventoryProduct } from '../../../utils/selectFunctions';
import { handleNumericInput } from '../../../utils/utils';
import SellingUnits from './SellingUnits';

const CreateStoreInventoryModal = ({ show, onHide }) => {

  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.storeInventory);
  const { stocks } = useSelector((state) => state.stock);
  const { productUnits } = useSelector((state) => state.productUnit);

  const { stockId, stockName, stockOptions, handleStockChange } = useStoreInventoryProduct(stocks);
  const { productUnitId, productUnitOptions, handleProductUnitChange } = useProductUnitSelect(productUnits);
  const { sellingUnitId, setSellingUnitId, sellingUnitOptions, handleSellingUnitChange } = useSellingUnitSelect(productUnits);

  // get selected stock
  const selectedStock = stocks?.find((stock) => stock?._id === stockId);

  const [unitQuantity, setUnitQuantity] = useState('');
  const [numberOfUnits, setNumberOfUnits] = useState('');
  const [totalQuantity, setTotalQuantity] = useState('');
  const [inventoryVariation, setInventoryVariation] = useState('');
  const [reorderLevel, setReorderLevel] = useState('');
  const [sellingUnits, setSellingUnits] = useState([]);


  useEffect(() => {
    if (show) {
      dispatch(getAllProductUnits());
      dispatch(getAllStocks());
    }
  }, [dispatch, show])

  // calculate total quantity
  useEffect(() => {
    setTotalQuantity(numberOfUnits * unitQuantity);
  }, [numberOfUnits, unitQuantity]);

  // Handle selling unit add
  const handleAddSellingUnit = (itemId, setItem, items) => {
    // Find the selected unit from productUnits
    const selectedUnit = productUnits.find((unit) => unit._id === itemId);

    // Check if the unit is already in the sellingUnits array
    if (selectedUnit && !items.some((unit) => unit._id === selectedUnit._id)) {
      setItem([...items, selectedUnit]);
      setSellingUnitId(null);
      return true;
    } else {
      toast.error("This variant already exists!");
    }
    return false;
  };

  // Handle product unit remove
  const handleRemoveSellingUnit = (itemId, setItem, items) => {
    // Remove the unit by matching the `_id`
    setItem(items.filter((unit) => unit._id !== itemId));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the selling units are empty
    if (sellingUnits.length === 0) {
      return toast.error("Please add selling units!");
    }

    const newItem = {
      stock: stockId,
      name: stockName,
      inventoryVariation,
      product: selectedStock?.product,
      productUnit: productUnitId,
      unitQuantity,
      numberOfUnits,
      totalQuantity,
      reorderLevel,
      sellingUnits
    };

    // console.log(newItem)
    dispatch(createInventoryItem(newItem));
  };

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
      toast.error(error);
    }

    if (success) {
      toast.success("Inventory item created successfully!");
      dispatch(getInventoryItemsByStore)
      dispatch({ type: "clearSuccess" });
      onHide();
    }
  }, [dispatch, error, onHide, success]);

  return (
    <Modal show={show} onHide={onHide} size='lg' backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Create Store Inventory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Select Product :</Form.Label>
                <div className="d-flex">
                  <InputGroup>
                    <Select
                      value={stockOptions.find(option => option.value === stockId) || null}
                      onChange={handleStockChange}
                      options={stockOptions}
                      placeholder="Select Inventory"
                      isClearable
                      className="flex-grow-1"
                      required
                    />
                  </InputGroup>
                </div>
              </Form.Group>
            </Col>
            {selectedStock?.variantType === 'Multiple' && (
              <Col>
                <Form.Group className="mb-2">
                  <Form.Label>Inventory Variation :</Form.Label>
                  <Form.Control
                    as="select"
                    value={inventoryVariation}
                    onChange={(e) => setInventoryVariation(e.target.value)}
                  // required
                  >
                    <option value="">Select Inventory Variation</option>
                    {selectedStock?.primaryVariations?.map((variant, index) => (
                      <option key={index} value={variant}>
                        {variant}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Unit Received :</Form.Label>
                <Select
                  value={productUnitOptions.find(option => option.value === productUnitId) || null}
                  onChange={handleProductUnitChange}
                  options={productUnitOptions}
                  placeholder="Select unit received"
                  isClearable
                  className="flex-grow-1"
                  required
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Unit Quantity :</Form.Label>
                <Form.Control
                  type="text"
                  value={unitQuantity}
                  onChange={(e) => handleNumericInput(e, setUnitQuantity)}
                  placeholder="Enter unit quantity"
                  required
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Number of Units :</Form.Label>
                <Form.Control
                  type="text"
                  value={numberOfUnits}
                  onChange={(e) => handleNumericInput(e, setNumberOfUnits)}
                  placeholder="Enter number of units"
                  required
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Total Quantity :</Form.Label>
                <Form.Control
                  type="number"
                  value={totalQuantity}
                  disabled
                  placeholder="Total quantity"
                  readOnly
                />
              </Form.Group>
            </Col>

            <Col sm={12} md={12} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Reorder Level :</Form.Label>
                <Form.Control
                  type="number"
                  value={reorderLevel}
                  onChange={(e) => setReorderLevel(parseInt(e.target.value, 10))}
                  placeholder="Enter reorder level"
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12} lg={12}>
              <Form.Group className="mb-2">
                <Form.Label>Selling Unit :</Form.Label>
                <div className="d-flex">
                  <Select
                    value={sellingUnitOptions.find(option => option.value === sellingUnitId) || null}
                    onChange={handleSellingUnitChange}
                    options={sellingUnitOptions}
                    placeholder="Select selling unit"
                    isClearable
                    className="flex-grow-1"
                  />

                  <Button
                    className="ms-2"
                    onClick={
                      () => handleAddSellingUnit(sellingUnitId, setSellingUnits, sellingUnits)
                    }
                    type="button"
                  >
                    Add
                  </Button>
                </div>
              </Form.Group>
              <div className="d-flex flex-wrap mb-2">
                {sellingUnits && sellingUnits?.map((unit, index) => (
                  <span key={index} className="badge bg-info d-inline-flex align-items-center me-2">
                    {unit.unitName}
                    <AiOutlineClose
                      size={15}
                      className="ms-2 cursor-pointer icon"
                      onClick={() => handleRemoveSellingUnit(unit?._id, setSellingUnits, sellingUnits)}
                    />
                  </span>
                ))}
              </div>
            </Col>
            <Col md={12} lg={12}>
              {sellingUnits && sellingUnits.length > 0 && (
                <SellingUnits
                  sellingUnits={sellingUnits}
                  setSellingUnits={setSellingUnits}
                />
              )}
            </Col>
          </Row>
          <Button
            variant="primary"
            type="submit"
            className="float-end mt-3"
            disabled={isLoading}
          >
            Create
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateStoreInventoryModal;
