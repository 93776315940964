/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getAllCategories } from '../../../redux/actions/categoryAction';
import { createProduct, getAllProducts } from '../../../redux/actions/productAction';
import { getAllProductUnits } from '../../../redux/actions/productUnitAction';
import { getAllSubcategories } from '../../../redux/actions/subcategoryAction';
import { getAllVariants } from '../../../redux/actions/variantAction.js';
import { usePricingAttribute, useProductCategorySelect, useSubcategorySelect } from '../../utils/selectFunctions.jsx';
import { capitalizeFirstLetter } from '../../utils/utils.js';

import { pricingAttributes } from '../../data/staticData.js';
import CreateCategoryModal from '../categories/CreateCategoryModal.jsx';
import CreateVariantModal from '../variants/CreateVariantModal.jsx';
import AddVariantVariationModal from '../variants/variant-details/AddVariantVariation.jsx';
import CreateSubcategoryModal from './CreateSubcategoryModal.jsx';

const CreateProductModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error, products } = useSelector((state) => state.product);
  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.subcategory);

  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState("");

  const [pricingVariation, setPricingVariation] = useState('');
  const [pricingVariations, setPricingVariations] = useState([]);

  const { categoryOptions, } = useProductCategorySelect(categories);
  const { subcategoryOptions } = useSubcategorySelect(subcategories, category);
  const { pricingAttribute, pricingAttributeOptions, handlePricingAttributeChange } = usePricingAttribute(pricingAttributes);

  // product variants
  const [productVariants, setProductVariants] = useState([]);

  /** create category modals */
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const handleCreateCategoryClose = () => setShowCreateCategory(false);

  // create subcategory modals
  const [showCreateSubcategoryModal, setShowCreateSubcategoryModal] = useState(false);
  const handleCreateSubcategoryClose = () => setShowCreateSubcategoryModal(false);

  // add variant variations modals
  const [showAddVariantVariation, setShowAddVariantVariation] = useState(false);
  const handleAddVariantVariationClose = () => setShowAddVariantVariation(false);

  // add variant modals
  const [showAddVariant, setShowAddVariant] = useState(false);
  const handleAddVariantClose = () => setShowAddVariant(false);

  useEffect(() => {
    if (show) {
      dispatch(getAllCategories());
      dispatch(getAllSubcategories());
      dispatch(getAllProductUnits());
      dispatch(getAllVariants());
    }
  }, [show, dispatch]);

  // state error
  const [errorName, setErrorName] = useState('');

  // Handle name change
  const handleNameChange = (e) => {
    setName(capitalizeFirstLetter(e.target.value));
    // if not empty, set product variant
    if (e.target.value) {
      // trim and capitalize first letter
      const trimmedName = e.target.value.trim();
      const productExists = products && products?.some(product => product.name === trimmedName);
      if (productExists) {
        toast.error('Product name already exists!');
        setErrorName('Product name already exists!');
      } else {
        setErrorName(''); // Clear error if name is unique
      }
      setProductVariants([]);
    } else {
      setProductVariants([]);
    }
  };

  // handle remove product variant
  const handleRemoveProductVariant = (productVariant) => {
    setProductVariants((prev) => prev.filter((variant) => variant !== productVariant));
  };

  // Handle category change
  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption ? selectedOption.value : "");
    setSubcategory("");
  };

  // Handle subcategory change
  const handleSubcategoryChange = (selectedOption) => {
    setSubcategory(selectedOption ? selectedOption.value : "");
  };

  // Handle pricing attribute change
  const handlePricingChangeAttribute = (selectedOption) => {
    handlePricingAttributeChange(selectedOption);
  };

  // handle blur
  const handleBlur = () => {
    if (name && pricingVariations.length > 0) {
      const newPricingVariant = pricingVariations.map((variation) => variation);
      const trimmedName = name.trim();
      newPricingVariant.forEach((variant) => {
        handleAddProductVariant(`${trimmedName} ${variant}`);
      });
    }
  };

  // Handle add price variation
  const handleAddPricingVariation = () => {
    if (!pricingVariation) {
      toast.error(`Please enter ${pricingVariation} variation.`);
      return;
    }
    const trimmedPricingVariant = pricingVariation.trim();
    const trimmedName = name.trim();

    if (trimmedPricingVariant && !pricingVariations.includes(trimmedPricingVariant)) {
      setPricingVariation("");
      setPricingVariations((prev) => [...prev, trimmedPricingVariant]);
      handleAddProductVariant(`${trimmedName} ${trimmedPricingVariant}`);
    } else if (pricingVariations.includes(trimmedPricingVariant)) {
      toast.error(`${pricingVariation} variant already exists!.`);
    }
  };

  // handle add product variant
  const handleAddProductVariant = (productVariant) => {
    setProductVariants((prev) => [...prev, productVariant]);
  };

  // Handle remove price variation
  const handleRemovePricingVariation = (priceVariant) => {
    setPricingVariations((prev) => prev.filter((variant) => variant !== priceVariant));
    handleRemoveProductVariant(`${name} ${priceVariant}`);
  };

  // Handle add category
  const handleAddCategory = () => {
    setShowCreateCategory(true);
  };

  // Handle add subcategory
  const handleAddSubcategory = () => {
    setShowCreateSubcategoryModal(true);
  };

  /** handle submit function */
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Validation for required fields
    if (!name || !category) {
      toast.error('Please fill out all required fields.');
      return;
    }

    // Validation for price variations
    if (pricingVariations.length === 0) {
      toast.error(`Please add at least one ${pricingVariation || ''} variation.`);
      return;
    }

    // Create product data object
    const productData = {
      name,
      category,
      subcategory,
      pricingAttribute,
      pricingVariations,
      productVariants,
    };

    // Dispatch the action to create the product
    dispatch(createProduct(productData));

  };

  useEffect(() => {
    if (success) {
      toast.success('Product created successfully!');
      dispatch(getAllProducts());
      setName('');
      setCategory('');
      setSubcategory('');
      setPricingVariation('');
      setPricingVariations([]);
      dispatch({ type: "clearSuccess" });
      onHide();
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
  }, [success, dispatch, error, onHide, show]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size='lg' fixed='top' style={{ zIndex: 1050 }}>
      <Modal.Header closeButton>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Select Main Category :</Form.Label>
                <div className="d-flex">
                  <Select
                    value={categoryOptions.find(option => option.value === category)}
                    onChange={handleCategoryChange}
                    options={categoryOptions}
                    placeholder="Select main category"
                    required
                    isClearable
                    className="flex-grow-1"
                  />
                  <Button
                    className="ms-2"
                    onClick={handleAddCategory}
                    type="button"
                  >
                    <FaPlus />
                  </Button>
                </div>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Select Sub Category :</Form.Label>
                <div className="d-flex">
                  <Select
                    value={subcategoryOptions.find(option => option.value === subcategory) || null}
                    onChange={handleSubcategoryChange}
                    options={subcategoryOptions}
                    placeholder="Select subcategory"
                    required
                    isClearable
                    className="flex-grow-1"
                  />
                  <Button
                    className="ms-2"
                    onClick={handleAddSubcategory}
                    disabled={!category}
                    type="button"
                  >
                    <FaPlus />
                  </Button>
                </div>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-2">
                <Form.Label>Name :</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  onBlur={handleBlur}
                  placeholder='Enter product name'
                  required
                />
              </Form.Group>
              {errorName && <p className="text-danger">{errorName}</p>}
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Pricing Variant :</Form.Label>
                <div className="d-flex">
                  <Select
                    value={pricingAttributeOptions.find(option => option.value === pricingAttribute) || null}
                    onChange={handlePricingChangeAttribute}
                    options={pricingAttributeOptions}
                    placeholder="Select pricing variant"
                    isClearable
                    className="flex-grow-1"
                  />
                  <Button
                    className="ms-2"
                    onClick={() => setShowAddVariant(true)}
                  >
                    <FaPlus />
                  </Button>
                </div>
              </Form.Group>
            </Col>

            {pricingAttribute && (
              <Col lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Available {pricingAttribute || ''} Variations :</Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      type="text"
                      value={pricingVariation}
                      onChange={(e) => setPricingVariation(capitalizeFirstLetter(e.target.value))}
                      placeholder='Enter price variation'
                    />
                    <Button
                      className="ms-2"
                      onClick={handleAddPricingVariation}
                      type="button"
                    >
                      Add
                    </Button>
                  </div>
                </Form.Group>
                <div className="mb-2">
                  {pricingVariations?.map((priceVariant, i) => (
                    <span key={i} className="badge bg-info d-inline-flex align-items-center me-2">
                      {priceVariant}
                      <AiOutlineClose
                        size={15}
                        className="ms-2 cursor-pointer"
                        onClick={() => handleRemovePricingVariation(priceVariant)}
                      />
                    </span>
                  ))}
                </div>
              </Col>
            )}
            {pricingAttribute && productVariants && productVariants.length > 0 && (
              <Col lg={12}>
                <Form.Group className="mb-0">
                  <Form.Label>Product Variants :</Form.Label>
                </Form.Group>
                <div className="mb-2">
                  {productVariants?.map((productVariant, i) => (
                    <span key={i} className="badge bg-info d-inline-flex align-items-center me-2">
                      {productVariant}
                    </span>
                  ))}
                </div>
              </Col>
            )}
          </Row>
          <div className="float-end mt-3">
            <Button
              className="ms-3"
              type="submit"
              variant="primary"
              disabled={isLoading || pricingVariation}
            >
              {isLoading ? 'Creating...' : 'Create Product'}
            </Button>
          </div>
        </Form>
        {showCreateCategory && (
          <CreateCategoryModal
            show={showCreateCategory}
            onHide={handleCreateCategoryClose}
          />
        )}
        {showCreateSubcategoryModal && (
          <CreateSubcategoryModal
            show={showCreateSubcategoryModal}
            onHide={handleCreateSubcategoryClose}
            categoryId={category}
            setCategory={setCategory}
          />
        )}
        {showAddVariant && (
          <CreateVariantModal
            show={showAddVariant}
            onHide={handleAddVariantClose}
            variantType="Pricing"
          />
        )}

        {showAddVariantVariation && (
          <AddVariantVariationModal
            show={showAddVariantVariation}
            onHide={handleAddVariantVariationClose}
          // variant={pricingVariant}
          />
        )}

      </Modal.Body>
    </Modal>
  );
};

export default CreateProductModal;
