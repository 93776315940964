import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  supplier: null,
  suppliers: [],
  success: false,
  error: null,
  message: null,
};

export const supplierReducer = createReducer(initialState, (builder) => {
  builder
    // Supplier Creation
    .addCase('supplierCreateRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('supplierCreateSuccess', (state, action) => {
      state.isLoading = false;
      state.supplier = action.payload;
      state.success = true;
      state.message = "Supplier created successfully!";
    })
    .addCase('supplierCreateFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Suppliers
    .addCase('getAllSuppliersRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllSuppliersSuccess', (state, action) => {
      state.isLoading = false;
      state.suppliers = action.payload;
    })
    .addCase('getAllSuppliersFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Single Supplier
    .addCase('getSingleSupplierRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getSingleSupplierSuccess', (state, action) => {
      state.isLoading = false;
      state.supplier = action.payload;
    })
    .addCase('getSingleSupplierFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Supplier
    .addCase('editSupplierRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editSupplierSuccess', (state, action) => {
      state.isLoading = false;
      state.supplier = action.payload;
      state.success = true;
      state.message = "Supplier updated successfully!";
    })
    .addCase('editSupplierFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Delete Supplier
    .addCase('deleteSupplierRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteSupplierSuccess', (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
    })
    .addCase('deleteSupplierFailed', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
