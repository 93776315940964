import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getAllProducts } from "../../../../redux/actions/productAction";
import { getAllProductUnits } from "../../../../redux/actions/productUnitAction";
import { createStock } from "../../../../redux/actions/stockAction";
import { getAllSuppliers } from "../../../../redux/actions/supplierAction";
import { getAllVariants } from "../../../../redux/actions/variantAction";
import { inventoryAttributes } from "../../../data/staticData";
import { useInventoryProduct, usePrimaryInventory, usePrimarySellingUnit, useSupplierSelect } from "../../../utils/selectFunctions";
import { capitalizeFirstLetter, handleNumericFloatInput } from "../../../utils/utils";
import CreateProductUnitModal from "../../product-unit/CreateProductUnitModal";
import CreateProductModal from "../../products/CreateProductModal";
import CreateSupplierModal from "../../suppliers/CreateSupplierModal";
import EditPricingVariantModal from "../components/EditPricingVariantModal";
import PriceCalculatorModal from "../components/PriceCalculatorModal";

const CreateInventoryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, success, error } = useSelector((state) => state.stock);

  const { products } = useSelector((state) => state.product);
  const { productUnits } = useSelector((state) => state.productUnit);
  const { suppliers } = useSelector((state) => state.supplier);

  const { productId, productOptions, handleProductChange } = useInventoryProduct(products);
  const { supplierId, supplierOptions, handleSupplierChange } = useSupplierSelect(suppliers);
  const { primarySellingUnitId, primarySellingUnitName, primarySellingUnitOptions, handlePrimarySellingUnitChange } = usePrimarySellingUnit(productUnits);
  const { primaryInventoryId, primaryInventoryOptions, handlePrimaryInventoryChange } = usePrimaryInventory(inventoryAttributes);

  const [inventoryName, setInventoryName] = useState("");
  const [pricingVariant, setPricingVariant] = useState('');
  // const [unitQuantity, setUnitQuantity] = useState('1');
  const [buyingPrice, setBuyingPrice] = useState("");
  const [wholeSalePrice, setWholeSalePrice] = useState("");
  const [retailPrice, setRetailPrice] = useState("");
  const [variantType, setVariantType] = useState('Single');
  const [isVerified, setIsVerified] = useState(false);

  // inventory variants
  const [primaryVariation, setPrimaryVariation] = useState('')
  const [primaryVariations, setPrimaryVariations] = useState([]);
  const [inventoryVariants, setInventoryVariants] = useState([]);

  // Get selected product
  const getSelectedProduct = products && products?.find((product) => product._id === productId);

  /** create product modals */
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const handleCreateProductModalClose = () => setShowCreateProductModal(false);

  /* create price variation modals */
  const [showCreatePriceVariationModal, setShowCreatePriceVariationModal] = useState(false);
  const handleCreatePriceVariationModalClose = () => setShowCreatePriceVariationModal(false);

  /** create supplier modals */
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const handleCreateSupplierModalClose = () => setShowCreateSupplierModal(false);

  /** create product unit modals */
  const [showCreateProductUnitModal, setShowCreateProductUnitModal] = useState(false);
  const handleCreateProductUnitModalClose = () => setShowCreateProductUnitModal(false);

  /** price calculator modal */
  const [showPriceCalculatorModal, setShowPriceCalculatorModal] = useState(false);
  const handlePriceCalculatorModalClose = () => setShowPriceCalculatorModal(false);

  // Handle product unit add
  const handleAddProductUnit = () => {
    setShowCreateProductUnitModal(true);
  };

  // Handle product add
  const handleAddProduct = () => {
    setShowCreateProductModal(true);
  };
  // Handle product unit add
  const handleAddPricingVariant = () => {
    setShowCreatePriceVariationModal(true);
  };

  // Handle supplier add
  const handleAddSupplier = () => {
    setShowCreateSupplierModal(true);
  };

  // handle product name change
  const handleProductNameChange = (selectedOption) => {
    handleProductChange(selectedOption);
    const name = selectedOption?.label;
    setInventoryName(name);
    setPricingVariant('');
    setInventoryVariants([]);
  };

  // Handle inventory name
  const handleInventoryName = () => {
    const trimmedProduct = getSelectedProduct?.name;
    const trimmedVariant = pricingVariant;
    const name = `${trimmedProduct} ${trimmedVariant}`;
    setInventoryName(name);
  };

  // Handle pricing variant
  const handlePricingVariant = (e) => {
    setPricingVariant(e.target.value);
    const trimmedProduct = getSelectedProduct?.name;
    const trimmedVariant = e.target.value;
    const name = `${trimmedProduct} ${trimmedVariant}`;
    setInventoryName(name);
  };


  // Fetch all products, product units, and suppliers
  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllProductUnits());
    dispatch(getAllSuppliers());
    dispatch(getAllVariants());
  }, [dispatch]);


  // handle add add primary variation
  const handleAddPrimaryVariation = (primaryVariation) => {
    const trimmedPrimaryVariant = primaryVariation.trim();
    if (trimmedPrimaryVariant && !primaryVariations.includes(trimmedPrimaryVariant)) {
      setPrimaryVariations((prev) => [...prev, trimmedPrimaryVariant]);
      setPrimaryVariation("");
    } else if (primaryVariations.includes(trimmedPrimaryVariant)) {
      toast.error(`${primaryVariation || ''} variant already exists!.`);
    }
  }

  // handle remove primary variation
  const handleRemovePrimaryVariation = (variation) => {
    setPrimaryVariations(primaryVariations.filter((v) => v !== variation));
  }


  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!getSelectedProduct) {
      toast.error('Please select a product.');
      return;
    }

    if (!supplierId) {
      toast.error('Please select a supplier.');
      return;
    }

    // wholeSalePrice should be greater than buyingPrice
    if (parseInt(wholeSalePrice) < parseInt(buyingPrice)) {
      toast.error('WholeSale price should be greater than buying price.');
      return;
    }

    // retailPrice should be greater than wholeSalePrice
    if (parseInt(retailPrice) < parseInt(wholeSalePrice)) {
      toast.error('Retail price should be greater than wholeSale price.');
      return;
    }

    const stockData = {
      product: getSelectedProduct._id,
      pricingVariant,
      inventoryName,
      primarySellingUnit: primarySellingUnitId,
      buyingPrice,
      wholeSalePrice,
      retailPrice,
      supplier: supplierId,
      variantType,
      primaryVariant: primaryInventoryId || null,
      primaryVariations,
      inventoryVariants,
      isVerified
    };

    dispatch(createStock(stockData));
  }
  useEffect(() => {
    if (success) {
      toast.success("Inventory created successfully");
      dispatch({ type: "clearSuccess" });
      // navigate inventory/67b4518ac593033c1850ba29/variations
      // navigate(`/inventory/${success._id}/variations`);
      navigate('/inventory');
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
  }, [dispatch, error, navigate, success]);

  return (
    <div className="inventoryPage">
      <div className="container-fluid">
        <Row className="my-1">
          <Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inventory">Inventory</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create Inventory
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
      </div>
      <div className="container">
        <Row>
          <Col md={12} lg={12}>
            <h4>Create Inventory</h4>
            <hr className="mt-0" />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Product Name :</Form.Label>
                    <div className="d-flex">
                      <Select
                        value={productOptions.find(option => option.value === productId)}
                        onChange={handleProductNameChange}
                        options={productOptions}
                        placeholder="Select product"
                        isClearable
                        className="flex-grow-1"
                        required
                      />
                      <Button
                        className="ms-2"
                        onClick={handleAddProduct}
                        type="button"
                      >
                        <FaPlus />
                      </Button>
                    </div>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>
                      {getSelectedProduct?.pricingAttribute?.name ?? 'Pricing'} Variation :
                    </Form.Label>
                    <div className="d-flex">
                      <Form.Control
                        as="select"
                        value={pricingVariant}
                        onChange={handlePricingVariant}
                        required
                        placeholder={`Select ${getSelectedProduct?.pricingAttribute?.name} Variant :`}
                        disabled={!getSelectedProduct}
                      >
                        <option value="">
                          Select {getSelectedProduct?.pricingAttribute?.name} Variant
                        </option>
                        {getSelectedProduct?.pricingVariations?.map((variation, index) => (
                          <option key={index} value={variation}>
                            {variation}
                          </option>
                        ))}
                      </Form.Control>
                      <Button
                        className="ms-2"
                        onClick={handleAddPricingVariant}
                        type="button"
                        title="Add Pricing Variant"
                        disabled={!getSelectedProduct}
                      >
                        <FaPlus />
                      </Button>
                    </div>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Inventory Name :</Form.Label>
                    <Form.Control
                      value={inventoryName}
                      onChange={handleInventoryName}
                      placeholder="Enter Inventory Name"
                      disabled
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Pricing Unit :</Form.Label>
                    <div className="d-flex">
                      <Select
                        value={primarySellingUnitOptions.find(option => option.value === primarySellingUnitId)}
                        onChange={handlePrimarySellingUnitChange}
                        options={primarySellingUnitOptions}
                        placeholder="Select primary selling unit"
                        isClearable
                        className="flex-grow-1"
                        required
                      />

                      <Button
                        className="ms-2"
                        onClick={handleAddProductUnit}
                        type="button"
                      >
                        <FaPlus />
                      </Button>
                    </div>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>{`${primarySellingUnitName} Cost Price :`}</Form.Label>
                    <Form.Control
                      type="number"
                      value={buyingPrice}
                      onChange={(e) => handleNumericFloatInput(e, setBuyingPrice)}
                      placeholder={`Enter ${primarySellingUnitName} Buying Price`}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className='mb-2'>
                    <Form.Label>{`${primarySellingUnitName} WholeSale Price :`}</Form.Label>
                    <Form.Control
                      value={wholeSalePrice}
                      onChange={(e) => handleNumericFloatInput(e, setWholeSalePrice)}
                      placeholder={`Enter ${primarySellingUnitName} Wholesale Price :`}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className='mb-2'>
                    <Form.Label>{`${primarySellingUnitName} Retail Price`}</Form.Label>
                    <Form.Control
                      value={retailPrice}
                      onChange={(e) => handleNumericFloatInput(e, setRetailPrice)}
                      placeholder={`Enter  ${primarySellingUnitName} Retail Price`}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Supplied By :</Form.Label>
                    <div className="d-flex">
                      <Select
                        value={supplierOptions.find(option => option.value === supplierId)}
                        onChange={handleSupplierChange}
                        options={supplierOptions}
                        placeholder="Select supplier"
                        isClearable
                        className="flex-grow-1"
                        required
                      />
                      <Button
                        className="ms-2"
                        onClick={handleAddSupplier}
                        type="button"
                      >
                        Add
                      </Button>
                    </div>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="mb-2">
                    <Form.Label>Variant Type :</Form.Label>
                    <Form.Select
                      value={variantType}
                      onChange={(e) => setVariantType(e.target.value)}
                    >
                      <option value="Single">Single</option>
                      <option value="Multiple">Multiple</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                {variantType === 'Multiple' && (
                  <>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Primary Variant :</Form.Label>
                        <div className="d-flex align-items-center">
                          <InputGroup>
                            <Select
                              value={primaryInventoryOptions.find((v) => v.value === primaryInventoryId) || null}
                              options={primaryInventoryOptions}
                              onChange={handlePrimaryInventoryChange}
                              isClearable
                              placeholder="Select Primary Inventory Variant"
                              className="flex-grow-1"
                              required
                            />
                          </InputGroup>
                        </div>

                      </Form.Group>
                    </Col>

                    {primaryInventoryId && (
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Available Variation</Form.Label>
                          <div className="d-flex">
                            <Form.Control
                              value={primaryVariation}
                              onChange={(e) => setPrimaryVariation(capitalizeFirstLetter(e.target.value))}
                            />
                            <Button
                              className="ms-2 cursor-pointer"
                              onClick={() => handleAddPrimaryVariation(primaryVariation)}
                            >
                              Add
                            </Button>
                          </div>
                        </Form.Group>

                        <div className="d-flex flex-wrap mb-2">
                          {primaryVariations && primaryVariations?.map((variation, index) => (
                            <span key={index} className="badge bg-info d-inline-flex align-items-center me-2">
                              {variation}
                              <AiOutlineClose
                                size={15}
                                className="ms-2 cursor-pointer"
                                onClick={() => handleRemovePrimaryVariation(variation)}
                              />
                            </span>
                          ))}
                        </div>
                      </Col>

                    )}
                  </>
                )}

                <Col md={12}>
                  <Form.Label>Verify Price</Form.Label>
                  <Form.Check
                    type="switch"
                    id="verify-price-switch"
                    label="Enable Verification"
                    checked={isVerified}
                    onChange={(e) => setIsVerified(e.target.checked)}
                    required
                  />
                </Col>
              </Row>

              <div>
                <Button
                  type="submit"
                  variant="primary"
                  className='float-end mb-2'
                  disabled={isLoading}
                >
                  Create Stock
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
      {showCreateProductModal && (
        <CreateProductModal
          show={showCreateProductModal}
          onHide={handleCreateProductModalClose}
        />
      )}

      {showCreateSupplierModal && (
        <CreateSupplierModal
          show={showCreateSupplierModal}
          onHide={handleCreateSupplierModalClose}
        />
      )}

      {showCreateProductUnitModal && (
        <CreateProductUnitModal
          show={showCreateProductUnitModal}
          onHide={handleCreateProductUnitModalClose}
        />
      )}

      {showCreatePriceVariationModal && (
        <EditPricingVariantModal
          show={showCreatePriceVariationModal}
          onHide={handleCreatePriceVariationModalClose}
          product={getSelectedProduct}
        />
      )}

      {showPriceCalculatorModal && (
        <PriceCalculatorModal
          show={showPriceCalculatorModal}
          onHide={handlePriceCalculatorModalClose}
        />
      )}
    </div>
  )
}
export default CreateInventoryPage