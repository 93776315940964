/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editBilling, getAllBillings } from '../../../redux/actions/billingAction';
import { getAllCustomers } from '../../../redux/actions/customerAction';
import { toast } from 'react-toastify';

const EditBillingModal = ({ show, onHide, billing }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.billing);
  const { customers } = useSelector((state) => state.customer);

  const [billingId, setBillingId] = useState('');
  const [customer, setCustomer] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (billing) {
      setBillingId(billing.billingId);
      setCustomer(billing.customer?._id);
      setTotalAmount(billing.totalAmount);
      setStatus(billing.status);
    }
  }, [billing]);

  useEffect(() => {
    dispatch(getAllCustomers());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editBilling(billing._id, { billingId, customer, totalAmount, status }));
  };

  useEffect(() => {
    if (success) {
      toast.success('Billing updated successfully!');
      dispatch(getAllBillings()); // Fetch updated billing list
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Billing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Billing ID</Form.Label>
                <Form.Control
                  type="text"
                  value={billingId}
                  onChange={(e) => setBillingId(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Customer</Form.Label>
                <Form.Control
                  as="select"
                  value={customer}
                  onChange={(e) => setCustomer(e.target.value)}
                  required
                >
                  <option value="">Select customer</option>
                  {customers.map((customer) => (
                    <option key={customer._id} value={customer._id}>
                      {customer.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                >
                  <option value="">Select status</option>
                  <option value="Pending">Pending</option>
                  <option value="Paid">Paid</option>
                  <option value="Cancelled">Cancelled</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" className='float-end' disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditBillingModal;
