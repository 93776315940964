import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  inventoryItem: null,
  inventoryItems: [],
  inventoryValue: 0,
  success: false,
  error: null,
  message: null,
};

export const storeInventoryReducer = createReducer(initialState, (builder) => {
  builder
    // Create Inventory Item
    .addCase('createInventoryItemRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('createInventoryItemSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItems.push(action.payload);
      state.success = true;
      state.message = 'Item created successfully!';
    })
    .addCase('createInventoryItemFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Fetch All Inventory Items
    .addCase('getAllInventoryItemsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllInventoryItemsSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItems = action.payload;
    })
    .addCase('getAllInventoryItemsFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Fetch Inventory Items by store
    .addCase('getInventoryItemsByStoreRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getInventoryItemsByStoreSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItems = action.payload;
    })
    .addCase('getInventoryItemsByStoreFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Edit Inventory Item
    .addCase('editInventoryItemRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('editInventoryItemSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItems = state.inventoryItems.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      state.success = true;
      state.message = 'Item updated successfully!';
    })
    .addCase('editInventoryItemFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Add Inventory Quantity to an Item
    .addCase('addInventoryQuantityRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('addInventoryQuantitySuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItem = action.payload;
      state.success = true;
      state.message = 'Quantity added successfully!';
    })
    .addCase('addInventoryQuantityFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Get Inventory Item Details
    .addCase('getInventoryItemDetailsRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getInventoryItemDetailsSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItem = action.payload;
    })
    .addCase('getInventoryItemDetailsFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    // Delete Inventory Item
    .addCase('deleteInventoryItemRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('deleteInventoryItemSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryItems = state.inventoryItems.filter(
        (item) => item._id !== action.payload
      );
      state.success = true;
      state.message = 'Item deleted successfully!';
    })
    .addCase('deleteInventoryItemFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    })
    // Get store inventory value based on the total quantity and buying price
    .addCase('getInventoryValueRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getInventoryValueSuccess', (state, action) => {
      state.isLoading = false;
      state.inventoryValue = action.payload;
    })
    .addCase('getInventoryValueFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    // Clear Errors
    .addCase('clearErrors', (state) => {
      state.error = null;
    })
    // Clear Success Messages
    .addCase('clearSuccess', (state) => {
      state.message = null;
      state.success = false;
    });
});
