import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAllSales } from '../../../../redux/actions/saleAction.js';
import { handleSalesFilter } from '../../../components/utils/handleFilter.jsx';

const SalesReportPage = () => {
  const dispatch = useDispatch();
  const { sales, error, success } = useSelector((state) => state.sale);

  /**search states */
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(getAllSales());
  }, [dispatch]);

  useEffect(() => {
    setRecords(sales);
  }, [sales]);

  /** search function */
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredData = handleSalesFilter(sales, term);
    setRecords(filteredData);
  };

  /** table columns */
  const columns = [
    {
      name: "#",
      selector: (_row, index) => index + 1,
      sortable: false,
      width: "60px",
    },
    {
      name: "Sale ID",
      selector: (row) => row?.saleId,
    },
    {
      name: "Customer Name",
      selector: (row) => row?.customer?.name,
    },
    {
      name: "Total Amount",
      selector: (row) => row?.totalAmount,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.date).toLocaleDateString(),
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch({ type: "clearErrors" });
    }

    if (success) {
      dispatch({ type: "clearSuccess" });
    }
  }, [dispatch, error, success]);

  return (
    <div className="container-fluid">
      <Row className="my-1">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Sales Report
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row className="my-1 justify-content-between align-items-center">
        <Col xs={12} md={4}>
          <h2>Sales Report</h2>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            type="text"
            placeholder="Search sales..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div id="printable-area">
            <DataTable
              columns={columns}
              data={records}
              fixedHeader
              pagination
              noDataComponent="No sales found"
              striped
              highlightOnHover
              responsive
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SalesReportPage;