// Filter purchases by supplier name
export const handlePurchaseFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.supplier?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row?.supplier?.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row?.invoiceNumber?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter suppliers by name
export const handleSupplierFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row?.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row?.company?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter customers by name
export const handleCustomerFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter categories by name
export const handleCategoryFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter product units by unit name
export const handleProductUnitFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.unitName?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter products by name
export const handleProductFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row?.category?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row?.subcategory?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter inventory by product name
export const handleInventoryFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.inventoryName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row?.product?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// filter store inventory inventoryName
export const handleStoreInventoryFilter = (data, searchTerm) => {
  return data?.filter(row =>
    `${row?.name} ${row?.inventoryVariation}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row?.stock?.inventoryName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row?.stock?.product?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter sales by sale ID, customer name, total amount, or sale date
export const handleSalesFilter = (sales, searchTerm) => {
  if (!searchTerm) return sales;
  return sales.filter((sale) =>
    (sale.saleId?.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
    (sale.customer?.name?.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
    (sale.totalAmount?.toString().includes(searchTerm.toLowerCase()) || '') ||
    (new Date(sale.saleDate).toLocaleDateString().includes(searchTerm.toLowerCase()) || '')
  );
};

// Filter branches by name
export const handleBranchFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter roles by name
export const handleRoleFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter stores by name, location, or contact
export const handleStoreFilter = (stores, searchTerm) => {
  if (!searchTerm) return stores;
  return stores.filter((store) =>
    store.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    store.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
    store.contact.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter users by name, email, or role
export const handleUserFilter = (users, searchTerm) => {
  if (!searchTerm) return users;
  return users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter orders by order ID, customer name, total amount, or status
export const handleOrderFilter = (orders, searchTerm) => {
  if (!searchTerm) return orders;
  return orders.filter((order) =>
    order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.customer?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.totalAmount.toString().includes(searchTerm.toLowerCase()) ||
    order.status.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter billings by billing ID, customer name, total amount, or status
export const handleBillingFilter = (billings, searchTerm) => {
  if (!searchTerm) return billings;
  return billings.filter((billing) =>
    billing.billingId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    billing.customer?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    billing.totalAmount.toString().includes(searchTerm.toLowerCase()) ||
    billing.status.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// Filter expenses by expense ID, description, amount, or date
export const handleExpenseFilter = (expenses, searchTerm) => {
  if (!searchTerm) return expenses;
  return expenses.filter((expense) =>
    expense.expenseId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    expense.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    expense.amount.toString().includes(searchTerm.toLowerCase()) ||
    new Date(expense.date).toLocaleDateString().includes(searchTerm.toLowerCase())
  );
};

// Filter variants by name
export const handleVariantFilter = (data, searchTerm) => {
  return data?.filter(row =>
    row?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

// const handleFilter = (e) => {
//   const newData = data.filter(row =>
//     row.name.toLowerCase().includes(e.target.value.toLowerCase())
//   );
//   setRecords(newData);
// };
