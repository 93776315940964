/* eslint-disable react/prop-types */
import { Modal } from 'react-bootstrap';

const InventoryPricing = ({ show, onHide }) => {

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Pricing Stock</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      </Modal.Body>
    </Modal>
  );
};

export default InventoryPricing;