/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateInventoryVariant } from "../../../../../redux/actions/stockAction";

const SingleVariant = ({ data }) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector(state => state.stock);

  const [inventoryVariant, setInventoryVariant] = useState('');
  const [inventoryUPC, setInventoryUPC] = useState('');

  useEffect(() => {
    if (data) {
      setInventoryVariant(data?.inventoryVariants[0]?.name || '');
      setInventoryUPC(data?.inventoryVariants[0]?.upc || '');
    }
  }, [data]);

  // generate upc
  const handleGenerateUPC = () => {
    const upc = Math.floor(100000000 + Math.random() * 900000000);
    setInventoryUPC(upc);
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (inventoryUPC === '') {
      toast.error('Please enter UPC');
      return;
    }

    const inventoryVariantData = {
      name: inventoryVariant,
      upc: inventoryUPC,
    };
    dispatch(updateInventoryVariant(data._id, inventoryVariantData));
  }

  // clear success and error
  useEffect(() => {
    if (success) {
      toast.success('Inventory variant updated successfully');
      dispatch({ type: "clearSuccess" });
    }
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }
  }, [success, error, dispatch]);

  return (
    <div className="single-variant">
      <Col>
        <Form onSubmit={handleSubmit}>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Variant Name</Form.Label>
              <Form.Control
                type="text"
                value={inventoryVariant}
                onChange={(e) => setInventoryVariant(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>UPC</Form.Label>
              <div className="d-flex">
                <Form.Control
                  type="number"
                  value={inventoryUPC}
                  onChange={(e) => setInventoryUPC(e.target.value)}
                />
                <Button
                  className="ms-2 btn btn-primary"
                  onClick={handleGenerateUPC}
                >
                  Generate
                </Button>
              </div>
            </Form.Group>
          </Col>
          <Button
            className="float-end"
            variant="primary"
            type="submit"
          >
            Update
          </Button>
        </Form>
      </Col>
    </div>
  )
}
export default SingleVariant